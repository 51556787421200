/**
 * This module provides a pre-configured axios instance for making requests to the API,
 * as well as some utility functions.
 */
import axios, { AxiosError } from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import applyCaseMiddleware from 'axios-case-converter'
import getConfig from 'services/config'

export const axiosBaseURL = new URL(getConfig('COPILEX_BACKEND_URL')).toString().replace(/\/$/, '')

// Default request timeout for all requests to the API
export const defaultRequestTimeout = 10 * 60 * 1000 // in milliseconds

// Set up our axios instance with caching and case conversion middleware
export const axiosInstance = setupCache(
  applyCaseMiddleware(axios.create({
    baseURL: axiosBaseURL,
    timeout: defaultRequestTimeout
  }))
)

// Time to live for cache entries
export const defaultTTL = 60 * 1000 // in milliseconds

export const createAuthHeaderFromToken = (token: string): { Authorization: string } => {
  return { Authorization: `Bearer ${token}` }
}

export interface APIError {
  detail: string
}

export const handleAPIError = (err: unknown): never => {
  // Extract the exact error message from the API response
  if (err instanceof AxiosError) {
    const error = err as AxiosError
    if (error.response == null) {
      console.error('API internal error:', error)
      throw new Error('Some internal error occurred')
    }
    const detail = (error.response.data as APIError).detail
    throw new Error(detail)
  } else {
    throw err
  }
}
