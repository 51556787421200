import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  // Will make the back button align to the left and the second file button align to the right
  justifyContent: 'space-between'
})

export default StyledBox
