/**
 * The default width of the left and right sidepanes, in pixels.
 */
export const DEFAULT_SIDE_PANE_WIDTH_IN_PX = 320

/**
 * The maximum width of the central pane, in pixels.
 */
export const CENTRAL_PANE_MAX_WIDTH_IN_PX = 800

/**
 * The minimum width of the central pane, in pixels.
 */
export const CENTRAL_PANE_MIN_WIDTH_IN_PX = 400

export type SidepaneMode = 'standard' | 'maximized'

/**
 * The width of the side pane, in pixels, or a fraction of the screen width.
 */
export type SidepaneWidth = number | 'one-third' | 'half' | 'two-thirds'

export interface SidepaneState {
  // Whether the side pane is open.
  isOpen: boolean
  // Whether the side pane is temporary (closed automatically when hovering away) or not.
  isTemporary: boolean
  // Whether the side pane is in standard mode or maximized mode.
  mode: SidepaneMode
  // The width of the side pane in standard mode.
  standardWidth: SidepaneWidth
  // The width of the side pane in maximized mode.
  maximizedWidth: SidepaneWidth
}

export const getSidepaneWidthInPx = (paneState: SidepaneState): number => {
  const width = paneState.mode === 'standard' ? paneState.standardWidth : paneState.maximizedWidth
  if (typeof width === 'number') {
    return width
  } else if (width === 'one-third') {
    return window.innerWidth / 3
  } else if (width === 'half') {
    return window.innerWidth / 2
  } else if (width === 'two-thirds') {
    return window.innerWidth * 2 / 3
  }
  throw new Error(`Invalid sidepane width in state: ${JSON.stringify(paneState)}`)
}

export interface CentralPaneState {
  offset: number
  width: number
}
