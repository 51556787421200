import { useAuth0 } from '@auth0/auth0-react'
import { Button, FormControl, TextField, Typography } from '@mui/material'
import ProgressBackdrop from 'components/progress/ProgressBackdrop'
import { useAuth } from 'context/AuthContext'
import { whitelistEmail } from 'features/admin/api/whitelistEmail'
import { useState } from 'react'
import { useIntl } from 'react-intl'

function AdminPage (): JSX.Element {
  const intl = useIntl()
  const currentUser = useAuth()
  const { getAccessTokenSilently } = useAuth0()

  const [email, setEmail] = useState('')
  const [processing, setProcessing] = useState<boolean>(false)
  const [token, setToken] = useState<string | null>(null)

  if (currentUser === undefined || currentUser === null) {
    return <Typography>Not logged in!</Typography>
  }

  const handleSubmit = (): void => {
    setProcessing(true)
    void getAccessTokenSilently().then(async (token) =>
      await whitelistEmail(token, email, currentUser)
    ).then((response) => {
      setProcessing(false)
      console.debug('Response: ', response)
      setToken(response.registrationToken)
    })
  }

  return (
    <>
      <FormControl sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifySelf: 'center',
        justifyContent: 'space-between',
        gap: 1,
        mx: 'auto',
        my: 2,
        maxWidth: '1200px',
        height: '100%'
      }}>
        <h1>
          {
            intl.formatMessage({
              id: 'app.admin-page.title',
              defaultMessage: 'Register user'
            })
          }
        </h1>
        <TextField
          id="email-input"
          value={email}
          label={
            intl.formatMessage({
              id: 'app.admin-page.email',
              defaultMessage: 'Email'
            })
          }
          disabled={token !== null}
          onChange={(event) => { setEmail(event.target.value) }}
        />
        <Button
          variant="contained"
          disabled={token !== null}
          onClick={handleSubmit}
        >
          Register
        </Button>
        {token !== null && (
          <p>
            {
              intl.formatMessage({
                id: 'app.admin-page.registered',
                defaultMessage: 'User registered 🎉{br}Send them this link to complete the registration:'
              }, {
                br: <br />
              })
            }
            <br/>
            {`https://${window.location.host}/signup?token=${token}`}
          </p>
        )}
      </FormControl>
      <ProgressBackdrop open={processing} />
    </>
  )
}

export default AdminPage
