import { useState } from 'react'

interface UseHoveredReturn {
  isHovered: boolean
  containerProps: {
    onMouseEnter: () => void
    onMouseLeave: () => void
  }
}

/**
 * Hook to track the hover state of a component
 */
export const useHovered = (): UseHoveredReturn => {
  const [isHovered, setIsHovered] = useState(false)

  const onMouseEnter = (): void => { setIsHovered(true) }
  const onMouseLeave = (): void => { setIsHovered(false) }

  return {
    isHovered,
    containerProps: {
      onMouseEnter,
      onMouseLeave
    }
  }
}
