import { Box, styled } from '@mui/material'

interface DocumentViewContainerProps {
  width?: string | number
}

export const DocumentViewContainer = styled(Box)<DocumentViewContainerProps>(({ theme, width }) => ({
  // Don't allow the container to shrink
  minHeight: 0,
  flexShrink: 1,
  // In order to make the footer always at the bottom,
  // take all available height, even if the document is smaller
  flexGrow: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  overflow: 'hidden',
  width
}))

export const HEADER_HEIGHT_IN_PX = '32px'

export const DocumentViewHeader = styled(Box)(({ theme }) => ({
  // Prevent the header from shrinking
  flexShrink: 0,
  // Set a fixed height for the header, matching the close button height
  // which is the largest element we can have in the header.
  height: HEADER_HEIGHT_IN_PX,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1)
}))

export const FilenameWithIconContainer = styled(Box)(({ theme }) => ({
  flexShrink: 1,
  minWidth: 0
}))
