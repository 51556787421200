import { type UUID } from 'crypto'
import { type DocType } from 'features/documents'

export type IssueCategory = 'Internal' | 'Contextual' | 'Compliance'

export type IssueType = SanityCheckOption | 'Deviation'

export const CRITICALITY_VALUES = ['high', 'medium', 'low', 'non applicable'] as const
export type Criticality = typeof CRITICALITY_VALUES[number]

export const COMPARATIVE_ANALYSIS_NATURE_VALUES = ['modification', 'omission', 'addition', 'point of attention', 'non applicable'] as const
export type ComparativeAnalysisNature = typeof COMPARATIVE_ANALYSIS_NATURE_VALUES[number]

export const TYPOGRAPHICAL_ERRORS_NATURE_VALUES = [
  'misspelled word',
  'incorrect spacing',
  'omitted letter',
  'repeated word',
  'misplaced punctuation',
  'transposed letters',
  'wrong homonym usage',
  'incorrect pluralization',
  'missing word',
  'grammatical mistake',
  'additional unintended character',
  'other',
  'non applicable'
] as const
export type TypographicalErrorsNature = typeof TYPOGRAPHICAL_ERRORS_NATURE_VALUES[number]

export const INTERNAL_CROSS_REFERENCE_CHECK_NATURE_VALUES = [
  'accurate cross-reference',
  'inaccurate cross-reference',
  'unverifiable cross-reference'
] as const
export type InternalCrossReferenceCheckNature = typeof INTERNAL_CROSS_REFERENCE_CHECK_NATURE_VALUES[number]

export const DEFINED_TERMS_NATURE_VALUES = [
  'clear definition',
  'implicit definition',
  'unclear definition',
  'inconsistent definition',
  'missing definition'
] as const
/**
 * Nature of a issue related to a defined term, e.g., whether it is clear, unclear, ...
 * Only used for sanity check analysis of defined terms.
 */
export type DefinedTermsNature = typeof DEFINED_TERMS_NATURE_VALUES[number]

/**
 * Nature of an issue, which will depend on the type of analysis.
 */
export type Nature = (
  ComparativeAnalysisNature
  | TypographicalErrorsNature
  | InternalCrossReferenceCheckNature
  | DefinedTermsNature
)

export const TERM_NATURE_VALUES = [
  'explicitly defined term',
  'implicitly defined term',
  'definable term'
] as const
/**
 * Nature of a term, e.g., whether it is explicitly defined, implicitly defined, ...
 * Only used for sanity check analysis of defined terms.
 * Not to be confused with Nature, which is about the nature of the issue!
 */
export type TermNature = typeof TERM_NATURE_VALUES[number]

/**
 * Options for the sanity check.
 * FIXME: should get those from the backend via /globals
 */
export enum SanityCheckOption {
  TypographicalErrors = 'Typographical errors',
  InternalCrossReferenceCheck = 'Internal cross-reference check',
  NumberingIssues = 'Numbering issues',
  Redundancies = 'Redundancies',
  InternalLogicErrors = 'Internal logic errors',
  AmbiguousTermsAndPhrases = 'Ambiguous terms and phrases',
  DefinedTerms = 'Defined terms',
  // Deprecated options (kept for compatibility),
  ImpreciseDefinitions = 'Imprecise definitions',
  LogicalErrors = 'Logical errors',
  MathErrors = 'Math errors',
  StructuralErrors = 'Structural errors',
  LogicalContradictions = 'Logical contradictions',
  OmissionsOfImportantTerms = 'Omissions of important terms',
}

/**
 * Reference to a document.
 * The filename is NOT included in the reference because it is not anonymized
 * and should end up in the final answer and thus history of the conversation
 * that models will have access to.
 */
export interface DocumentReference {
  /**
   * UUID of the document.
   */
  docId: UUID | null
  /**
   * Type of the document
   */
  docType: DocType | null
}

/**
 * Description of a passage that is an evidence of an issue in the analyzed contract,
 * which can be either in the contract itself or the reference to which it is compared.
 */
export interface Evidence {
  /**
   * Reference to the document where the passage is found.
   */
  docRef: DocumentReference | null
  /**
   * Location of the passage in the document, e.g., a section number.
   */
  location: string | null
  /**
   * The passage itself.
   */
  quote: string
  /**
   * Start and end indices of what should be highlighted in the passage.
   */
  markedSpan: [number, number] | null
  /**
   * The first five words of the paragraph containing the quote.
   */
  startingWith: string | null
  /**
   * The location of the target for cross-references checks.
   */
  targetLocation: string | null
}

/**
   * Description of a correction to be made to the analyzed document.
   */
export interface Correction {
  /**
   * The text to be replaced.
   */
  before: string
  /**
   * The text to replace with.
   */
  after: string
}

/**
 * Description of an issue in the analyzed document.
 */
export interface DocReviewIssue {
  /**
   * Category of the issue.
   */
  category: IssueCategory

  /**
   * Type of the issue.
   */
  type: IssueType

  /**
   * Level of severity of the issue.
   */
  criticality: Criticality | null

  /**
   * Reasoning that led to the explanation.
   */
  reasoning: string | null

  /**
   * A few sentences to explain what the issue is.
   */
  explanation: string

  /**
   * Short title of the issue.
   */
  title: string | null

  /**
   * Nature of the issue, e.g. modification, omission, ...
   */
  nature: Nature | null

  /**
   * List of passages that should be retrieved to identify the error. May be empty if the issue is not related
  to a specific passage, for example if something is missing from the document.
   */
  evidences: Evidence[]

  /**
   * List of corrections to be made to the analyzed document to fix the issue.
   */
  corrections: Correction[] | null

  /**
   * The content of the clause in the Reviewed Document
   */
  reviewedClause: string | null

  /**
   * The location of the clause in the Reviewed Document
   */
  reviewedClauseLocation: string | null

  /**
   * The content of the clause in the Reference Document
   */
  referenceClause: string | null

  /**
   * The location of the clause in the Reference Document
   */
  referenceClauseLocation: string | null

  /**
   * Whether the reviewed clause contains a point of attention
   */
  isPointOfAttention: boolean | null

  /**
   * Name of the term. Only used for defined terms.
   */
  term: string | null

  /**
   * Nature of the term. Only used for defined terms.
   */
  termNature: TermNature | null
}

/**
   * Result of a doc review task that is unstructured
   */
export interface OtherDocReviewResult {
  /**
   * Which analysis was selected, or user input if the option is not predefined.
   */
  option: string
  /**
   * Output of the model.
   */
  result: string
}

export type DocReviewResults = Array<DocReviewIssue | OtherDocReviewResult>
