import AddIcon from '@mui/icons-material/Add'
import { Box, IconButton, MenuItem, Select, type SelectChangeEvent, styled, Typography } from '@mui/material'
import { useGlobals } from 'context/GlobalsContext'
import useComparativeAnalysisOptions from 'features/comparative-analysis/hooks/useComparativeAnalysisOptions'
import { FileUploader } from 'features/documents'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ComparativeAnalysisDocumentSelect from './ComparativeAnalysisDocumentSelect'

const DocumentSelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexBasis: '50%',
  flexGrow: 0,
  flexShrink: 1,
  alignItems: 'center',
  gap: theme.spacing(1)
}))

/**
 * Component for selecting which attached documents to use in a comparative analysis.
 */
const ComparativeAnalysisDocumentsSelect: React.FC = () => {
  const intl = useIntl()
  const globals = useGlobals()
  // Tracks the number of additional reference documents selects to display
  const [additionalReferenceCount, setAdditionalReferenceCount] = useState<number>(0)
  const { options, selectedComparisonType, updateOptions } = useComparativeAnalysisOptions()

  // When the selected comparison type changes (for example from "Playbook Analysis" to "Gold Standard Analysis"),
  // update the isSameMatter option to the first accepted value
  useEffect(() => {
    console.debug('> ComparativeAnalysisDocumentsSelect [selectedComparisonType]', selectedComparisonType)

    if (selectedComparisonType === undefined) {
      return
    }
    if (selectedComparisonType.acceptedSameMatterValues.length === 0) {
      throw new Error('Selected comparison type has no accepted same matter values')
    }

    // Select the first option by default for the isSameMatter option
    const firstAcceptedSameMatterValue = selectedComparisonType.acceptedSameMatterValues[0]
    updateOptions({
      isSameMatter: firstAcceptedSameMatterValue
    })
  }, [selectedComparisonType])

  if (selectedComparisonType === undefined) {
    throw new Error('Selected comparison type is undefined, can\'t render ComparativeAnalysisDocumentsSelect')
  }

  // The maximum number of reference documents is constrained by the number of
  // attachments allowed, and the number of reference documents allowed for the
  // selected comparison type (if any).
  // This is useful to not show a "+" button when the maximum number of reference
  // documents is reached.
  const maxNbDocs = Math.min(
    globals.attachments.maxNbDocs,
    selectedComparisonType.maxNbReferenceDocs ?? Infinity
  )

  // Check if all current reference documents are selected, which is a prerequisite
  // for adding a new reference document to select
  const canAddReference = (): boolean => {
    // Start from index 1 (first reference doc) up to the last additional reference
    for (let i = 1; i <= additionalReferenceCount + 1; i++) {
      if (options.attachmentsIds[i] === null) {
        return false
      }
    }
    // Don't allow adding more reference documents if the maximum number of attachments is reached
    if (additionalReferenceCount + 2 >= maxNbDocs) {
      return false
    }
    return true
  }

  const handleAddReference = (): void => {
    if (canAddReference()) {
      setAdditionalReferenceCount(prev => prev + 1)
    }
  }

  const handleSameMatterChange = (event: SelectChangeEvent<string>): void => {
    updateOptions({
      isSameMatter: event.target.value === 'true'
    })
  }

  const renderReferenceDocuments = (): JSX.Element[] => {
    const elements: JSX.Element[] = []

    // Add the first reference document (always present)
    elements.push(
      <>
        <ComparativeAnalysisDocumentSelect index={1} />
        <FileUploader iconOnly />
      </>
    )

    // Add additional reference documents
    for (let i = 0; i < additionalReferenceCount; i++) {
      elements.push(
        <>
          <Typography>
            {intl.formatMessage({
              id: 'app.comparative-analysis-options-select.and',
              defaultMessage: 'and'
            })}
          </Typography>
          <ComparativeAnalysisDocumentSelect index={2 + i} />
          <FileUploader iconOnly />
        </>
      )
    }

    return elements
  }

  const iconButtonAriaLabel = intl.formatMessage({
    id: 'app.comparative-analysis.add-reference-document.aria-label',
    defaultMessage: 'Add another reference document'
  })
  const iconButtonTitle = !canAddReference()
    ? intl.formatMessage({
      id: 'app.comparative-analysis.add-reference-document.disabled',
      defaultMessage: 'Please select all current reference documents before adding a new one'
    })
    : iconButtonAriaLabel

  return (
    <>
      <DocumentSelectContainer>
        <ComparativeAnalysisDocumentSelect index={0} />
        <FileUploader iconOnly />
      </DocumentSelectContainer>

      <Typography sx={{ color: 'text.primary' }}>
        {intl.formatMessage({
          id: 'app.comparative-analysis-options-select.to-identify-deviations-from',
          defaultMessage: 'in light of'
        })}
      </Typography>

      {renderReferenceDocuments().map((element, index) => (
        <DocumentSelectContainer key={index}>
          {element}
        </DocumentSelectContainer>
      ))}

      {/* Icon button to add another select menu, for another reference document */}
      {maxNbDocs > 2 && (
        <IconButton
          onClick={handleAddReference}
          sx={{ color: 'primary.main' }}
          disabled={!canAddReference()}
          aria-label={iconButtonAriaLabel}
          title={iconButtonTitle}
      >
          <AddIcon />
        </IconButton>
      )}

      {/* Select whether it is the same matter or not, if there are multiple options to choose from */}
      {selectedComparisonType.acceptedSameMatterValues.length > 1 && (
        <>
          <Typography>
            {intl.formatMessage({
              id: 'app.comparative-analysis-options-select.from',
              defaultMessage: 'from'
            })}
          </Typography>
          <Select
            id='same-matter-select'
            value={options.isSameMatter.toString()}
            onChange={handleSameMatterChange}
            aria-label="Same matter or not"
          >
            {
              selectedComparisonType.acceptedSameMatterValues.map((sameMatterValue, index) => (
                <MenuItem key={index} value={sameMatterValue.toString()}>
                  {
                    intl.formatMessage({
                      id: `app.comparative-analysis-options-select.same-matter.${sameMatterValue.toString()}`
                    })
                  }
                </MenuItem>
              ))
            }
          </Select>
        </>
      )}
    </>
  )
}

export default ComparativeAnalysisDocumentsSelect
