import { FiltersContainer } from 'features/analyze/components/contextual-issue/ContextualIssuesDisplay.styles'
import DocReviewIssuesFilters from 'features/analyze/components/DocReviewIssuesFilters'
import useDocReviewIssuesFilter from 'features/analyze/hooks/useDocReviewIssuesFilter'
import { type DocReviewIssue, type IssueType } from 'features/analyze/types'
import { type AttachmentListItem } from 'features/documents'
import InternalIssueDisplayContainer from './InternalIssueDisplayContainer'
import InternalIssuesDescription from './InternalIssuesDescription'
import { InternalIssueContainer, InternalIssuesContainer } from './InternalIssuesDisplay.styles'

interface Props {
  typeToResults: Map<IssueType, DocReviewIssue[]>
  reviewedDoc: AttachmentListItem
}

/**
 * Displays the results of an internal analysis,
 * which is a list of issues found for each issue type that was analyzed.
 */
export const InternalIssuesDisplay: React.FC<Props> = ({
  typeToResults,
  reviewedDoc
}: Props): JSX.Element => {
  // Flatten the issues from all issue types into a single list
  const results = Array.from(typeToResults).flatMap(([_, results]) => results)

  const {
    filteredIssues,
    filters,
    setType,
    setSort,
    setCriticalities,
    setIssueTypes
  } = useDocReviewIssuesFilter(results)

  return (
    <InternalIssuesContainer className='internal-issues'>
      <InternalIssuesDescription typeToResults={typeToResults} reviewedDoc={reviewedDoc} />
      <FiltersContainer className='filters-container'>
        <DocReviewIssuesFilters
          issueCategory='Internal'
          sortBy={filters.sortBy}
          type={filters.type}
          onTypeChange={setType}
          onSortChange={setSort}
          onCriticalitiesChange={setCriticalities}
          onIssueTypesChange={setIssueTypes}
        />
      </FiltersContainer>
      <InternalIssueContainer className='internal-issues-container'>
          {
            filteredIssues.map((result, idx) => (
              <div className='internal-issue-item' key={idx}>
                <InternalIssueDisplayContainer
                  idx={idx}
                  result={result}
                  reviewedDoc={reviewedDoc}
                />
              </div>
            ))
          }
      </InternalIssueContainer>
    </InternalIssuesContainer>
  )
}

export default InternalIssuesDisplay
