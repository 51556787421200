import { Box, type SxProps, type Theme } from '@mui/material'
import useSidepanes from './SidepanesContext'
import { CENTRAL_PANE_MAX_WIDTH_IN_PX, CENTRAL_PANE_MIN_WIDTH_IN_PX } from './types'

interface Props {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

/**
 * Renders the central pane that contains the main content of the application.
 * It is dimensioned and positioned based on the state of the sidepanes.
 */
export const CentralPane: React.FC<Props> = ({
  children, sx
}: Props) => {
  const { centralPaneRef } = useSidepanes()

  return (
    <Box
      className='central-pane'
      component="main"
      ref={centralPaneRef}
      sx={{
        // Allow the central pane to shrink, to leave space for the sidepanes
        flexGrow: 1,
        flexShrink: 1,
        maxWidth: `${CENTRAL_PANE_MAX_WIDTH_IN_PX}px`,
        minWidth: `${CENTRAL_PANE_MIN_WIDTH_IN_PX}px`,
        width: '100%',
        // minWidth: 0, // Add this to allow shrinking below content size
        // The margin is to keep it centered in the space between the sidepanes.
        margin: '0 auto',

        // Rest of the styling
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',

        // Spread any user-provided sx
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

export default CentralPane
