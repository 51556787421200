import { alpha, Box, styled } from '@mui/material'

export type FloatingViewIconPlacement = 'right' | 'top-right' | 'bottom-right'

interface StyledBoxProps {
  placement?: FloatingViewIconPlacement
}

/**
 * Style to position the view icon floating above the label, on its right side
 */
export const StyledBox = styled(Box)<StyledBoxProps>(({ placement = 'right', theme }) => ({
  position: 'absolute',
  right: 0,
  ...(placement === 'right' && {
    top: '50%',
    transform: 'translateY(-50%)'
  }),
  ...(placement === 'top-right' && {
    top: 0
  }),
  ...(placement === 'bottom-right' && {
    bottom: 0
  }),
  display: 'flex',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.main, 0.5),
  borderRadius: '9999px',
  padding: theme.spacing(0.1), // Put some padding to make the background larger and more visible
  zIndex: 1000 // Ensure it's above other elements
}))
