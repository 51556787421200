import { SessionContext, type SessionContextType } from 'features/sessions/providers/SessionContext'

const mockSession = {
  id: '123',
  title: 'Mock Session',
  status: 'active',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString()
} as const

const mockSessionContext: SessionContextType = {
  selectedSessionId: mockSession.id,
  selectedSession: mockSession,
  changeSelectedSession: () => { }
}

interface Props {
  children?: React.ReactNode
}

export const MockSessionContextProvider = ({ children }: Props): JSX.Element => (
  <SessionContext.Provider value={mockSessionContext}>
    {children}
  </SessionContext.Provider>
)

export default MockSessionContextProvider
