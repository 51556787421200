/**
 * Hook to get and update the options for the draft task,
 * which are nested within the user input form state.
 */

import useListAttachments from 'features/documents/api/listAttachments'
import { useEffect } from 'react'
import useDraftTaskParams from './useDraftTaskParams'
import { type AttachmentListItem } from 'features/documents'
import { type UUID } from 'crypto'

interface UseSelectedDocuments {
  selectedTemplate: AttachmentListItem | null
  selectedSources: AttachmentListItem[]
  otherAttachments: AttachmentListItem[]
  disabled: boolean
  swapSelectedDocuments: () => void
  setTemplateId: (templateId: UUID | null) => void
  setSourceIds: (sourceIds: UUID[]) => void
}

/**
 * Hook to get and update the selected documents for the draft task.
 * It also handles the case where a selected document is no longer available
 * (e.g. was removed from the attachments list).
 */
const useSelectedDocuments = (): UseSelectedDocuments => {
  // The list of documents to choose from is the list of all attachments
  const _attachments = useListAttachments()
  const attachments = _attachments ?? []
  const { draftTaskParams, initWithAttachments, templateId, setTemplateId, setSourceIds, removeSourceIds, swapTemplateAndFirstSource } = useDraftTaskParams()

  // React to changes in the attachments list
  useEffect(() => {
    console.debug('> useSelectedDocuments')

    // If the attachments list now has 2 or more attachments,
    // and the user has not yet selected any, we will select those
    // documents by defaults
    if (attachments.length >= 2) {
      initWithAttachments(attachments)
    }

    // Handle the case where a selected document is no longer available
    // (e.g. was removed from the attachments list)
    const templateId = draftTaskParams.templateId
    if (templateId !== undefined && templateId !== null) {
      const templateAttachment = attachments.find((attachment) => attachment.id === templateId)
      if (templateAttachment === undefined) {
        setTemplateId(null)
      }
    }
    const sourceIds = draftTaskParams.sourceIds
    if (sourceIds !== undefined && sourceIds !== null && sourceIds.length > 0) {
      // Find which source attachments have been removed from the attachments list
      const sourceAttachments = attachments.filter((attachment) => sourceIds.includes(attachment.id))
      if (sourceAttachments.length !== sourceIds.length) {
        removeSourceIds(sourceIds)
      }
    }
  }, [attachments])

  // React to change in the selected template
  // by updating sources to be all the other attachments
  useEffect(() => {
    console.log('> useSelectedDocuments [templateId]', templateId)
    const newSourceIds = attachments.filter((attachment) => attachment.id !== templateId).map((attachment) => attachment.id)
    setSourceIds(newSourceIds)
  }, [templateId])

  const swapSelectedDocuments = (): void => {
    swapTemplateAndFirstSource()
  }

  // Whether to disable the document selection UI or not
  const disabled = attachments === undefined || attachments.length === 0

  const selectedTemplate = attachments.find((attachment) => attachment.id === draftTaskParams.templateId) ?? null
  const selectedSources = attachments.filter((attachment) => draftTaskParams.sourceIds?.includes(attachment.id))
  const otherAttachments = attachments.filter((attachment) => !((draftTaskParams.sourceIds?.includes(attachment.id)) ?? false) && attachment.id !== draftTaskParams.templateId)

  return {
    selectedTemplate,
    selectedSources,
    otherAttachments,
    disabled,
    swapSelectedDocuments,
    setTemplateId,
    setSourceIds
  }
}

export default useSelectedDocuments
