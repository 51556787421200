import { type UUID } from 'crypto'
import { mapAttachmentsById, regroupResultsByCategory } from 'features/analyze/api'
import { ContextualIssuesDisplay } from 'features/analyze/components/contextual-issue'
import { InternalIssuesDisplay } from 'features/analyze/components/internal-issue'
import OtherDocReviewResultDisplay from 'features/analyze/components/OtherDocReviewResultDisplay'
import { type DocReviewResults } from 'features/analyze/types'
import { type AttachmentListItem } from 'features/documents'
import { ExportModifiedDocButton } from 'features/export-document'
import { type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form/types'
import { StyledBox } from './DocReviewResultsDisplay.styles'

interface Props {
  results: DocReviewResults
  /**
   * The UserInputForm contains parameters that we need to use when displaying in the result,
   * such as which attachment was the reviewed or reference document,
   * and what their corresponding filenames are.
   */
  userInputForm: UserInputForm
  sessionId: string
  messageIdx: number
}

/**
 * Display the results of an Analyze task, with a few cases to handle.
 * - Structured results are displayed first, if any.
 *   - Internal issues is one case (e.g., sanity check)
 *   - Contextual issues is another case (e.g., comparative analysis)
 * - Unstructured results are displayed last, if any
 * Note: the way doc results is designed, it is not possible to have both internal and contextual issues at the same time.
 */
const DocReviewResultsDisplay: React.FC<Props> = ({ results, userInputForm, sessionId, messageIdx }: Props) => {
  if (userInputForm.attachments.length === 0) {
    throw new Error('No attachments found in the user input form, this should never happen for an Analyze task.')
  }

  const attachmentIdToAttachmentListItem = mapAttachmentsById(userInputForm.attachments)

  // Extract information specific to comparative analysis, if any
  const comparativeAnalysisOptions = (
    (userInputForm?.taskParams as AnalysisTaskParams)?.comparativeAnalysisOptions ?? null
  )
  const reviewedDocId = comparativeAnalysisOptions?.attachmentsIds[0] ?? null
  const referenceDocsIds = (comparativeAnalysisOptions?.attachmentsIds ?? []).slice(1).filter((id) => id !== null) as UUID[]

  // Get the corresponding AttachmentListItem object for the reviewed doc
  // and the reference docs, if any.
  // In the case of internal issues, the reviewed doc is simply
  // the first attachment in the attachment list.
  const reviewedDoc = (
    reviewedDocId !== null
      ? attachmentIdToAttachmentListItem.get(reviewedDocId)
      : userInputForm.attachments[0]
  )
  const referenceDocs = referenceDocsIds.map((id) => attachmentIdToAttachmentListItem.get(id)).filter((doc) => doc !== undefined) as AttachmentListItem[]
  if (reviewedDoc === undefined || referenceDocs === undefined) {
    throw new Error('Could not find attachment list item for the reviewed or reference document in the attachment list')
  }

  // Group the results to display them by category and type
  const { categoryToTypeToResults, unstructuredResults } = regroupResultsByCategory(results)

  const internalTypeToResults = categoryToTypeToResults.get('Internal')
  const contextualTypeToResults = categoryToTypeToResults.get('Contextual')

  const internalElements = internalTypeToResults !== undefined && (
    <>
      <InternalIssuesDisplay typeToResults={internalTypeToResults} reviewedDoc={reviewedDoc} />
      <ExportModifiedDocButton
        sessionId={sessionId}
        messageIdx={messageIdx} />
    </>
  )

  if (contextualTypeToResults !== undefined && (reviewedDoc === null || referenceDocs.length === 0)) {
    throw new Error('Reviewed or reference document is null, but there are contextual issues to display.')
  }
  const contextualElements = (contextualTypeToResults !== undefined && reviewedDoc !== null && referenceDocs.length !== 0) && (
    <>
      <ContextualIssuesDisplay
        typeToResults={contextualTypeToResults}
        reviewedDoc={reviewedDoc}
        referenceDocs={referenceDocs} />
      <ExportModifiedDocButton
        sessionId={sessionId}
        messageIdx={messageIdx} />
    </>
  )

  const unstructuredElements = unstructuredResults.length > 0 && (
    <div className='doc-review-other-results'>
      {unstructuredResults.map((result, idx) => {
        return (
          <div className='doc-review-other-results-item' key={idx}>
            <OtherDocReviewResultDisplay result={result} />
          </div>
        )
      })}
    </div>
  )

  return (
    <StyledBox className='doc-review-results'>
      {/*
        Display structured results first, if any.
        Internal issues and contextual issues both have
        their specific components to display
      */}
      {internalElements}
      {contextualElements}
      {/* Display unstructured results, if any */}
      {unstructuredElements}
    </StyledBox>
  )
}

export default DocReviewResultsDisplay
