import { type UUID } from 'crypto'
import { DOC_TYPE_NOT_CERTAIN, type AttachmentsAndUploads } from 'features/documents'
import { type InputValidationResult } from 'features/send-message'
import { type AnalysisTaskParams } from 'features/user-input-form'

/**
 * Check if the input is valid for sending a message with a comparative analysis task.
 */
export const isValidComparativeAnalysisInputs = (
  taskParams: AnalysisTaskParams,
  attachments: AttachmentsAndUploads
): InputValidationResult => {
  // It must have some comparative analysis options
  if (taskParams.comparativeAnalysisOptions === undefined) {
    return [false, 'Select analysis options']
  }

  const attachmentsIds: Array<UUID | null> | null = (
    taskParams.comparativeAnalysisOptions?.attachmentsIds ?? null
  )
  // Check if the attachmentsIds are valid
  if (
    attachmentsIds === null || attachmentsIds.some((id: UUID | null) => id === null)
  ) {
    return [false, 'Select analysis options']
  }

  // Find the corresponding uploaded attachments
  const selectedAttachments = attachments.uploaded.filter((attachment) => attachmentsIds.includes(attachment.id))
  if (selectedAttachments.length !== attachmentsIds.length) {
    console.error('Some attachmentsIds of the comparative analysis are not in the uploaded attachments!')
    return [false, 'Select analysis options']
  }

  // Check that all the selected attachments have a valid document type: it should not be "Document type not certain"
  if (selectedAttachments.some((attachment) => attachment.docType === DOC_TYPE_NOT_CERTAIN)) {
    return [false, 'Select document types']
  }

  return [true, '']
}

export default isValidComparativeAnalysisInputs
