import { ExpandMore } from '@mui/icons-material'
import { Divider, Menu, MenuItem, Tooltip } from '@mui/material'
import { useState, type FC } from 'react'
import { useIntl } from 'react-intl'
import UserAvatar from '../UserAvatar'
import HelpMenuItem from './HelpMenuItem'
import { ReportMenuItem } from './ReportMenuItem'
import LogoutMenuItem from './LogoutMenuItem'
import ColorModeMenuItem from './ColorModeMenuItem'
import LanguageMenuItem from './LanguageMenuItem'
import { useAuth } from 'context/AuthContext'
import { UserMenuButton, UserMenuContainer } from './UserMenu.styles'

interface Props {
  onOpenChange?: (isOpen: boolean) => void
}

/**
 * User menu component: a dropdown menu that appears when clicking on the user avatar
 */
const UserMenu: FC<Props> = ({ onOpenChange }): JSX.Element => {
  const intl = useIntl()
  const currentUser = useAuth()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    onOpenChange?.(true)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
    onOpenChange?.(false)
  }

  const tooltip = intl.formatMessage(
    { id: 'app.user-menu.tooltip', defaultMessage: 'Logged as {emailAddress}.{br}Click to open user menu' },
    { emailAddress: currentUser?.email() ?? 'john.doe@example.com', br: <br /> }
  )

  return (
    <UserMenuContainer className='user-menu-container'>
      <Tooltip title={tooltip}>
        <UserMenuButton className='user-menu-button' onClick={handleClick}>
          {/* Add a padding to the top of the avatar to align it with box (not sure why it's not centered) */}
          <UserAvatar sx={{ paddingTop: '2px' }} />
          <ExpandMore sx={{ marginRight: '2px' }} />
        </UserMenuButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '8px'
            }
          }
        }}
      >
        <HelpMenuItem />
        <ReportMenuItem />
        <ColorModeMenuItem />
        <LanguageMenuItem />
        <Divider />
        <LogoutMenuItem />
      </Menu>
    </UserMenuContainer>
  )
}

export default UserMenu
