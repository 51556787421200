import { isValidComparativeAnalysisInputs } from 'features/comparative-analysis'
import { type AttachmentsAndUploads } from 'features/documents'
import { isValidDraftInput } from 'features/draft'
import { type InputValidationResult } from 'features/send-message'
import { AnalysisTaskAction, type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form'

/**
 * Check if the input is valid for sending a message.
 * Returns a tuple of [isValid, validationMessage]
 */
export const isValidInput = (
  input: string,
  userInputForm: Partial<UserInputForm>,
  attachments: AttachmentsAndUploads
): InputValidationResult => {
  // Not valid if an upload is in progress
  // This corresponds to our pending list, excluding the failed uploads.
  const pendingUploadsNotFailed = attachments.pending.filter((upload) => upload.status !== 'FAILURE')
  if (pendingUploadsNotFailed.length > 0) return [false, 'Wait for uploads to complete']

  // A non-empty text input is always sufficient for any task
  if (input.trim().length > 0) return [true, '']

  // Input may be left blank if user has selected a task, and action and some options
  if (userInputForm.mainTask === undefined || userInputForm.mainTask === null) {
    return [false, 'Select a task']
  }

  if (userInputForm.mainTask === 'Analyze') {
    return isValidAnalysisInput(userInputForm, attachments)
  }

  if (userInputForm.mainTask === 'Draft') {
    return isValidDraftInput(input, userInputForm)
  }

  // For Summarize or Translate, input is only required if no attachments are present
  if (userInputForm.mainTask === 'Summarize' || userInputForm.mainTask === 'Translate') {
    if (attachments.uploaded.length === 0) {
      return [false, 'Add a document or enter instructions']
    }
    return [true, '']
  }

  // For the default task, suggest to enter instructions or selecting a task
  if (userInputForm.mainTask === 'Chat') {
    return [false, 'Enter instructions or select a task']
  }

  // For all other tasks, input is required if no attachments are present
  return [false, 'Enter instructions']
}

/**
 * Check if the input is valid for sending a message with an analysis task.
 */
export const isValidAnalysisInput = (
  userInputForm: Partial<UserInputForm>,
  attachments: AttachmentsAndUploads
): InputValidationResult => {
  if (attachments.uploaded.length === 0) {
    return [false, 'Add a document to analyze']
  }

  const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined
  if (taskParams?.action === undefined) {
    return [false, 'Select an analysis type']
  }

  switch (taskParams.action) {
    case AnalysisTaskAction.SanityCheck:
      if (taskParams.sanityCheckOptions === undefined || taskParams.sanityCheckOptions.length === 0) {
        return [false, 'Select analysis options']
      }
      break
    case AnalysisTaskAction.ComparativeAnalysis:
      return isValidComparativeAnalysisInputs(taskParams, attachments)
    case AnalysisTaskAction.SpecificAnalysis:
      if (taskParams.specificAnalysisOptions === undefined || taskParams.specificAnalysisOptions.length === 0) {
        return [false, 'Select analysis options']
      }
      break
  }
  return [true, '']
}
