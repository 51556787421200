import { useAuth0 } from '@auth0/auth0-react'
import SendIcon from '@mui/icons-material/Send'
import {
  Box,
  Button,
  Fade,
  Modal,
  Tab,
  Tabs,
  Tooltip
} from '@mui/material'
import CancelButton from 'components/buttons/CancelButton'
import CloseModalButton from 'components/buttons/CloseModalButton'
import { CopyToClipboardButton } from 'components/buttons/CopyToClipboardButton'
import { ExportAnnotationButton } from 'components/buttons/ExportAnnotationButton'
import GlobalRulesPane from 'components/GlobalRulesPane'
import ProgressBackdrop from 'components/progress/ProgressBackdrop'
import InputDiff from 'components/review-modal/inputs/InputDiff'
import SnapToggleButton from 'components/review-modal/inputs/SnapToggleButton'
import InputReviewTable from 'components/review-modal/side-pane/InputReviewTable'
import SidePaneToggleButton from 'components/review-modal/side-pane/SidePaneToggleButton'
import { useAuth } from 'context/AuthContext'
import { UserSettingsContext } from 'context/UserSettingsContext'
import { anonymize, type AnonymizeResponse } from 'features/anonymization/api/anonymize'
import { exportAnnotations } from 'features/anonymization/api/exportAnnotations'
import { applyAliasRulesToAnonymizationSettings, applyAnonRulesToAnonymizationSettings } from 'features/anonymization/api/updateAnonymizationSettings'
import { updateMsgAnonymizationRules } from 'features/anonymization/api/updateMsgAnonymizationRules'
import { type AliasRule, type AnonRule, type AnonymizationSettings, type Entity, type EntityType, type MsgSpecificRules, type RuleKey, type Tag } from 'features/anonymization/types'
import { type UserSettings } from 'features/users/types'
import React, { useCallback, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import getConfig from 'services/config'
import { arrEquals } from 'services/utils'
import { ModalTitle } from 'theme'
import { addExactMatchToAnonSettings, addExactMatchToMsgSpecificRules, changeItemInAnonSettings, responseToState, type ReviewState, type ReviewStateItem } from '../../../services/reviewState'
import { FormButtons, GlobalRulesTab, InputDiffs, InputReviewTab, LeftSideButtons, ModalHeader, ReviewModalContainer, ReviewPane, RightSideButtons, Spacer, StyledFormControl } from './ReviewModal.styles'

const emptyMsgSpecificRules = {
  msgAnonymizationRules: [],
  msgAliasRules: []
}

interface ReviewModalProps {
  open: boolean
  input: string
  attachmentIds: string[]
  initExistingEntities: Entity[]
  initResponse: AnonymizeResponse
  onClose: (input: string) => void
  onSkip: (texts: string[], attachmentIds: string[]) => void
  onSubmit: (anonTexts: string[], attachmentIds: string[], existingEntities: Entity[]) => void
}

export const ReviewModal: React.FC<ReviewModalProps> = (
  {
    open,
    input,
    attachmentIds,
    initExistingEntities,
    initResponse,
    onClose,
    onSkip,
    onSubmit
  }: ReviewModalProps
) => {
  const intl = useIntl()
  const { userSettings, updateUserSettings } = useContext(UserSettingsContext) as {
    userSettings: UserSettings // Assuming userSettings is not null
    updateUserSettings: (newSettings: UserSettings, sessionId?: string) => void
  }
  const { getAccessTokenSilently } = useAuth0()
  const currentUser = useAuth()

  const [state, setState] = React.useState<ReviewState | null>(responseToState(initResponse, userSettings.anonymizationSettings))
  const [processing, setProcessing] = React.useState<boolean>(false)
  const [tabsValue, setTabsValue] = React.useState(0)
  const [leftPaneOpen, setLeftPaneOpen] = React.useState(false)
  const [msgSpecificRules, setMsgSpecificRules] = React.useState<MsgSpecificRules>({ ...emptyMsgSpecificRules })
  const [snap, setSnap] = React.useState(true)

  useEffect(() => {
    console.debug('> ReviewModal [initResponse]')

    // Reset the message-specific rules and which tab is shown in the left pane
    setMsgSpecificRules({ ...emptyMsgSpecificRules })
    setTabsValue(0)

    // Transform the API response from the initial anonymization request
    // into a ReviewState that we can use to render the UI
    setState(responseToState(initResponse, userSettings.anonymizationSettings))
  }, [initResponse])

  useEffect(() => {
    console.debug('> ReviewModal [input, userSettings, msgSpecificRules]')

    // If input has been cleared (or was empty to start with),
    // and there are no attachments, don't call anonymization again
    if (input === '' && attachmentIds.length === 0) {
      setProcessing(false) // Just in case
      return
    }

    void getAccessTokenSilently().then(async (token) =>
      await anonymize(
        token,
        input,
        attachmentIds,
        userSettings.anonymizationSettings,
        msgSpecificRules.msgAnonymizationRules,
        msgSpecificRules.msgAliasRules,
        initExistingEntities,
        currentUser
      )
    ).then((response) => {
      setState(responseToState(response, userSettings.anonymizationSettings))
    }).catch((error) => {
      console.error(error)
    }).finally(() => {
      setProcessing(false)
    })
  }, [input, userSettings, msgSpecificRules])

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    // Check if the keyboard shortcut to submit the form was pressed,
    // and that the modal is actually open!
    if (open && event.ctrlKey && event.key === 'Enter') {
      handleSubmit()
      console.debug('Submitted with Ctrl+Enter')
    }
  }, [open])

  useEffect(() => {
    // Attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [open, handleKeyPress])

  const handleClose = (): void => {
    // Clear up state for next time
    setMsgSpecificRules({ ...emptyMsgSpecificRules })
    setState(null)

    // Call callback function to close the modal
    onClose(input)
  }

  const handleModalClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown'): void => {
    // If the user clicked on the backdrop, don't close the modal
    if (reason === 'backdropClick') {
      return
    }
    handleClose()
  }

  if (state === null) return <></>

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>): void => {
    if (event !== undefined) {
      event.preventDefault()
    }

    // Submit both the anonymized text and the mapping from each replacement
    // (e.g. PERSON_1) to its cleartext, which will be used to de-anonymize the answer
    onSubmit(state.anonTexts, attachmentIds, state.existingEntities)

    // Clear up state for next time
    setMsgSpecificRules({ ...emptyMsgSpecificRules })
    setState(null)
  }

  const handleChangedItemForMsg = (
    prevItem: ReviewStateItem,
    item: ReviewStateItem,
    newItem?: ReviewStateItem,
    includeAliases?: boolean
  ): void => {
    setProcessing(true)
    const cleartexts = (
      (includeAliases ?? false)
        ? [item.cleartext, ...item.aliases]
        : [item.cleartext]
    )
    void getAccessTokenSilently().then(async (token) =>
      await updateMsgAnonymizationRules(
        token,
        input,
        attachmentIds,
        msgSpecificRules.msgAnonymizationRules,
        state.tags,
        cleartexts,
        item.entityType,
        item.posAnonymizeStatus,
        currentUser
      )
    ).then((newMsgAnonymizationRules) => {
      setMsgSpecificRules({
        ...msgSpecificRules,
        msgAnonymizationRules: newMsgAnonymizationRules
      })
    })
  }

  const handleChangedItemGlobally = (
    prevItem: ReviewStateItem,
    item: ReviewStateItem
  ): void => {
    // Did the user change the message-specific rules status?
    if (prevItem.posAnonymizeStatus !== item.posAnonymizeStatus) {
      throw new Error(
        'Passed message-specific update to a function that handle global rules'
      )
    }

    setProcessing(true)
    void getAccessTokenSilently().then(async (token) =>
      await changeItemInAnonSettings(token, item, currentUser)
    ).then((newAnonymizationSettings) => {
      updateUserSettings(
        { ...userSettings, anonymizationSettings: newAnonymizationSettings }
      )
    })
  }

  /**
     * Call the API to create a new message-specific anonymization rule
     * and also create a new alias rule if an alias was provided.
     */
  const handleAddExactMatchForMsg = (
    cleartext: string,
    entityType: EntityType,
    position: [number, number] | undefined,
    asAliasOf: string | undefined
  ): void => {
    if (userSettings === null) throw new Error('User settings not found')

    setProcessing(true)
    void getAccessTokenSilently().then(async (token) =>
      await addExactMatchToMsgSpecificRules(
        token,
        cleartext,
        entityType,
        position,
        asAliasOf,
        msgSpecificRules,
        currentUser
      )
    ).then((newMsgSpecificRules) => {
      setMsgSpecificRules(newMsgSpecificRules)
    })
  }

  /**
   * Call the API to create a new user-specific global anonymization rule,
   * and also create a new alias rule if an alias was provided.
   */
  const handleAddExactMatchAsGlobalRule = (
    cleartext: string,
    entityType: EntityType,
    asAliasOf: string | undefined
  ): void => {
    setProcessing(true)
    void getAccessTokenSilently().then(async (token) =>
      await addExactMatchToAnonSettings(
        token,
        cleartext,
        entityType,
        asAliasOf,
        currentUser,
        userSettings.anonymizationSettings
      )
    ).then((newAnonymizationSettings) => {
      changeAnonymizationSettings(newAnonymizationSettings)
    })
  }

  const handleDeleteAnonRule = (key: RuleKey): void => {
    const keysToDelete = [key]

    setProcessing(true)
    void getAccessTokenSilently().then(async (token) =>
      await applyAnonRulesToAnonymizationSettings(
        token,
        currentUser,
        [],
        keysToDelete
      )
    ).then((newAnonymizationSettings) => {
      changeAnonymizationSettings(newAnonymizationSettings)
    })
  }

  /**
   * Call the API to create a new alias rule
   */
  // const handleAddAlias = (alias: string, cleartext: string, entityType: EntityType): void => {
  //   if (userSettings === null) throw new Error('User settings not found')

  //   // Find the review state item that corresponds to our replacement
  //   const item: ReviewStateItem | undefined = state.items.find((item) => item.cleartext === cleartext)
  //   if (item === undefined) {
  //     console.error(`cleartext: ${cleartext}. state.items: `, state.items)
  //     throw new Error('Cannot add an alias to an entity that was not found. cleartext: ' + cleartext)
  //   }

  //   // Find an existing alias rule for that cleartext, if any
  //   const existingRule: AliasRule | undefined = userSettings.anonymizationSettings.aliasRules.find((rule) => rule.cleartext === cleartext && rule.entityType === entityType)

  //   const rulesToAdd: AliasRule[] = [existingRule ?? {
  //     cleartext,
  //     entityType,
  //     aliases: [alias]
  //   }]
  //   const keysToDelete: RuleKey[] = []

  //   setProcessing(true)
  //   void applyAliasRulesToAnonymizationSettings(currentUser, rulesToAdd, keysToDelete).then((newAnonymizationSettings) => {
  //     changeAnonymizationSettings(newAnonymizationSettings)
  //   })
  // }

  /**
   * Call the API to update aliases in an existing alias rule
   */
  const handleChangeAliasRule = (aliasRule: AliasRule): void => {
    const rulesToAdd: AliasRule[] = (
      aliasRule.aliases.length > 0
        ? [aliasRule]
        : []
    )
    const keysToDelete: RuleKey[] = (
      aliasRule.aliases.length > 0
        ? []
        : [{ cleartext: aliasRule.cleartext, entityType: aliasRule.entityType }]
    )

    setProcessing(true)
    void getAccessTokenSilently().then(async (token) =>
      await applyAliasRulesToAnonymizationSettings(
        token,
        currentUser,
        rulesToAdd,
        keysToDelete)
    ).then((newAnonymizationSettings) => {
      changeAnonymizationSettings(newAnonymizationSettings)
    })
  }

  // const changeRuleActiveState = (replacement: string, active: boolean): void => {
  //   if (userSettings === null) throw new Error('User settings not found')

  //   // Find the review state item that corresponds to our replacement
  //   const item: ReviewStateItem | undefined = state.items.find((item) => item.tag?.replacement === replacement)
  //   if (item === undefined) {
  //     console.error(`replacement: ${replacement}. state.items: `, state.items)
  //     throw new Error(`Cannot deactivate an item that was not found. replacement: ${replacement}`)
  //   }
  //   if (!item.active) {
  //     console.error(`replacement: ${replacement}. state.items: `, state.items)
  //     throw new Error(`Cannot deactivate an item already deactivated. replacement: ${replacement}`)
  //   }

  //   const rule: Rule = {
  //     cleartext: item.cleartext,
  //     tag: item.tag,
  //     active
  //   }

  //   setProcessing(true)
  //   void applyRulesToAnonymizationSettings(currentUser, [rule]).then((newAnonymizationSettings) => {
  //     changeAnonymizationSettings(newAnonymizationSettings)
  //   })
  // }

  /**
   * Given a specific tag, will create a message-specific
   * anonymization rule to deactivate it.
   * @param tag Tag to deactivate
   */
  const handleDeactivateAnonForTag = (tag: Tag): void => {
    // Find the existing rule in the message-specific rules, if any
    const existingRule: AnonRule | undefined = msgSpecificRules.msgAnonymizationRules.find((rule) =>
      rule.cleartext === tag.cleartext &&
      rule.entityType === tag.entityType &&
      (
        rule.position !== undefined &&
        tag.position !== undefined &&
        arrEquals(rule.position, tag.position)
      )
    )
    const otherMsgAnonymizationRules = (
      existingRule !== undefined
        ? msgSpecificRules.msgAnonymizationRules.filter((rule) => rule !== existingRule)
        : msgSpecificRules.msgAnonymizationRules
    )

    const rule: AnonRule = (
      existingRule !== undefined
        ? { ...existingRule, anonymize: false }
        : {
            cleartext: tag.cleartext,
            entityType: tag.entityType,
            position: tag.position,
            anonymize: false
          }
    )

    setProcessing(true)
    setMsgSpecificRules({
      ...msgSpecificRules,
      msgAnonymizationRules: [...otherMsgAnonymizationRules, rule]
    })
  }

  // const handleDeactivateAnonRule = (tag: Tag): void => {
  //   // Find the review state item that corresponds to the tag
  //   const item: ReviewStateItem | undefined = state.items.find((item) =>
  //     item.entityType === tag.entityType && item.entityId === tag.entityId
  //   )
  //   if (item === undefined) {
  //     console.error(`tag: ${JSON.stringify(tag)}. state.items: `, state.items)
  //     throw new Error(
  //       `Tried to deactivate anon rule for tag ${JSON.stringify(tag)}, ` +
  //       'but no matching review state item was found.'
  //     )
  //   }
  //   if (item.anonymize === false) {
  //     console.error(`tag: ${JSON.stringify(tag)}. state.items: `, state.items)
  //     throw new Error(
  //       `Tried to deactivate anon rule for tag ${JSON.stringify(tag)}, ` +
  //       'but the matching review state item was already deactivated.'
  //     )
  //   }

  //   const rule: AnonRule = {
  //     cleartext: tag.cleartext,
  //     entityType: tag.entityType,
  //     anonymize: false
  //   }
  //   const rulesToAdd = [rule]

  //   setProcessing(true)
  //   void applyAnonRulesToAnonymizationSettings(currentUser, rulesToAdd, []).then((newAnonymizationSettings) => {
  //     changeAnonymizationSettings(newAnonymizationSettings)
  //   })
  // }

  // const handleActivate = (replacement: string): void => {
  //   changeRuleActiveState(replacement, true)
  // }

  const handleChangeAnonymizationSettings = (newAnonymizationSettings: AnonymizationSettings): void => {
    setProcessing(true)
    changeAnonymizationSettings(newAnonymizationSettings)
  }

  const changeAnonymizationSettings = (newAnonymizationSettings: AnonymizationSettings): void => {
    updateUserSettings(
      { ...userSettings, anonymizationSettings: newAnonymizationSettings }
    )
  }

  const handleCopyAnonTextsToClipboard = (): void => {
    void (async function () {
      await navigator.clipboard.writeText(state.anonTexts.join('\n\n'))
    })()
  }

  const handleExportAnnotations = (): void => {
    // If there are no attachments, there is nothing to export
    if (attachmentIds.length === 0) {
      return
    }
    // Create a JSONL file with the anonymized tags for the attachment
    // (the first one), and download it to the user's computer.
    exportAnnotations(
      attachmentIds[0], // Use the first attachment id as the filename
      state.texts[1], // First attachment is the 2nd text to anonymize!
      state.inputDiffs[1] // Similarly for the input diffs
    )
  }

  const handleSkip = (): void => {
    // Callback that gives not just the input, but also the
    // attachments, so that the parent component can send
    // all of them to the API
    onSkip(state.texts, attachmentIds)
  }

  const tabInputReviewLabel = intl.formatMessage({
    id: 'app.review-modal.tab-input-review.label',
    defaultMessage: 'Input review'
  })

  const tabGlobalRulesLabel = intl.formatMessage({
    id: 'app.review-modal.tab-global-rules.label',
    defaultMessage: 'Global rules'
  })

  const skipButtonTooltip = intl.formatMessage({
    id: 'app.review-modal.skip-button.tooltip',
    defaultMessage: 'Send the input WITHOUT anonymizing anything'
  })

  const skipButtonText = intl.formatMessage({
    id: 'app.review-modal.skip-button.label',
    defaultMessage: 'Skip'
  })

  const cancelButtonTooltip = intl.formatMessage({
    id: 'app.review-modal.cancel-button.tooltip',
    defaultMessage: 'Close the window'
  })

  const submitButtonTooltip = intl.formatMessage({
    id: 'app.review-modal.submit-button.tooltip',
    defaultMessage: 'Send the anonymized input'
  })

  const submitButtonText = intl.formatMessage({
    id: 'app.review-modal.submit-button.label',
    defaultMessage: 'Submit'
  })

  const editForm = (
    <Box>
      <form className='review-modal-form' onSubmit={handleSubmit}>
        <StyledFormControl>
          {/* Left-side: managing anonymization rules */}
          {leftPaneOpen && <InputReviewTab>
            {/* Tabs */}
            <Tabs
              value={tabsValue}
              onChange={(event, value) => { setTabsValue(value) }}
              aria-label="left-pane-tabs"
            >
              <Tab label={tabInputReviewLabel} id='tab-input-review' aria-controls='tabpanel-input-review' />
              <Tab label={tabGlobalRulesLabel} id='tab-global-rules' aria-controls='tabpanel-global-rules' />
            </Tabs>

            {/* Input review tab */}
            {tabsValue === 0 && <InputReviewTab
              role='tabpanel'
              id={'tabpanel-input-review'}
              aria-labelledby={'tab-input-review}'}
            >
              <InputReviewTable
                state={state}
                onAddAnonRule={(cleartext, entityType, asAliasOf) => {
                  handleAddExactMatchForMsg(
                    cleartext,
                    entityType,
                    undefined,
                    asAliasOf
                  )
                }}
                onChange={handleChangedItemForMsg}
                onChangeAliasRule={handleChangeAliasRule}
                onDeleteAnonRule={handleDeleteAnonRule}
              />
            </InputReviewTab>}

            {/* Global rules tab */}
            {tabsValue === 1 && <GlobalRulesTab
              role='tabpanel'
              id={'tabpanel-global-rules'}
              aria-labelledby={'tab-global-rules'}
            >
              <GlobalRulesPane
                anonymizationSettings={userSettings.anonymizationSettings}
                existingEntitiesWithAliases={state.existingEntitiesWithAliases}
                onChange={handleChangedItemGlobally}
                onChangeAliasRule={handleChangeAliasRule}
                onDeleteAnonRule={handleDeleteAnonRule}
                onChangeAnonymizationSettings={handleChangeAnonymizationSettings}
                onAddExactMatchAsGlobalRule={handleAddExactMatchAsGlobalRule}
              />
            </GlobalRulesTab>}
          </InputReviewTab>}

          {/* Button to toggle the left pane */}
          <SidePaneToggleButton
            leftPaneOpen={leftPaneOpen}
            setLeftPaneOpen={() => { setLeftPaneOpen(!leftPaneOpen) }}
          />

          {/* Central/Right-side: preview of anonymized texts */}
          <ReviewPane>
            <ModalHeader>
              <ModalTitle
                className="review-modal-title"
                // Text not selectable, to avoid confusion with selection
                // in the input diffs to add rules
                sx={{ userSelect: 'none' }}
              >
                {
                  intl.formatMessage({
                    id: 'app.review-modal.title',
                    defaultMessage: 'Confidentiality check'
                  })
                }
              </ModalTitle>
              <CloseModalButton onClick={handleClose} />
            </ModalHeader>
            <InputDiffs className='review-modal-input-diffs'>
              {state.inputDiffs.map((inputDiff, idx) => (
                <InputDiff
                  key={idx}
                  inputDiff={inputDiff}
                  docIdx={idx}
                  entityTypeToActiveEntitiesCleartext={state.entityTypeToActiveEntitiesCleartext}
                  snap={snap}
                  onAddExactMatch={
                    // Create both a msg-specific anon rule for this specific
                    // selection, as well as a global anon rule.
                    // If there is an alias rule to create as well,
                    // it will be created as a global rule only.
                    // (This is because we don't want to use any alias
                    // rules in the message-specific rules in order to
                    // keep things simple)
                    (cleartext, entityType, position, asAliasOf) => {
                      handleAddExactMatchForMsg(
                        cleartext, entityType, position, undefined
                      )
                      handleAddExactMatchAsGlobalRule(
                        cleartext, entityType, asAliasOf
                      )
                    }
                  }
                  onDeactivate={handleDeactivateAnonForTag}
                  // onActivate={handleActivate}
                  // onDelete={handleDeleteEntity}
                />
              ))}
            </InputDiffs>
            <FormButtons>
              <LeftSideButtons>
                <SnapToggleButton snap={snap} setSnap={setSnap} />
                <CopyToClipboardButton
                  tooltip={
                    intl.formatMessage({
                      id: 'app.copy-anon-texts-to-clipboard',
                      defaultMessage: 'Copy anonymized texts to clipboard'
                    })
                  }
                  onClick={handleCopyAnonTextsToClipboard}
                />
                {
                  (getConfig('COPILEX_DEV_MODE') === 'true' && attachmentIds.length > 0) &&
                  <ExportAnnotationButton
                    onClick={handleExportAnnotations}
                  />
                }
              </LeftSideButtons>
              <RightSideButtons>
                <Tooltip title={skipButtonTooltip}>
                  <Button variant="outlined" onClick={handleSkip}>
                    {skipButtonText}
                  </Button>
                </Tooltip>
                <Spacer />
                <Tooltip title={cancelButtonTooltip}>
                  <CancelButton onClick={handleClose} />
                </Tooltip>
                <Tooltip title={submitButtonTooltip}>
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SendIcon />}
                  >
                    {submitButtonText}
                  </Button>
                </Tooltip>
              </RightSideButtons>
            </FormButtons>
          </ReviewPane>
        </StyledFormControl>
      </form>
    </Box>
  )

  return (
    <Modal
      className='review-modal'
      open={open}
      onClose={handleModalClose}
      disableEscapeKeyDown
      aria-labelledby="review-modal-title"
      aria-describedby="review-modal-description"
    >
      <Fade in={open}>
        <ReviewModalContainer
          className='review-modal-container'
          sx={{
            width: leftPaneOpen ? '95%' : 'auto'
          }}
        >
          <ProgressBackdrop open={processing}/>
          <Box>
            {editForm}
          </Box>
        </ReviewModalContainer>
      </Fade>
    </Modal>
  )
}

export default ReviewModal
