import { Stack, styled } from '@mui/material'

export const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: theme.spacing(2)
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  width: '100%',
  alignItems: 'flex-end'
}))

export const formControlSx = {
  flex: '1 1 auto',
  minWidth: 0,
  // Split the space equally between the filters
  maxWidth: '25%'
}
