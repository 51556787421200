import { useContext } from 'react'
import { DocumentPanelContext, type DocumentPanelContextValue } from '../providers/DocumentPanelProvider'

export const useDocumentPanel = (): DocumentPanelContextValue => {
  const context = useContext(DocumentPanelContext)
  if (context === undefined) {
    throw new Error('useDocumentPanel must be used within a DocumentPanelContext.Provider')
  }
  return context
}

export default useDocumentPanel
