import { Backdrop, Divider, styled } from '@mui/material'
import { ModalContainer } from 'theme'

export const StyledBackdrop = styled(Backdrop)(() => ({
  color: '#fff',
  zIndex: 9999
}))

export const BackdropContainer = styled(ModalContainer)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: theme.palette.text.primary // Needed for some reason, otherwise text is always white
}))

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.text.primary
}))

export const bottomContainerSx = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}
