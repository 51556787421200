import Mark, { type MarkFilterInfo, type MarkOptions } from 'advanced-mark.js'
import { type Context, createContext, type FC, type ReactNode, useContext, useEffect, useRef, useState } from 'react'

export interface HighlighterContextValue {
  /**
   * The texts to search for. Empty array means no search is active.
   *
   * IMPORTANT: if there are multiple search texts, the first match will act as a filter
   * for the remaining search texts:
   * - Say we search for "hello" and "world", it will first search for "hello"
   * - If "hello" is found, and there are matches for "world" before the first "hello" match,
   *   they will be rejected.
   * - If "hello" is not found, the first match for "world" will have a similar effect.
   *
   * This was done to deal with quotes that are split on ellipsis, e.g.,
   * "12. Warranty... Party A... will be liable for any damages" -> ["12. Warranty", "Party A", "will be liable for any damages"]
   * If there are matches for "Party A" before the first "12. Warranty" match, those matches must be rejected
   * because they are not part of the quote.
   */
  searchTexts: string[]
  /**
   * Set the texts to search for.
   */
  setSearchTexts: (texts: string[]) => void
  /**
   * Reference that should be set on the container element where highlighting should be applied.
   */
  containerRef: React.RefObject<HTMLDivElement>
  /**
   * The total number of matches found.
   */
  totalMatches: number
}

const HighlighterContext: Context<HighlighterContextValue | null> = createContext<HighlighterContextValue | null>(null)

interface ProviderProps {
  scrollToFirstMatch?: boolean
  children: ReactNode
}

/**
 * Provider that manages text highlighting within its children using advanced-mark.js.
 * Wrap a component that need highlighting functionality with this provider,
 * set the ref of the component element to the containerRef prop,
 * and use the useHighlighter hook to access its functionalities.
 */
export const HighlighterProvider: FC<ProviderProps> = ({ scrollToFirstMatch = true, children }: ProviderProps): JSX.Element => {
  const markInstance = useRef<Mark | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [searchTexts, setSearchTexts] = useState<string[]>([])
  /**
   * Counter to force re-applying highlights when the search texts are updated
   * with the same texts. This is necessary for its use in the document panel,
   * as we first try to lookup the search texts when the document might not be loaded yet,
   * and a second time when the document is loaded. Without this counter,
   * the state would not be updated the second time, and the highlights would not be reapplied.
   */
  const [forceUpdateCounter, setForceUpdateCounter] = useState<number>(0)
  /**
   * Track the first match element to scroll to it when it is found.
   * Note: if there is more than one search text, the first match element
   * will be the first match of the first search text (e.g., beginning of a quote
   * that was split on ellipsis).
   */
  const [firstMatchElement, setFirstMatchElement] = useState<Element | null>(null)
  const [totalMatches, setTotalMatches] = useState<number>(0)

  // Initialize mark instance when container ref is set
  useEffect(() => {
    console.debug('> HighlighterProvider [containerRef]', containerRef)
    if (containerRef.current != null) {
      console.debug('> HighlighterProvider: creating mark instance for containerRef', containerRef.current)
      markInstance.current = new Mark(containerRef.current)
      // Reapply highlights after some time to ensure the container is fully rendered.
      // For some reason this is necessary, otherwise the highlights might not be applied.
      applyHighlights(markInstance.current)
    }
  }, [
    containerRef,
    // Necessary to (re-)initialize the mark instance when the container is changed
    containerRef.current?.innerHTML
  ])

  // Update highlighting when search text changes or when force update is triggered
  useEffect(() => {
    console.debug('> HighlighterProvider [searchTexts, forceUpdateCounter]', searchTexts, forceUpdateCounter)

    // Reset firstMatchElement when search text is empty
    if (searchTexts.length === 0) {
      setFirstMatchElement(null)
      setTotalMatches(0)
    }

    if (markInstance.current != null) {
      applyHighlights(markInstance.current)
    } else if (searchTexts.length > 0) {
      console.error('Highlighter not initialized, cannot update highlighting')
    }
  }, [searchTexts, forceUpdateCounter])

  // Scroll to first match element when it is found
  useEffect(() => {
    if (firstMatchElement != null && scrollToFirstMatch) {
      firstMatchElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [firstMatchElement, scrollToFirstMatch])

  const applyHighlights = (currentMark: Mark, attempt: number = 1): void => {
    // Will retry up to maxAttempts times if no matches are found (see explanation below)
    const maxAttempts = 5

    console.debug(`> HighlighterProvider: applying highlights (attempt ${attempt} of ${maxAttempts})`)

    // First remove existing highlights
    currentMark.unmark()

    if (searchTexts.length === 0 || searchTexts[0] === '') {
      return
    }

    // Track the first match index to reject matches that come before it
    let firstMatchIndex: number | null = null

    // Check out https://angezid.github.io/advanced-mark.js/doc-v2/mark-method.html
    // for the full list of options
    const markOptions: MarkOptions = {
      acrossElements: true, // Match across multiple elements (e.g. across paragraphs)
      separateWordSearch: false, // Required to match the entire text and not individual words
      ignorePunctuation: ['|'], // Ignore pipe characters which may appear in quoted text because of markdown formatting
      synonyms: { '\'': ['’'] }, // Hack to match apostrophes, which has been normalized into single quotes by the backend
      // Filter function to control which matches are accepted.
      // It will first be called for each match of the first search text,
      // then for each match of the remaining search texts.
      // We use it to find the first match of the first search text,
      // and reject all other matches that come before it.
      filter: (textNode: Text, term: string, totalMatchesSoFar: number, termMatchesSoFar: number, filterInfo: MarkFilterInfo): boolean => {
        if (firstMatchIndex === null) {
          console.debug('HighlighterProvider: First match found for "', term, '" at index', filterInfo.match.index)
          firstMatchIndex = filterInfo.match.index
          setFirstMatchElement(textNode.parentElement)
          return true
        } else if (filterInfo.match.index < firstMatchIndex) {
          console.debug('HighlighterProvider: Rejecting match for "', term, '"at index', filterInfo.match.index, 'because it comes before the first match')
          return false
        } else {
          return true
        }
      },
      // Callback function to be called when all matches have been found,
      // to update the total number of matches found
      done: (totalMarks: number, totalMatches: number): void => {
        console.debug(
          `HighlighterProvider: Found ${totalMatches} matches ` +
          `looking for ${searchTexts.map(text => `"${text}"`).join(', ')}`
        )
        setTotalMatches(totalMatches)

        // If no matches were found and we haven't exceeded max attempts, retry after delay.
        // This is an ugly hack to deal with the fact that the mark instance is not
        // always available when the search texts are updated.
        // FIXME: find out when the mark instance is ready to be searched and remove this hack.
        if (totalMatches === 0 && attempt < maxAttempts) {
          console.debug('HighlighterProvider: No matches found, retrying soon')
          setTimeout(() => {
            applyHighlights(currentMark, attempt + 1)
          }, 1000 * attempt)
        }
      }
    }

    // Apply new highlighting
    currentMark.mark(searchTexts, markOptions)
  }

  const value = {
    searchTexts,
    setSearchTexts: (texts: string[]): void => {
      setSearchTexts(texts)
      setForceUpdateCounter(c => c + 1)
    },
    containerRef,
    totalMatches
  }

  return (
    <HighlighterContext.Provider value={value}>
      {children}
    </HighlighterContext.Provider>
  )
}

/**
 * Hook to access the highlighter context.
 * Use this in components that need to control or access the highlighting functionality.
 */
export const useHighlighter = (): HighlighterContextValue => {
  const context = useContext(HighlighterContext)

  if (context === null) {
    throw new Error('useHighlighter must be used within a HighlighterProvider')
  }

  return context
}
