import { Box, Button, Divider, Modal, Typography } from '@mui/material'
import type React from 'react'
import { type ReactNode } from 'react'
import { ModalContainer } from 'theme'

interface Props {
  open: boolean
  onClose: () => void
  title: string
  children: ReactNode
}

export const ErrorModal: React.FC<Props> = ({ open, onClose, title, children }): JSX.Element => {
  return (
    <Modal
      open={open}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
    >
      <ModalContainer>
        <Typography id="error-modal-title" variant="h4">
          {title}
        </Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Typography id="error-modal-body" variant="body1">
          {children}
        </Typography>
        <Box sx={{ p: 1, flexBasis: '50px', alignSelf: 'end' }}>
          <Button type="submit" variant="contained" onClick={onClose}>
            OK
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default ErrorModal
