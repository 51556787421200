import { UserMenu } from 'features/users/components/UserMenu'
import { CopilexLogo } from './CopilexLogo'
import { LeftPaneHeaderContainer } from './LeftPaneHeader.styles'
import { ReportModalProvider, ReportModal } from 'features/reports'

const LeftPaneHeader: React.FC = () => (
  <LeftPaneHeaderContainer>
    <CopilexLogo />
    <ReportModalProvider>
      <UserMenu />
      <ReportModal />
    </ReportModalProvider>
  </LeftPaneHeaderContainer>
)

export default LeftPaneHeader
