import { type RuntimeConfig } from 'types/global'

/**
 * Helper function to get the configuration value for the given key,
 * which works both in development and production modes.
 */
export const getConfig = (key: keyof RuntimeConfig): string => {
  // Check if there is a value in the environment variable
  // that was set at build time
  const envValue = process.env[`REACT_APP_${key}`]
  if (envValue !== undefined) {
    return envValue
  }
  // Otherwise, use the value from config.js,
  // which is set at runtime
  const runtimeValue = window.RUNTIME_CONFIG[key]
  if (runtimeValue === undefined || runtimeValue === null) {
    throw new Error(`Configuration key ${key} is not set!`)
  }
  if (runtimeValue === '') {
    throw new Error(`Configuration key ${key} is empty!`)
  }
  if (runtimeValue.startsWith('%%') && runtimeValue.endsWith('%%')) {
    throw new Error(`Configuration key ${key} still contains a placeholder!`)
  }
  return runtimeValue
}

export default getConfig
