import { type ChipProps, Box, Chip, Typography, styled } from '@mui/material'

interface StyledChipProps extends ChipProps {
  clickable?: boolean
}

export const StyledChip = styled(Chip)<StyledChipProps>(({ theme, clickable }) => ({
  // Allow shrinking below content size
  minWidth: 0,
  flex: '0 1 auto',
  // Set a transparent border by default, to reserve space for the hover effect
  border: '2px solid transparent',
  // Highlight the border of the chip when labeled hovered and chip is clickable (openable)
  '&:has(.MuiChip-label:hover)': {
    borderColor: clickable === true ? theme.palette.primary.main : 'transparent'
  },
  // Transition the border color when hovered
  transition: 'border-color 0.3s ease',
  // Make the label shrinkable, adding an ellipsis when it overflows
  '& .MuiChip-label': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

export const LabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 1,
  alignItems: 'center',
  maxWidth: '350px',
  position: 'relative' // To position the view icon
}))

export const FilenameTypography = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}))

export const DocTypeTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  flexShrink: 0
}))
