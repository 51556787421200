import { styled, Typography } from '@mui/material'

export const StyledUserInputTypography = styled(Typography)<{ showRaw: boolean }>(({ showRaw, theme }) => ({
  // When text is formatted, it will already have some padding at the top
  paddingTop: showRaw ? theme.spacing(1) : 0,
  paddingBottom: theme.spacing(1)
}))

export const StyledBox = styled('div')({
  whiteSpace: 'pre-line',
  '& table': {
    borderCollapse: 'collapse'
  },
  '& th': {
    border: '1px solid black',
    padding: '0.5em'
  },
  '& td': {
    border: '1px solid black',
    padding: '0.5em'
  }
})
