import { Sidepane, useSidepanes } from 'components/sidepanes'
import { ListSessions, NewSession } from 'features/sessions'
import type React from 'react'
import { LeftPaneContainer } from './LeftPane.styles'
import LeftPaneHeader from './LeftPaneHeader'

export const LeftPane: React.FC = () => {
  const { swipeHandlers } = useSidepanes()

  return (
    <Sidepane
      anchor={'left'}
      headerChildren={<LeftPaneHeader />}
    >
      <LeftPaneContainer className='left-pane' {...swipeHandlers}>
        <NewSession />
        <ListSessions />
      </LeftPaneContainer>
    </Sidepane>
  )
}
