import { Box } from '@mui/material'
import { EntityLabelTypography } from './Selectors.styles'

interface Props {
  label: string
  actionIcon: JSX.Element
  openDirection: 'left' | 'right'
}

/**
 * Displays the label and action icon (e.g., add as new entity, or add as alias)
 * inside a menu item, taking into account the direction in which the menu is opened
 * (left or right) to position the icon.
 */
const MenuItemLabelAndActionIcon = ({ label, actionIcon, openDirection }: Props): JSX.Element => (
    <Box
        className='menu-item-label-and-action-icon-container'
        sx={{ display: 'flex', flexDirection: 'row' }}
    >
        {openDirection === 'left' && actionIcon}
        <EntityLabelTypography>
            {label}
        </EntityLabelTypography>
        {
            // If the menu is opened to the right, the action icon should appear on the far right.
            // To achieve this, we put it in a container and override the default margin.
            openDirection === 'right' &&
            <Box sx={{ display: 'flex', marginLeft: 'auto', marginRight: 0, alignSelf: 'center' }}>
                {actionIcon}
            </Box>
        }
    </Box>
)

export default MenuItemLabelAndActionIcon
