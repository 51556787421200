import Loading from 'components/progress/Loading'
import useConfidentialitySettings from 'features/users/hooks/useConfidentialitySettings'
import { type FC } from 'react'
import ConfidentialityToggleSwitch from './ConfidentialityToggleSwitch'

/**
 * Container component for the confidentiality toggle switch
 */
export const ConfidentialityToggleSwitchContainer: FC = (): JSX.Element => {
  const { selectedLevel: level, levels, setLevelNum } = useConfidentialitySettings()

  const alwaysReviewLevel = levels.find((level) => level.alwaysReview)
  const neverReviewLevel = levels.find((level) => level.neverReview)

  if (level === null || alwaysReviewLevel === undefined || neverReviewLevel === undefined) {
    return <Loading />
  }

  const handleChange = (checked: boolean): void => {
    if (checked) {
      setLevelNum(alwaysReviewLevel.level)
    } else {
      setLevelNum(neverReviewLevel.level)
    }
  }

  return (
    <ConfidentialityToggleSwitch
      active={level?.alwaysReview}
      onChange={handleChange}
    />
  )
}

export default ConfidentialityToggleSwitchContainer
