import { type Globals } from 'features/globals'
import { type Session } from 'features/sessions'
import { type Assistant, type AssistantSettings } from './types'

export * from './types'

export const getEnabledAssistants = (globals: Globals): Assistant[] => {
  return Array.from(globals.assistantsById).filter(([assistantId, assistant]) =>
    globals.enabledAssistantIds.includes(assistantId)
  ).map(([_, assistant]) => assistant)
}

export const getSelectedAssistant = (session: Session, globals: Globals): Assistant => {
  const assistantId = getAssistantId(session, globals)
  return getAssistant(assistantId, globals)
}

export const getAssistant = (assistantId: string, globals: Globals): Assistant => {
  const assistant = globals.assistantsById.get(assistantId)
  if (assistant !== undefined) {
    return assistant
  }
  // If the assistant is not defined, we use the default assistant
  // to not break everything!
  console.error('Assistant not found for id: ' + assistantId + ', using default assistant instead')
  const defaultAssistant = globals.assistantsById.get(globals.defaultAssistantId)
  if (defaultAssistant === undefined) {
    throw new Error('Default assistant not found based on its id: ' + globals.defaultAssistantId)
  }
  return defaultAssistant
}

export const getAssistantId = (session: Session, globals: Globals): string => (
  session.settings?.assistantId ?? globals.defaultAssistantId
)

/**
 * Ugly hack to convert the assistantId to the settings object.
 * Fix this by refactoring how we handle assistant settings!
 */
export const assistantIdToSettings = (assistantId: string): AssistantSettings => {
  if (assistantId.startsWith('copilex')) {
    return {
      agentType: 'copilex',
      modelName: 'dummy'
    }
  }
  return {
    agentType: 'conversation',
    modelName: assistantId
  }
}
