import { Tooltip } from '@mui/material'
import { openHelpPage } from 'features/help'
import { useContext } from 'react'
import { useIntl } from 'react-intl'
import ColorModeContext from 'context/ColorModeContext'

export const CopilexLogo = (): JSX.Element => {
  const intl = useIntl()
  const { colorMode } = useContext(ColorModeContext)

  const handleClick = (): void => {
    openHelpPage(intl.locale)
  }

  const alt = intl.formatMessage({
    id: 'app.copilex-logo-alt',
    defaultMessage: 'Copilex logo'
  })

  const tooltip = intl.formatMessage({
    id: 'app.copilex-logo-tooltip',
    defaultMessage: 'Click to open help page'
  })

  return (
    <Tooltip title={tooltip}>
      <img
        src={`/logo-full-${colorMode}.svg`}
        alt={alt}
        style={{
          width: 'auto',
          height: '28px',
          paddingTop: '2px',
          paddingLeft: '6px',
          paddingRight: '6px',
          // Show that the logo is clickable
          cursor: 'pointer'
        }}
        onClick={handleClick}
      />
    </Tooltip>
  )
}

export default CopilexLogo
