import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useSidepanes } from 'components/sidepanes'
import { NewSessionIcon } from 'features/sessions'
import { useCreateSession } from 'features/sessions/api/createSession'
import { useSession } from 'features/sessions/providers/SessionContext'
import { useIntl } from 'react-intl'
import { StyledContainer } from './TitleBar.styles'

export const TitleBar = (): JSX.Element => {
  const intl = useIntl()
  const { centralPane } = useSidepanes()
  const { selectedSession } = useSession()
  const { mutate, isPending } = useCreateSession()

  const handleNewSession = (): void => {
    mutate({})
  }

  const newSessionTooltip = intl.formatMessage({
    id: 'app.new-session.tooltip.create-session',
    defaultMessage: 'Click to create a new session'
  })

  return (
    <StyledContainer containerWidth={centralPane.width}>
      <Typography variant="body2" fontWeight="bold" component="div" sx={{ flexGrow: 1 }}>
        {selectedSession?.title ??
          intl.formatMessage({
            id: 'app.title-bar.no-session',
            defaultMessage: 'No session selected'
          })
        }
      </Typography>
      <Box>
        <Tooltip title={newSessionTooltip}>
          <IconButton
            onClick={handleNewSession}
            disabled={isPending}
            color="primary"
            aria-label={intl.formatMessage({
              id: 'app.new-session.button.new-session',
              defaultMessage: 'New session'
            })}
          >
            <NewSessionIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </StyledContainer>
  )
}

export default TitleBar
