import { TextField, useTheme } from '@mui/material'
import { type AttachmentsAndUploads } from 'features/documents'
import { AnalysisTaskAction, type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form/types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { inputProps } from './ComposeTextInput.styles'

// How many rows should the input field have by default.
// If user prompt buttons are shown, the input field should be smaller
// to make room for the buttons.
const MIN_ROWS = 3
const MAX_ROWS = 8 // Roughly 1/3 of the screen height

interface Props {
  userInputForm: Partial<UserInputForm>
  attachments: AttachmentsAndUploads
  input: string
  setInput: (input: string) => void
  processing: boolean
  onSubmit: () => void
}

export const ComposeTextInput = ({ userInputForm, attachments, input, setInput, processing, onSubmit }: Props): JSX.Element => {
  const theme = useTheme()
  const intl = useIntl()
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setDisabled(isDisabledForSelectedTask(userInputForm))
  }, [userInputForm])

  const isDisabledForSelectedTask = (userInputForm: Partial<UserInputForm>): boolean => {
    // Disable input field if the user has selected the Analyze main task
    // with the Comparative Analysis action
    if (userInputForm.mainTask === 'Analyze') {
      const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined
      return taskParams?.action === AnalysisTaskAction.ComparativeAnalysis
    }
    return false
  }

  /**
   * Generate placeholder text for the input field
   * based on the current state of the user input form.
   */
  const getPlaceholder = (userInputForm: Partial<UserInputForm>): string | undefined => {
    switch (userInputForm.mainTask) {
      case undefined:
      case null:
        return intl.formatMessage({
          id: 'app.text-input.placeholder.select-task',
          defaultMessage: 'Select a task above, or ask anything'
        })
      case 'Chat':
        return intl.formatMessage({
          id: 'app.text-input.placeholder.type-chat',
          defaultMessage: 'Type your request here'
        })
      case 'Analyze':
        return getAnalyzePlaceholder(userInputForm)
      case 'Answer':
        return intl.formatMessage({
          id: 'app.text-input.placeholder.type-question',
          defaultMessage: 'Type your question here'
        })
      case 'Search':
        return intl.formatMessage({
          id: 'app.text-input.placeholder.type-search',
          defaultMessage: 'Type what you want to search for here'
        })
      case 'Draft':
        return intl.formatMessage({
          id: 'app.text-input.placeholder.type-draft',
          defaultMessage: 'Describe what you want to draft here'
        })
      case 'Translate':
        return intl.formatMessage({
          id: 'app.text-input.placeholder.type-translate',
          defaultMessage: 'Type what you want to translate here, and to which language'
        })
      case 'Summarize':
        if (attachments.uploaded.length > 0 || attachments.pending.length > 0) {
          return intl.formatMessage({
            id: 'app.text-input.placeholder.additional-instructions',
            defaultMessage: 'Add any additional instructions here, or leave blank'
          })
        } else {
          return intl.formatMessage({
            id: 'app.text-input.placeholder.type-summarize',
            defaultMessage: 'Type what you want to summarize here, or upload a document'
          })
        }
      default:
        return undefined
    }
  }

  /**
   * Generate the placeholder text for the input field
   * based on the current state of the user input form
   * for the Analyze task.
   */
  const getAnalyzePlaceholder = (userInputForm: Partial<UserInputForm>): string | undefined => {
    const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined
    if (taskParams?.action === undefined) {
      return intl.formatMessage({
        id: 'app.text-input.placeholder.select-action',
        defaultMessage: 'Select an action above, or describe what you want to analyze here'
      })
    }
    if (taskParams.comparativeAnalysisOptions !== undefined) {
      // No placeholder needed for comparative analysis, and the text field should be disabled
      return undefined
    }
    if (taskParams.sanityCheckOptions === undefined && taskParams.specificAnalysisOptions === undefined) {
      return intl.formatMessage({
        id: 'app.text-input.placeholder.select-options',
        defaultMessage: 'Select options above, or describe what you want to analyze here'
      })
    }
    return intl.formatMessage({
      id: 'app.text-input.placeholder.additional-instructions',
      defaultMessage: 'Add any additional instructions here, or leave blank'
    })
  }

  return (
    <TextField
      id="text-input"
      // This is to remove the border, combined with the sx property disableUnderline
      variant="standard"
      fullWidth
      multiline
      minRows={disabled ? 0 : MIN_ROWS}
      maxRows={disabled ? 0 : MAX_ROWS}
      value={input}
      disabled={processing || disabled}
      onChange={(event) => { setInput(event.target.value) }}
      onKeyUp={(event) => {
        if (event.ctrlKey && event.key === 'Enter') { onSubmit() }
      }}
      placeholder={disabled ? undefined : getPlaceholder(userInputForm)}
      InputProps={inputProps(disabled, theme)}
    />
  )
}

export default ComposeTextInput
