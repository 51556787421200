import { type FC, useEffect, useState } from 'react'
import { StyledTextField } from './PageInput.styles'

interface Props {
  onPageInputChange: (pageNumber: number) => void
  currentPage: number
  numPages: number | undefined
}

/**
 * Displays the current page number and allows the user to change it.
 */
export const PageInput: FC<Props> = ({ onPageInputChange, currentPage, numPages }: Props): JSX.Element => {
  const [pageInputValue, setPageInputValue] = useState<string>(currentPage.toString())

  // Update input value when currentPage changes
  useEffect(() => {
    setPageInputValue(currentPage.toString())
  }, [currentPage])

  if (numPages === undefined) {
    return <></>
  }

  const isValidPageNumber = (pageNumber: number): boolean => {
    return !isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= numPages
  }

  const handlePageInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setPageInputValue(value)

    const pageNumber = parseInt(value)
    if (isValidPageNumber(pageNumber)) {
      onPageInputChange(pageNumber)
    }
  }

  const handlePageInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      const pageNumber = parseInt(pageInputValue)
      if (isValidPageNumber(pageNumber)) {
        onPageInputChange(pageNumber)
      } else {
        setPageInputValue(currentPage.toString())
      }
    }
  }

  const handlePageInputBlur = (): void => {
    const pageNumber = parseInt(pageInputValue)
    if (!isValidPageNumber(pageNumber)) {
      setPageInputValue(currentPage.toString())
    }
  }

  return (
    <StyledTextField
      value={pageInputValue}
      onChange={handlePageInputChange}
      onKeyDown={handlePageInputKeyDown}
      onBlur={handlePageInputBlur}
      size="small"
    />
  )
}

export default PageInput
