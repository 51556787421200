/**
 * Component that displays a component and an invisible overlay that catches all events.
 */
export const DisabledOverlay = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <div style={{ position: 'relative' }}>
      {/* Component to display */}
      <div>{children}</div>

      {/* Invisible overlay that catches all events */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: 'transparent'
        }}
        onClick={(e) => { e.stopPropagation() }}
        onKeyDown={(e) => { e.stopPropagation() }}
        onMouseDown={(e) => { e.stopPropagation() }}
        onMouseUp={(e) => { e.stopPropagation() }}
        onMouseEnter={(e) => { e.stopPropagation() }}
        onMouseLeave={(e) => { e.stopPropagation() }}
        onTouchStart={(e) => { e.stopPropagation() }}
        onTouchEnd={(e) => { e.stopPropagation() }}
        onFocus={(e) => { e.stopPropagation() }}
        onBlur={(e) => { e.stopPropagation() }}
        tabIndex={-1}
      />
    </div>
  )
}

export default DisabledOverlay
