import { axiosInstance, createAuthHeaderFromToken } from 'services/axios'

import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { useSession } from 'features/sessions'
import { type UUID } from 'crypto'

interface Props {
  sessionId: string
  attachmentId: UUID
}

export const useAttachmentAsPDF = ({ sessionId, attachmentId }: Props): UseQueryResult<File> => {
  const { getAccessTokenSilently } = useAuth0()
  const { selectedSession } = useSession()

  const queryFn = async (): Promise<File> => {
    if (selectedSession === null) {
      throw new Error('No session selected')
    }

    return await getAccessTokenSilently().then(async (token) =>
      await getAttachmentAsPDF(token, sessionId, attachmentId)
    )
  }

  return useQuery({
    queryKey: ['attachment-pdf', sessionId, attachmentId],
    queryFn,
    staleTime: Infinity // PDF content won't change, so we can cache it indefinitely
  })
}

const getAttachmentAsPDF = async (
  token: string,
  sessionId: string,
  attachmentId: UUID
): Promise<File> => {
  const authHeader = createAuthHeaderFromToken(token)
  const url = `/sessions/${sessionId}/attachments/${attachmentId}/as-pdf`
  const response = await axiosInstance.get<Blob>(
    url,
    {
      headers: authHeader,
      cache: false,
      responseType: 'blob'
    }
  )
  return new File([response.data], `${attachmentId}.pdf`, { type: 'application/pdf' })
}

export default useAttachmentAsPDF
