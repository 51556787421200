import { Switch } from '@mui/material'
import { styled } from '@mui/material/styles'
import { switchStyle } from 'theme'

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  ...switchStyle(theme.palette.mode, theme.palette.success.main, theme.palette.success.light),
  '& .MuiSwitch-thumb': {
    // Shape of a shield with a tick in the middle
    width: 32,
    height: 32,
    clipPath: 'path("M 12 2 L 4 5 v 6.09 c 0 5.05 3.41 9.76 8 10.91 c 4.59 -1.15 8 -5.86 8 -10.91 V 5 z m 5 6 l -6 8 L 7 11 L 11 13 z")',
    borderRadius: 0,
    boxShadow: 'none',
    transform: 'translate(-4px, -4px)',
    backgroundColor: 'white',
    // On top of it, outline the shield in black
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'black',
      clipPath: 'path("M12 2 4 5v6.09c0 5.05 3.41 9.76 8 10.91 4.59-1.15 8-5.86 8-10.91V5zm6 9.09c0 4-2.55 7.7-6 8.83-3.45-1.13-6-4.82-6-8.83v-4.7l6-2.25 6 2.25z")'
    }
  }
}))
