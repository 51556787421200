import { type UUID } from 'crypto'

/**
 * Type for the document type, e.g., "Contract", "Term sheet", ...
 * The possible values are defined and provided by the back-end
 */
export type DocType = string

/**
 * Item in the list of attachments or uploads, with the common fields
 * that will be displayed in the upload modal.
 */
export interface AttachmentOrUploadListItem {
  /**
   * The ID of the attachment or upload task.
   * May not be defined yet for optimistic updates.
   */
  id: UUID | undefined
  filename: string
  truncated: boolean
  text: string | null
  docType: string
  /**
   * The URI of the task, for canceling the upload if needed.
   * May not be defined yet for optimistic updates.
   */
  taskUri: string | null | undefined
  createdAt: string
  /**
   * The status of the upload task.
   */
  status: string | undefined
}

export interface AttachmentListItem {
  id: UUID
  filename: string
  truncated: boolean
  text: string | null
  docType: DocType
  createdAt: string
  deleted: boolean
}

// Constant for doc type when it is not certain.
// It should match the ones defined in the backend!
export const DOC_TYPE_NOT_CERTAIN: DocType = 'Document type not certain'

export interface UploadListItem {
  taskId: UUID | undefined
  taskUri: string | undefined
  filename: string
  createdAt: string
  status: UploadStatus
}

export type UploadStatus = 'PENDING' | 'STARTED' | 'RETRY' | 'FAILURE' | 'SUCCESS'

export interface AttachmentsAndUploads {
  uploaded: AttachmentListItem[]
  pending: UploadListItem[]
  deleted: AttachmentListItem[]
}

export interface Limits {
  /**
   * Number of files that can still be uploaded
   * before reaching the quota for the session.
   */
  uploadQuotaRemaining: number
  maxNbDocs: number
  maxNbPages: number
  maxNbChars: number
  maxDocSizeInMb: number
}
