import { Avatar } from '@mui/material'
import anthropicLogo from 'assets/anthropic-logo.png'
import mistralLogo from 'assets/mistral-logo.jpeg'
import openaiLogo from 'assets/openai-logo.png'
import { CopilexIcon } from 'components/icons'
import { useColorMode } from 'context/ColorModeContext'
import { type AssistantSettings } from 'features/assistants/types'
import { useIntl } from 'react-intl'

/**
 * Smaller avatar size for the assistant avatar displayed in chat (in pixels)
 */
export const AVATAR_SIZE_CHAT = 20

interface Props {
  settings?: AssistantSettings
  size?: number
}

const AIAvatar: React.FC<Props> = ({ settings, size = AVATAR_SIZE_CHAT }: Props): JSX.Element => {
  const intl = useIntl()

  // FIXME: should refactor the msg settings to contain an assistant id,
  // and not expose the agentType and modelName
  let src
  if (settings !== undefined) {
    if (settings.agentType.startsWith('copilex') || settings.agentType === 'master agent') {
      return (
        <CopilexIcon size={size} />
      )
    } else {
      if (settings.modelName.toLowerCase().startsWith('gpt-')) {
        src = openaiLogo
      } else if (settings.modelName.toLowerCase().startsWith('mistral')) {
        src = mistralLogo
      } else if (settings.modelName.toLowerCase().startsWith('claude')) {
        src = anthropicLogo
      }
    }
  }

  return <Avatar src={src} sx={{ width: size, height: size }}>
    {
      intl.formatMessage({
        id: 'app.ai-avatar.label',
        defaultMessage: 'AI'
      })
    }
  </Avatar>
}

export default AIAvatar
