import { type AttachmentListItem } from 'features/documents'
import { useDeleteAttachment } from 'features/documents/api/deleteAttachment'
import useDocumentPanel from 'features/documents/hooks/useDocumentPanel'
import AttachmentChip from './AttachmentChip'

export interface Props {
  attachment: AttachmentListItem
  /**
   * If true, the attachment will be deletable via a button in the chip.
   */
  deletable?: boolean
  /**
   * If true, the document type will be displayed inside the label.
   * Otherwise, the document type will be displayed only in the tooltip.
   */
  docTypeInLabel?: boolean
  /**
   * If true, will open the attachment in the document panel when clicked.
   */
  openable?: boolean
}

/**
 * Container component for the attachment chip.
 */
export const AttachmentChipContainer = ({ attachment, deletable = true, docTypeInLabel = false, openable = true }: Props): JSX.Element => {
  const deleteAttachmentMutation = useDeleteAttachment({ attachmentId: attachment.id })
  const { openPrimaryDocument } = useDocumentPanel()

  const handleOpen = (): void => {
    openPrimaryDocument(attachment.id)
  }

  const handleDelete = (): void => {
    deleteAttachmentMutation.mutate()
  }

  return (
    <AttachmentChip
      attachment={attachment}
      deletable={deletable}
      docTypeInLabel={docTypeInLabel}
      openable={openable}
      onDelete={handleDelete}
      onOpen={handleOpen}
    />
  )
}

export default AttachmentChipContainer
