import { Cancel, Delete, Replay } from '@mui/icons-material'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, IconButton, TableRow, Tooltip, Typography } from '@mui/material'
import { useCancelUpload } from 'features/documents/api/cancelUpload'
import { useDeleteAttachment } from 'features/documents/api/deleteAttachment'
import { useRetryUploadAttachment } from 'features/documents/api/retryUploadAttachment'
import FilenameWithIcon from 'features/documents/components/FilenameWithIcon'
import { type AttachmentOrUploadListItem, type Limits } from 'features/documents/types'
import { useIntl } from 'react-intl'
import { StyledTableCell, buttonCellSx, filenameCellSx, selectorCellSx } from './AttachmentItem.styles'
import Processing from './Processing'

interface Props {
  attachmentOrUpload: AttachmentOrUploadListItem
  limits: Limits
}

const AttachmentItem: React.FC<Props> = ({ attachmentOrUpload, limits }: Props) => {
  const intl = useIntl()
  const { id, filename, truncated, /* docType, */ taskUri, status } = attachmentOrUpload
  const retryUploadAttachmentMutation = useRetryUploadAttachment()

  const isUploaded = taskUri === null
  const cancelUploadMutation = useCancelUpload({ taskUri })
  const deleteAttachmentMutation = useDeleteAttachment({ attachmentId: id })
  // const updateAttachmentMutation = useUpdateAttachment({ attachmentId: id })

  // const handleChangeDocType = (newDocType: string): void => {
  //   if (!isUploaded) {
  //     throw new Error('Cannot change docType while uploading.')
  //   }
  //   console.debug('Changing docType to', newDocType)
  //   updateAttachmentMutation.mutate({ update: { docType: newDocType } })
  // }

  const handleDelete = (): void => {
    if (!isUploaded) {
      throw new Error('Cannot delete an attachment that is currently uploading.')
    }
    console.debug('Deleting attachment', filename)
    deleteAttachmentMutation.mutate()
  }

  const handleCancel = (): void => {
    if (taskUri === null) {
      throw new Error('taskUri is null, cannot cancel upload.')
    }
    console.debug('Canceling upload for', filename)
    cancelUploadMutation.mutate({})
  }

  const handleRetry = (): void => {
    if (id === undefined) {
      throw new Error('task id is undefined, cannot retry upload.')
    }
    console.debug('Retrying upload for', filename)
    retryUploadAttachmentMutation.mutate({ taskId: id })
  }

  const truncationWarning = intl.formatMessage({
    id: 'app.attachment-item.truncation-warning',
    defaultMessage: (
      'File content exceeded one of the limitations ' +
      '(max {maxNbPages} pages or {maxNbChars} characters), ' +
      'and had to be truncated.'
    )
  }, {
    maxNbPages: limits.maxNbPages,
    maxNbChars: limits.maxNbChars
  })

  const retryButtonTooltip = intl.formatMessage({
    id: 'app.attachment-item.retry-button.tooltip',
    defaultMessage: 'Click here to retry the upload'
  })

  let selectorCell: JSX.Element
  if (isUploaded) {
    selectorCell = <></> // <DocTypeSelector docType={docType} onChange={handleChangeDocType} />
  } else if (status === 'SUCCESS') {
    selectorCell = <Box sx={{ color: 'text.secondary' }}>Upload successful</Box>
  } else if (status === 'FAILURE') {
    selectorCell = <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
      <Typography sx={{ color: 'error.main' }}>
        Upload failed
      </Typography>
      <Tooltip title={retryButtonTooltip}>
        <IconButton onClick={handleRetry}>
          <Replay />
        </IconButton>
      </Tooltip>
      </Box>
  } else {
    selectorCell = <Processing filename={filename} />
  }

  return (
    <TableRow>
      <StyledTableCell sx={filenameCellSx}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '300px',
          maxWidth: '300px'
        }}>
          <Box sx={{
            flexGrow: 1,
            maxWidth: '300px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
            <FilenameWithIcon filename={filename}/>
          </Box>
          {/* If truncated, then show a warning sign with a tooltip */}
          {truncated && (
            <Box sx={{ flexShrink: 0, flexBasis: '20px', ml: '5px' }}>
              <Tooltip title={truncationWarning}>
                <WarningIcon />
              </Tooltip>
            </Box>
          )}
        </Box>
      </StyledTableCell>
      <StyledTableCell sx={selectorCellSx}>
        {selectorCell}
      </StyledTableCell>
      <StyledTableCell sx={buttonCellSx}>
        {
          isUploaded
            ? <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
            : (
                taskUri !== undefined
                  ? <IconButton onClick={handleCancel}>
                      <Cancel />
                    </IconButton>
                  : <></>
              )
        }
      </StyledTableCell>
    </TableRow>
  )
}

export default AttachmentItem
