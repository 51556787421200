import useContainerDimensions from 'hooks/useContainerDimensions'
import { createContext, type FC, useContext, useEffect, useRef, useState } from 'react'
import { type SwipeableHandlers, useSwipeable } from 'react-swipeable'
import { CENTRAL_PANE_MAX_WIDTH_IN_PX, CENTRAL_PANE_MIN_WIDTH_IN_PX, DEFAULT_SIDE_PANE_WIDTH_IN_PX, getSidepaneWidthInPx, type SidepaneMode, type SidepaneState, type SidepaneWidth } from './types'

/**
 * Compute the width of the central pane such that it will fit in the available space,
 * that is what is left after subtracting the width of the left and right panes from the window width.
 *
 * @param leftOffset - The width of the left pane (if open) or 0 (if closed).
 * @param rightOffset - The width of the right pane (if open) or 0 (if closed).
 * @returns The width of the central pane.
 */
const computeCentralPaneWidth = (leftOffset: number, rightOffset: number): number => {
  return Math.max(
    CENTRAL_PANE_MIN_WIDTH_IN_PX,
    Math.min(
      CENTRAL_PANE_MAX_WIDTH_IN_PX,
      window.innerWidth - leftOffset - rightOffset
    )
  )
}

export interface SidepanesContextValue {
  leftPane: SidepaneState
  rightPane: SidepaneState
  centralPane: { width: number, height: number }
  centralPaneRef: React.RefObject<HTMLDivElement>
  openRightPane: (mode?: SidepaneMode) => void
  openLeftPane: (mode?: SidepaneMode) => void
  closeRightPane: () => void
  closeLeftPane: () => void
  setLeftPaneWidths: (standardWidth?: SidepaneWidth, maximizedWidth?: SidepaneWidth) => void
  setRightPaneWidths: (standardWidth?: SidepaneWidth, maximizedWidth?: SidepaneWidth) => void
  swipeHandlers: SwipeableHandlers
}

export const SidepanesContext = createContext<SidepanesContextValue | undefined>(undefined)

export const defaultLeftPaneState: SidepaneState = {
  isOpen: true,
  isTemporary: false,
  mode: 'standard',
  standardWidth: DEFAULT_SIDE_PANE_WIDTH_IN_PX,
  maximizedWidth: DEFAULT_SIDE_PANE_WIDTH_IN_PX * 2
}

export const defaultRightPaneState: SidepaneState = {
  isOpen: false,
  isTemporary: false,
  mode: 'standard',
  standardWidth: DEFAULT_SIDE_PANE_WIDTH_IN_PX,
  maximizedWidth: DEFAULT_SIDE_PANE_WIDTH_IN_PX * 2
}

interface Props {
  children: React.ReactNode
}

/**
 * Provides a context for managing both left and right side panes and the central pane.
 */
export const SidepanesProvider: FC<Props> = ({ children }): JSX.Element => {
  const [leftPane, setLeftPane] = useState<SidepaneState>(defaultLeftPaneState)
  const [rightPane, setRightPane] = useState<SidepaneState>(defaultRightPaneState)
  const centralPaneRef = useRef<HTMLDivElement>(null)
  const centralPane = useContainerDimensions(centralPaneRef)

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => { setLeftPane(prev => ({ ...prev, isOpen: true })) },
    onSwipedRight: () => { setRightPane(prev => ({ ...prev, isOpen: true })) }
  })

  /**
   * Updates the state of the sidepanes and central pane.
   */
  const updateState = (paneChanged: 'left' | 'right' | null): void => {
    // Compute the offset from each side pane, which only applies if the pane is opened and not temporary
    const leftOffset = leftPane.isOpen && !leftPane.isTemporary ? getSidepaneWidthInPx(leftPane) : 0
    const rightOffset = rightPane.isOpen && !rightPane.isTemporary ? getSidepaneWidthInPx(rightPane) : 0
    // Compute the width of the central panel such that it will fit in the available space.
    const newCentralPaneWidth = computeCentralPaneWidth(leftOffset, rightOffset)
    // If there enough space to view everything?
    const enoughSpace = (leftOffset + rightOffset + newCentralPaneWidth) <= window.innerWidth

    if (!enoughSpace) {
      if (paneChanged === 'left' && leftPane.isOpen) {
        setRightPane(prev => ({ ...prev, isOpen: false }))
      } else if (paneChanged === 'right' && rightPane.isOpen) {
        setLeftPane(prev => ({ ...prev, isOpen: false }))
      } else {
        // If there is not enough space for both panes, close both
        setLeftPane(prev => ({ ...prev, isOpen: false }))
        setRightPane(prev => ({ ...prev, isOpen: false }))
      }
    }
  }

  // Using different listeners for each pane change and window resize,
  // in order to pass the event argument to updateState and adapt the changes accordingly.
  // (For example, when the left pane was just opened and there is not enough room for both side panes,
  // the right side pane should be closed)
  useEffect(() => {
    // Set the initial state and add a listener for future changes in window size.
    updateState(null)
    window.addEventListener('resize', () => { updateState(null) })
    return () => {
      window.removeEventListener('resize', () => { updateState(null) })
    }
  }, [])

  // Trigger a complete update when the panes change
  // Note: adding centralPane to the dependency array will cause a loop!
  useEffect(() => {
    updateState('left')
  }, [leftPane])

  useEffect(() => {
    updateState('right')
  }, [rightPane])

  const openRightPane = (mode?: SidepaneMode): void => {
    setRightPane(prev => ({ ...prev, isOpen: true, mode: mode ?? prev.mode }))
  }

  const openLeftPane = (mode?: SidepaneMode): void => {
    setLeftPane(prev => ({ ...prev, isOpen: true, mode: mode ?? prev.mode }))
  }

  const closeRightPane = (): void => {
    setRightPane(prev => ({ ...prev, isOpen: false }))
  }

  const closeLeftPane = (): void => {
    setLeftPane(prev => ({ ...prev, isOpen: false }))
  }

  const setLeftPaneWidths = (standardWidth?: SidepaneWidth, maximizedWidth?: SidepaneWidth): void => {
    setLeftPane(prev => ({
      ...prev,
      ...(standardWidth !== undefined ? { standardWidth } : {}),
      ...(maximizedWidth !== undefined ? { maximizedWidth } : {})
    }))
  }

  const setRightPaneWidths = (standardWidth?: SidepaneWidth, maximizedWidth?: SidepaneWidth): void => {
    setRightPane(prev => ({
      ...prev,
      ...(standardWidth !== undefined ? { standardWidth } : {}),
      ...(maximizedWidth !== undefined ? { maximizedWidth } : {})
    }))
  }

  return (
    <SidepanesContext.Provider
      value={{
        leftPane,
        rightPane,
        centralPane,
        centralPaneRef,
        openRightPane,
        openLeftPane,
        closeRightPane,
        closeLeftPane,
        setLeftPaneWidths,
        setRightPaneWidths,
        swipeHandlers
      }}
    >
      {children}
    </SidepanesContext.Provider>
  )
}

export const useSidepanes = (): SidepanesContextValue => {
  const context = useContext(SidepanesContext)
  if (context === undefined) {
    throw new Error('useSidepanes must be used within a SidepanesProvider')
  }
  return context
}

export default useSidepanes
