import { Box, styled } from '@mui/material'

export const ComposeContainer = styled(Box)(({ theme }) => ({
  // The compose container take up all the vertical space it needs
  flexShrink: 0,
  // Add some margin and padding for aesthetic reasons
  margin: theme.spacing(1.5),
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  // Display a border that looks like a text field one
  // (in place of the border of the TextField component inside)
  border: `1.5px solid ${theme.palette.grey[500]}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  // When the text field is focused, the border of the container should be highlighted
  '&:has(#text-input:focus)': {
    borderColor: theme.palette.primary.main
  }
}))

export const TopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(1)
}))

export const BottomContainer = styled(Box)(({ theme }) => ({
  minWidth: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft: 0,
  marginRight: 0,
  gap: theme.spacing(1)
}))

export const AttachmentsAndInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.up('xs')]: {
    gap: theme.spacing(0.5)
  },
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(1)
  }
}))

export const AttachmentsContainer = styled(Box)(({ theme }) => ({
  // Allow the container to shrink below its content width if needed
  minWidth: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
  alignItems: 'center',
  flex: '1 2 auto',
  overflow: 'hidden'
}))

export const SendButtonContainer = styled(Box)(({ theme }) => ({
  // Keep this container from shrinking to preserve button visibility
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center'
}))
