import { Box, Typography } from '@mui/material'
import { TaskSelect } from 'components/central-pane/task-select'
import AssistantSelect from 'features/assistants/components/AssistantSelect'
import ComparativeAnalysisDocumentsSelect from 'features/comparative-analysis/components/ComparativeAnalysisDocumentsSelect'
import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { AnalysisTaskAction, type AnalysisTaskParams, type TranslateTaskParams, type UserInputForm } from 'features/user-input-form/types'
import { useIntl } from 'react-intl'
import SanityCheckDocumentSelect from './SanityCheckDocumentSelect'
import SanityCheckOptionsSelect from './SanityCheckOptionsSelect'
import SpecificAnalysisOptionsSelect from './SpecificAnalysisOptionsSelect'
import TranslateLanguageSelect from './TranslateLanguageSelect'
import { outerContainerSx, InnerContainer } from './UserPromptButtons.styles'
import TemplateDocumentSelect from 'features/draft/components/TemplateDocumentSelect'

/**
 * Once the user has picked a main task, user prompt buttons are shown
 * to allow the user to further specify options for the task.
 */
const UserPromptButtons: React.FC = () => {
  const intl = useIntl()
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const handleChange = (newUserInputForm: Partial<UserInputForm>): void => {
    console.debug('>>> Compose: UserInputForm changed: ', newUserInputForm)
    setUserInputForm(newUserInputForm)
  }

  // Component that comes at the beginning of the user prompt buttons
  // to allow the user to select the main task.
  // Each task-specific component must put it in the first position
  // of its components list.
  const taskSelect = (
    <TaskSelect
      key={0}
      userInputForm={userInputForm}
      onChange={handleChange}
    />
  )

  /**
   * Helper function to get the components specific for the Translate task.
   */
  const getTranslateComponents = (): JSX.Element[] => {
    // Check that the task params are set.
    // They should be set at the same time as the main task is set.
    const taskParams = userInputForm.taskParams as TranslateTaskParams | undefined | null
    if (taskParams === undefined ||
      taskParams === null ||
      taskParams.sourceLang === undefined ||
      taskParams.sourceLang === null ||
      taskParams.targetLang === undefined ||
      taskParams.targetLang === null) {
      throw new Error(
        `Cannot render components because the Translate task has been selected,
        but its specific task params are not set.`
      )
    }

    return [
      <InnerContainer key={0}>
        {
          intl.formatMessage({
            id: 'app.user-prompt-buttons.translate'
          }, {
            taskSelect,
            fromLanguageSelect: <TranslateLanguageSelect isTarget={false} />,
            toLanguageSelect: <TranslateLanguageSelect isTarget={true} />
          })
        }
      </InnerContainer>
    ]
  }

  /**
   * Helper function to get the components specific for the Chat task.
   */
  const getChatComponents = (): JSX.Element[] => {
    return [
      <InnerContainer key={0}>
        {intl.formatMessage({
          id: 'app.user-prompt-buttons.chat'
        }, {
          taskSelect,
          assistantSelect: <AssistantSelect />
        })}
      </InnerContainer>
    ]
  }

  /**
   * Helper function to get the components specific for the Analyze task.
   */
  const getAnalyzeComponents = (): JSX.Element[] => {
    const taskParams = userInputForm.taskParams as AnalysisTaskParams

    if (taskParams?.action === AnalysisTaskAction.SanityCheck) {
      return [
        <InnerContainer key={0}>
          {
            intl.formatMessage({
              id: 'app.user-prompt-buttons.sanity-check'
            }, {
              taskSelect: <TaskSelect
                userInputForm={userInputForm}
                onChange={handleChange}
              />,
              documentSelect: <SanityCheckDocumentSelect />,
              optionsSelect: <SanityCheckOptionsSelect
                userInputForm={userInputForm}
                onChange={handleChange} />
            })
          }
        </InnerContainer>
      ]
    } else if (taskParams?.action === AnalysisTaskAction.ComparativeAnalysis) {
      const isComparativeAnalysisTypeSelected = (
        ((userInputForm.taskParams as AnalysisTaskParams)?.comparativeAnalysisOptions?.comparisonTypeId ?? null) !== null
      )
      if (isComparativeAnalysisTypeSelected) {
        return [
          <InnerContainer key={0}>
            {
              intl.formatMessage({
                id: 'app.user-prompt-buttons.comparative-analysis'
              }, {
                taskSelect,
                documentsSelect: <ComparativeAnalysisDocumentsSelect />
              })
            }
          </InnerContainer>
        ]
      }
    } else if (taskParams?.action === AnalysisTaskAction.SpecificAnalysis) {
      return [
        <InnerContainer key={0}>
          {taskSelect}
          <SpecificAnalysisOptionsSelect
            key={2}
            userInputForm={userInputForm}
            onChange={handleChange} />
        </InnerContainer>
      ]
    }

    return [
      <InnerContainer key={0}>
        {taskSelect}
      </InnerContainer>
    ]
  }

  /**
   * Helper function to get the components specific for the Draft task.
   */
  const getDraftComponents = (): JSX.Element[] => {
    return [
      <InnerContainer key={0}>
        {
          intl.formatMessage({
            id: 'app.user-prompt-buttons.draft'
          }, {
            taskSelect,
            templateDocumentSelect: <TemplateDocumentSelect />
          })
        }
      </InnerContainer>
    ]
  }

  // Construct a list of components to render,
  // based on the current state of the user input form.
  const children: JSX.Element[] = []
  if (userInputForm.mainTask === 'Analyze') {
    children.push(...getAnalyzeComponents())
  } else if (userInputForm.mainTask === 'Translate') {
    children.push(...getTranslateComponents())
  } else if (userInputForm.mainTask === 'Chat') {
    children.push(...getChatComponents())
  } else if (userInputForm.mainTask === 'Draft') {
    children.push(...getDraftComponents())
  } else {
    children.push(
      <InnerContainer key={0}>
        {taskSelect}
      </InnerContainer>
    )
  }

  return (
    <Box className={'user-prompt-buttons'} sx={outerContainerSx}>
      {children}
    </Box>
  )
}

export default UserPromptButtons
