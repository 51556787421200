import { Feedback } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useReportModal } from 'features/reports'
import { useIntl } from 'react-intl'

export const ReportIcon = Feedback

export const ReportMenuItem = (): JSX.Element => {
  const intl = useIntl()

  // Control the report modal state through a hook,
  // so that the modal can be opened from any component
  // and not attached for a MenuItem that will disappear
  // when the user clicks on it.
  const { open } = useReportModal()

  const reportBugLabel = intl.formatMessage({
    id: 'app.report-modal.button.label',
    defaultMessage: 'Report / Feedback'
  })

  return (
    <MenuItem key={'report-bug'} onClick={open}>
      <ListItemIcon>
        <ReportIcon />
      </ListItemIcon>
      <ListItemText>
        {reportBugLabel}
      </ListItemText>
    </MenuItem>
  )
}
