import { type DocType } from 'features/documents'
import { useIntl } from 'react-intl'
import useComparativeAnalysisOptions from '../hooks/useComparativeAnalysisOptions'
import useSelectedDocuments from '../hooks/useSelectedDocuments'
import DocumentSelect from 'features/documents/components/DocumentSelect'

interface Props {
  /**
   * The index of the document to select in the attachmentsIds of the comparative analysis options.
   * The first document (index 0) is the reviewed document,
   * and the following documents (index 1, 2, ...) are the reference documents.
   */
  index: number
}

/**
 * A dropdown menu for selecting one of the uploaded documents and its type.
 */
export const ComparativeAnalysisDocumentSelect: React.FC<Props> = ({
  index
}: Props): JSX.Element => {
  const intl = useIntl()
  const { options, selectedComparisonType } = useComparativeAnalysisOptions()
  const { disabled, handleChange: handleDocChange } = useSelectedDocuments()

  const attachmentId = options.attachmentsIds[index]

  const acceptedDocTypes: DocType[] = (
    index === 0
      ? selectedComparisonType?.acceptedReviewedDocTypes
      : selectedComparisonType?.acceptedReferenceDocTypes
  ) ?? []
  if (acceptedDocTypes.length === 0) {
    throw new Error(`No accepted doc types found for document select (index: ${index})`)
  }

  const inputLabel = intl.formatMessage({
    id: 'app.comparative-analysis.attachment-select.label',
    defaultMessage: 'Select a document'
  })

  return (
    <DocumentSelect
      value={attachmentId}
      onChange={(newId) => { handleDocChange(index, newId) }}
      disabled={disabled}
      acceptedDocTypes={acceptedDocTypes}
      label={inputLabel}
    />
  )
}

export default ComparativeAnalysisDocumentSelect
