import { FormControl, MenuItem, Select, Typography, type SelectChangeEvent } from '@mui/material'
import SelectInputLabel from 'components/select/SelectInputLabel'
import { useListAttachments, type DocType } from 'features/documents'
import { useUpdateAttachment } from 'features/documents/api/updateAttachment'
import FilenameWithIcon from 'features/documents/components/FilenameWithIcon'
import { useIntl } from 'react-intl'
import { type UUID } from 'crypto'
import { useEffect } from 'react'
import { documentSelectSx, StyledSelectContainer } from './DocumentSelect.styles'

interface Props {
  /**
   * The currently selected attachment ID
   */
  value: UUID | null
  /**
   * Callback when the selected attachment changes
   */
  onChange: (attachmentId: UUID | null) => void
  /**
   * Whether the select is disabled
   */
  disabled?: boolean
  /**
   * The accepted document types for this select
   */
  acceptedDocTypes: DocType[]
  /**
   * Optional label for the select input
   */
  label?: string
}

/**
 * A dropdown menu for selecting one of the uploaded documents and its type.
 */
export const DocumentSelect: React.FC<Props> = ({
  value,
  onChange,
  disabled = false,
  acceptedDocTypes,
  label
}: Props): JSX.Element => {
  const intl = useIntl()
  const attachments = useListAttachments()

  const selectedAttachment = attachments?.find((attachment) => attachment.id === value)
  const updateAttachmentMutation = useUpdateAttachment({ attachmentId: value ?? undefined })

  useEffect(() => {
    if (selectedAttachment !== undefined) {
      // Make sure the docType is a valid choice, otherwise update it to the first valid choice
      if (!acceptedDocTypes.includes(selectedAttachment.docType)) {
        updateAttachmentMutation.mutate({
          update: {
            docType: acceptedDocTypes[0]
          }
        })
      }
    }
  }, [selectedAttachment, acceptedDocTypes])

  const handleDocumentChange = (event: SelectChangeEvent<string>): void => {
    if (attachments === undefined) {
      return
    }
    const newAttachmentId = event.target.value === '' ? null : event.target.value as UUID
    onChange(newAttachmentId)
  }

  const handleDocTypeChange = (event: SelectChangeEvent<DocType>): void => {
    updateAttachmentMutation.mutate({
      update: {
        docType: event.target.value
      }
    })
  }

  const inputLabel = label ?? intl.formatMessage({
    id: 'app.document-select.label',
    defaultMessage: 'Select a document'
  })

  return (
    <StyledSelectContainer>
      <FormControl size='small'>
        <SelectInputLabel
          showLabel={value === null && !disabled}
        >
          {inputLabel}
        </SelectInputLabel>
        <Select
          labelId='document-select-label'
          id='document-select'
          value={value ?? ''}
          onChange={handleDocumentChange}
          aria-label="Document"
          disabled={disabled}
          sx={documentSelectSx}
        >
          {Array.from(attachments ?? []).map((attachment, idx) => (
            <MenuItem
              key={idx}
              value={attachment.id}
            >
              <FilenameWithIcon
                filename={attachment.filename}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedAttachment !== undefined && acceptedDocTypes.length > 0 && (
        <>
          <Typography>{'('}</Typography>
          {acceptedDocTypes.length > 1
            ? (
              <Select
                value={selectedAttachment.docType}
                onChange={handleDocTypeChange}
                size="small"
              >
                {acceptedDocTypes.map((docType, index) => (
                  <MenuItem key={index} value={docType}>
                    {intl.formatMessage({ id: `app.doc-type.${docType}`, defaultMessage: docType })}
                  </MenuItem>
                ))}
              </Select>
              )
            : (
              <Typography>
                {intl.formatMessage({ id: `app.doc-type.${acceptedDocTypes[0]}`, defaultMessage: acceptedDocTypes[0] })}
              </Typography>
              )}
          <Typography>{')'}</Typography>
        </>
      )}
    </StyledSelectContainer>
  )
}

export default DocumentSelect
