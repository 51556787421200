import { Typography } from '@mui/material'
import { IssueType, type DocReviewIssue } from 'features/analyze/types'
import { type AttachmentListItem } from 'features/documents'
import { AttachmentChipContainer } from 'features/documents/components/AttachmentChip'
import { type FC } from 'react'
import { useIntl } from 'react-intl'

interface Props {
  typeToResults: Map<IssueType, DocReviewIssue[]>
  reviewedDoc: AttachmentListItem
}

export const InternalIssuesDescription: FC<Props> = ({
  typeToResults,
  reviewedDoc
}: Props) => {
  const intl = useIntl()

  // Create a paragraph that summarizes what Copilex has done,
  // including mentioning the reviewed formatted as an attachment chip,
  // to make it clickable.
  const description = intl.formatMessage({
    id: 'app.doc-review.issue-type.Internal.description'
  }, {
    reviewedDoc: <AttachmentChipContainer attachment={reviewedDoc} docTypeInLabel={false} deletable={false} openable={true} />,
    bold: (chunks: any) => <strong>{chunks}</strong>
  })

  return (
    <Typography className='internal-issues-description'>
      {description}
      <ul>
        {
          Array.from(typeToResults).map(([type, results], idx) => (
            <li key={idx}>
              {intl.formatMessage({
                id: 'app.doc-review.issue-type.Internal.item-description'
              }, {
                option: intl.formatMessage({
                  id: `app.doc-review.sanity-check.options.${type}`
                }),
                numResults: results.length
              })}
            </li>
          ))
        }
      </ul>
    </Typography>
  )
}

export default InternalIssuesDescription
