import { Box, styled } from '@mui/material'

export const StyledContainer = styled(Box)(({ theme }) => ({
  // Allow the container to shrink
  minHeight: 0,
  flexShrink: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(1),
  gap: theme.spacing(1)
}))
