import { Box, Menu, MenuItem, Typography } from '@mui/material'
import Icon from 'components/icons/feather'
import FilenameWithIcon from 'features/documents/components/FilenameWithIcon'
import useDocumentPanel from 'features/documents/hooks/useDocumentPanel'
import { useState, type FC } from 'react'
import { useIntl } from 'react-intl'
import { HEADER_HEIGHT_IN_PX } from './DocumentView.styles'
import { SelectSecondDocumentContainer } from './SelectSecondDocument.styles'
import { type UUID } from 'crypto'

/**
 * Component that allows the user to select a second document to compare with the primary document.
 */
export const SelectSecondDocument: FC = (): JSX.Element => {
  const intl = useIntl()
  const { state, openSecondaryDocument } = useDocumentPanel()
  // Used to position the menu relative to the mouse cursor
  const [menuPosition, setMenuPosition] = useState<{ top: number, left: number } | null>(null)

  const selectableDocuments = state.documents.filter((doc) => doc.id !== state.primaryDocument?.id)
  const hasSelectableDocuments = selectableDocuments.length > 0

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setMenuPosition({
      top: event.clientY,
      left: event.clientX
    })
  }

  const handleClose = (): void => {
    setMenuPosition(null)
  }

  const handleDocumentSelect = (documentId: UUID): void => {
    openSecondaryDocument(documentId)
    handleClose()
  }

  const label = (
    hasSelectableDocuments
      ? intl.formatMessage({ id: 'app.document-panel.select-second-document.label' })
      : intl.formatMessage({ id: 'app.document-panel.select-second-document.no-documents-label' })
  )

  return (
    <SelectSecondDocumentContainer className='select-second-document-container'>
      {/* Empty header, to match the space taken by the one of the primary document */}
      <Box sx={{ height: `${HEADER_HEIGHT_IN_PX}px`, flexShrink: 0 }} />
      <Box
        onClick={hasSelectableDocuments ? handleClick : undefined}
        sx={{
          height: '100%',
          flexShrink: 1,
          border: '1px dashed',
          borderColor: 'divider',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'action.hover'
          }
        }}
      >
        <Typography variant='body2' sx={{ textAlign: 'center' }}>{label}</Typography>
        {hasSelectableDocuments && <Icon.PlusCircle />}
      </Box>

      <Menu
        anchorReference="anchorPosition"
        anchorPosition={menuPosition ?? undefined}
        open={Boolean(menuPosition)}
        onClose={handleClose}
      >
        {selectableDocuments.map((doc) => (
          <MenuItem
            key={doc.id}
            onClick={() => { handleDocumentSelect(doc.id) }}
          >
            <FilenameWithIcon filename={doc.filename} />
          </MenuItem>
        ))}
      </Menu>
    </SelectSecondDocumentContainer>
  )
}

export default SelectSecondDocument
