import { Button, IconButton, Tooltip } from '@mui/material'
import Icon from 'components/icons/feather'
import { useGlobals } from 'context/GlobalsContext'
import { useIntl } from 'react-intl'

interface Props {
  open: () => void
  uploadDisabled: boolean
  iconOnly?: boolean
}

const OpenUploadModalButton = ({ open, uploadDisabled, iconOnly = false }: Props): JSX.Element => {
  const intl = useIntl()
  const globals = useGlobals()

  const prettyAcceptedFilesList = globals.attachments.acceptedFiletypes.map((filetype) =>
    `${filetype.name} (${filetype.extensions.join(', ')})`
  ).join(', ')

  const tooltipTitle = (
    uploadDisabled
      ? intl.formatMessage({
        id: 'app.file-uploader-modal.button.tooltip.disabled',
        defaultMessage: 'Can\'t attach more files for this session'
      })
      : intl.formatMessage({
        id: 'app.file-uploader-modal.button.tooltip.enabled',
        defaultMessage: 'Upload a file. Supports {prettyAcceptedFilesList}'
      }, { prettyAcceptedFilesList })
  )

  return (
    <Tooltip title={tooltipTitle}>
      <span>
        {iconOnly
          ? <IconButton
              onClick={open}
              disabled={uploadDisabled}
            >
              <Icon.Paperclip />
            </IconButton>
          : (
            <Button
              startIcon={
                <Icon.Paperclip />
              }
              onClick={open}
            >
              {
                !iconOnly && intl.formatMessage({
                  id: 'app.file-uploader-modal.button.label',
                  defaultMessage: 'Upload'
                })
              }
            </Button>
            )
        }
      </span>
    </Tooltip>
  )
}

export default OpenUploadModalButton
