/**
 * Dropdown to select the language to translate the message to
 */

import { MenuItem, Select } from '@mui/material'
import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { type TranslateTaskParams, type UserInputForm } from 'features/user-input-form/types'
import { useIntl } from 'react-intl'

interface Props {
  isTarget: boolean
}

const supportedLanguages: string[] = ['en', 'fr', 'de', 'es', 'it', 'nl', 'pt', 'ru', 'zh', 'ja', 'ko', 'ar']
const sourceLanguages = ['all', ...supportedLanguages]
const targetLanguages = supportedLanguages

const TranslateLanguageSelect = ({
  isTarget
}: Props): JSX.Element => {
  const intl = useIntl()
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const key = isTarget ? 'targetLang' : 'sourceLang'
  const langs = isTarget ? targetLanguages : sourceLanguages
  const defaultLang = langs[0]

  if (
    userInputForm === undefined ||
    userInputForm === null ||
    userInputForm.mainTask !== 'Translate'
  ) {
    throw new Error('TranslateLanguageSelect must be used with a Translate task')
  }

  const taskParams = (userInputForm.taskParams ?? {}) as TranslateTaskParams

  const handleChange = (update: Partial<UserInputForm>): void => {
    setUserInputForm(prevForm => ({ ...prevForm, ...update }))
  }

  return (
    <Select
      value={taskParams[key] ?? defaultLang}
      displayEmpty
      onChange={(event) => {
        const action = event.target.value
        handleChange({
          taskParams: {
            ...taskParams,
            [key]: action
          }
        })
      }}
    >
      {/* Show a menu item for each selectable language */}
      {Object.values(langs).map((lang, idx) => (
        <MenuItem key={idx} value={lang}>
          {
            intl.formatMessage({
              id: `app.translate-task-language-select.lang-${lang}.label`,
              defaultMessage: lang
            })
          }
        </MenuItem>
      ))}
    </Select>
  )
}

export default TranslateLanguageSelect
