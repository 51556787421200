import { Box, FormControl, MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import Loading from 'components/progress/Loading'
import { useGlobals } from 'context/GlobalsContext'
import { getEnabledAssistants } from 'features/assistants'
import useAssistant from 'features/assistants/hooks/useAssistant'
import { useSession } from 'features/sessions'

export const AssistantSelect: React.FC = () => {
  const globals = useGlobals()
  const { selectedSession: session } = useSession()
  const { assistant, setAssistantById } = useAssistant()

  const handleChange = (event: SelectChangeEvent<string>): void => {
    if (session === null) throw new Error('Session not set!')

    setAssistantById(event.target.value)
  }

  if (assistant === null) {
    return <Loading />
  }

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          labelId='model-select-label'
          id='model-select'
          value={assistant.id}
          onChange={handleChange}
          sx={{ width: '250px' }}
          aria-label="Model"
        >
          {getEnabledAssistants(globals).map((assistant, idx) => (
            <MenuItem key={idx} value={assistant.id}>
              {assistant.displayName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default AssistantSelect
