export const colors = {
  base: {
    white: '#FFFFFF',
    black: '#000000'
  },
  gray: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#E4E7EC',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#182230',
    900: '#101828',
    950: '#0C111D'
  },
  brand: {
    25: '#FAFDFF',
    50: '#F1F9FF',
    100: '#E2F2FE',
    200: '#BFE6FD',
    300: '#8AD5FB',
    400: '#56C0F7',
    500: '#3FA9E8',
    600: '#3087C6',
    700: '#256BA1',
    800: '#205B84',
    900: '#1C4C6E',
    950: '#102F49'
  },
  error: {
    50: '#FEF3F2',
    200: '#FECDCA',
    500: '#F04438',
    700: '#B42318',
    800: '#912018'
  },
  warning: {
    50: '#FFFAEB',
    200: '#FEDF89',
    500: '#F79009',
    700: '#B54708',
    800: '#93370D'
  },
  success: {
    50: '#ECFDF3',
    200: '#ABEFC6',
    500: '#17B26A',
    700: '#067647',
    800: '#085D3A'
  }
}

// Copilex brand color to use for primary actions
export const copilexBlueColor = colors.brand[500]

// Alternative brand color to use for hover states
export const hoverColor = colors.brand[700]

// Background color for the mark component (ie. highlighted text)
export const markBgColor = '#FDE3C1'

export default colors
