import { ZoomIn, ZoomOut } from '@mui/icons-material'
import { Box, IconButton, Select, MenuItem } from '@mui/material'
import { type Dispatch, type SetStateAction } from 'react'

const ZOOM_LEVELS = [0.5, 0.7, 0.85, 1, 1.25, 1.5, 1.75, 2, 3, 4]

interface Props {
  scale: number
  setScale: Dispatch<SetStateAction<number>>
}

export const ZoomControls = ({ scale, setScale }: Props): JSX.Element => {
  const currentIndex = ZOOM_LEVELS.indexOf(scale)

  const handleZoomOut = (): void => {
    if (currentIndex > 0) {
      setScale(ZOOM_LEVELS[currentIndex - 1])
    }
  }

  const handleZoomIn = (): void => {
    if (currentIndex < ZOOM_LEVELS.length - 1) {
      setScale(ZOOM_LEVELS[currentIndex + 1])
    }
  }

  return (
    <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
      <IconButton
        onClick={handleZoomOut}
        disabled={currentIndex === 0}
      >
        <ZoomOut />
      </IconButton>

      <Select
        value={scale}
        onChange={(e) => { setScale(Number(e.target.value)) }}
        size="small"
      >
        {ZOOM_LEVELS.map((zoom) => (
          <MenuItem key={zoom} value={zoom}>
            {`${zoom * 100}%`}
          </MenuItem>
        ))}
      </Select>

      <IconButton
        onClick={handleZoomIn}
        disabled={currentIndex === ZOOM_LEVELS.length - 1}
      >
        <ZoomIn />
      </IconButton>
    </Box>
  )
}

export default ZoomControls
