import { ExpandMore } from '@mui/icons-material'
import { useColorMode } from 'context/ColorModeContext'
import { type UserInputForm } from 'features/user-input-form/types'
import { NestedDropdown } from 'mui-nested-menu'
import { buttonProps, menuProps, TaskSelectContainer } from './TaskSelect.styles'
import useSelectTaskEntry from './useSelectTaskEntry'

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

const TaskSelect: React.FC<Props> = () => {
  const { colorMode } = useColorMode()

  const { selectedTaskEntry, menuItemsData } = useSelectTaskEntry()

  return (
    <TaskSelectContainer>
      <NestedDropdown
        menuItemsData={menuItemsData}
        ButtonProps={{
          ...buttonProps(colorMode),
          startIcon: selectedTaskEntry.icon,
          endIcon: <ExpandMore />
        }}
        MenuProps={menuProps}
      />
    </TaskSelectContainer>
  )
}

export default TaskSelect
