import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Fade,
  Modal,
  Typography
} from '@mui/material'
import CloseModalButton from 'components/buttons/CloseModalButton'
import { useAuth } from 'context/AuthContext'
import { useSession } from 'features/sessions'
import { createReport } from 'features/reports/api/createReport'
import { type Report } from 'features/reports/types'
import type React from 'react'
import { useIntl } from 'react-intl'
import { ModalContainer, ModalTitle } from 'theme'
import { ModalHeader } from './ReportModal.styles'
import ReportModalForm from './ReportModalForm'
import { useReportModal } from '../hooks/useReportModal'

export const ReportModal: React.FC = () => {
  const intl = useIntl()
  const currentUser = useAuth()
  const { selectedSession: session } = useSession()
  const { getAccessTokenSilently } = useAuth0()
  const { isOpen, close } = useReportModal()

  const handleSubmit = (comment: string): void => {
    const report: Report = {
      comment,
      url: window.location.href,
      sessionId: session?.id ?? undefined
    }
    void getAccessTokenSilently().then(async (token) => {
      await createReport(token, report, currentUser)
      close()
    })
  }

  return (
    <Modal
      open={isOpen}
      onClose={close}
      aria-labelledby="report-modal-title"
      aria-describedby="report-modal-description"
    >
      <Fade in={isOpen}>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle className="report-modal-title">
              {
                intl.formatMessage({
                  id: 'app.report-modal.title',
                  defaultMessage: 'Report a bug or make a suggestion'
                })
              }
            </ModalTitle>
            <CloseModalButton onClick={close} />
          </ModalHeader>
          <Box>
            <ReportModalForm onSubmit={handleSubmit} onClose={close} />
          </Box>
        </ModalContainer>
      </Fade>
    </Modal>
  )
}

export default ReportModal
