import { type Evidence } from 'features/analyze'
import EvidenceDisplay from './EvidenceDisplay'
import { StyledTableCell } from './EvidenceTableCell.styles'

interface Props {
  evidence: Evidence | null
  onClick?: () => void
}

/**
 * Display a single evidence in a table cell.
 */
const EvidenceTableCell: React.FC<Props> = ({ evidence, onClick }: Props) => {
  return (
    <StyledTableCell className='evidence-table-cell'>
      {/* Some cells will be empty if the number of evidences
      for the reviewed and reference documents are differ */}
      {evidence !== null && <EvidenceDisplay evidence={evidence} onClick={onClick} />}
    </StyledTableCell>
  )
}

export default EvidenceTableCell
