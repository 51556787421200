import Icon from 'components/icons/feather'
import { type FC } from 'react'
import { StyledBox, type FloatingViewIconPlacement } from './FloatingViewIcon.styles'
import { IconButton, type IconButtonProps } from '@mui/material'

interface Props {
  placement?: FloatingViewIconPlacement
}

/**
 * "View" icon floating on the right side of a container,
 * indicating that the component is clickable
 * and it will show a visual representation of the content
 */
export const FloatingViewIcon: FC<Props> = (props): JSX.Element => {
  return (
    <StyledBox {...props}>
      <Icon.Eye />
    </StyledBox>
  )
}

export const ViewIconButton: FC<IconButtonProps> = (props): JSX.Element => {
  return <IconButton {...props}><Icon.Eye /></IconButton>
}
