import {
  Select,
  Typography,
  type SxProps,
  type Theme
} from '@mui/material'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useGlobals } from '../../../context/GlobalsContext'
import EntitySelectMenuItem from './EntitySelectMenuItem'
import { EntityLabelTypography, StyledMenuItem } from './Selectors.styles'

interface Props {
  open: boolean
  onOpen?: () => void
  onClose?: () => void
  label: string
  value: string
  onSelectEntityCategory: (category: string) => void
  sx?: SxProps<Theme>
}

const EntityTypeSelector: React.FC<Props> = ({
  open,
  onOpen,
  onClose,
  label,
  value,
  onSelectEntityCategory,
  sx
}: Props) => {
  const intl = useIntl()

  const [openedMenuIdx, setOpenedMenuIdx] = useState<number | null>(null)

  const globals = useGlobals()
  if (globals === null) return <></>

  return <Select
    className='entity-type-selector'
    id="add-entity-type-select"
    labelId='add-entity-type-select-label'
    label={label}
    sx={sx ?? {}}
    value={value}
    renderValue={() => <Typography color='white'>{value}</Typography>}
    open={open}
    onOpen={onOpen ?? (() => {})}
    onClose={onClose ?? (() => {})}
    autoWidth
  >
    {Array.from(globals.entityTypesByGroup).map(([group, entityTypeInfos], idx) => (
      // Render each group of entity types as a submenu,
      // except for the groups that we want to "flatten".
      (globals.flattenedGroups.includes(group)
        ? entityTypeInfos.map((entityTypeInfo, idx2) => (
            <StyledMenuItem
              key={idx * 100 + idx2}
              onClick={() => {
                onSelectEntityCategory(entityTypeInfo.entityType)
              }}
              value={entityTypeInfo.entityType}
              onMouseEnter={() => { setOpenedMenuIdx(null) }}
            >
              <EntityLabelTypography>
                {
                  intl.formatMessage({
                    id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                    defaultMessage: entityTypeInfo.prettyName
                  })
                }
              </EntityLabelTypography>
            </StyledMenuItem>
        ))
        : [
          <EntitySelectMenuItem
            key={idx * 100}
            label={
              intl.formatMessage({
                id: `app.entity-group.${group}`,
                defaultMessage: group
              })
            }
            openDirection={'right'}
            open={openedMenuIdx === idx}
            onOpen={() => { setOpenedMenuIdx(idx) }}
          >
            {entityTypeInfos.map((entityTypeInfo, idx2) => (
              <StyledMenuItem
                key={idx * 100 + idx2}
                onClick={() => {
                  onSelectEntityCategory(entityTypeInfo.entityType)
                } }
                value={entityTypeInfo.entityType}
              >
                <EntityLabelTypography>
                  {
                    intl.formatMessage({
                      id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                      defaultMessage: entityTypeInfo.prettyName
                    })
                  }
                </EntityLabelTypography>
              </StyledMenuItem>
            ))}
          </EntitySelectMenuItem>
          ]
      )
    )).flat()}
  </Select>
}

export default EntityTypeSelector
