import { Delete } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import { type FC } from 'react'
import {
  ClickableArea,
  StyledCard,
  StyledCardActions,
  StyledCardContent,
  StyledCardMedia,
  StyledSecondaryTypography,
  StyledTypography
} from './DocumentCard.styles'
import { useIntlContext } from 'context/IntlProviderWrapper'
import { formatDateStringToLocale } from 'services/utils'

interface Props {
  fileIconSrc: string
  nameWithoutExtension: string
  createdAt: string
  onSelect?: () => void
  onDelete?: () => void
}

const DocumentCard: FC<Props> = ({
  fileIconSrc,
  nameWithoutExtension,
  createdAt,
  onSelect,
  onDelete
}): JSX.Element => {
  const { localeConfig } = useIntlContext()

  return (
    <StyledCard variant="outlined">
      <ClickableArea
        className="clickable-area"
        onClick={onSelect}
      >
        <StyledCardMedia image={fileIconSrc} />
        <StyledCardContent>
          <StyledTypography variant="body2">
            {nameWithoutExtension}
          </StyledTypography>
          <StyledSecondaryTypography variant="caption">
            {formatDateStringToLocale(localeConfig.locale, createdAt)}
          </StyledSecondaryTypography>
        </StyledCardContent>
      </ClickableArea>
      {onDelete !== undefined && (
        <StyledCardActions>
          <IconButton aria-label="delete" onClick={onDelete}>
            <Delete />
          </IconButton>
        </StyledCardActions>
      )}
    </StyledCard>
  )
}

export default DocumentCard
