import useDocumentPanel from 'features/documents/hooks/useDocumentPanel'
import BackToListView from './BackToListView'
import SecondFileToggleButton from './SecondFileToggleButton'
import StyledBox from './DocumentPanelHeader.styles'
import { Typography } from '@mui/material'
import { useIntl } from 'react-intl'

/**
 * Adds at the top of the document panel some buttons to navigate
 * between the different views.
 */
export const DocumentPanelHeader = (): JSX.Element => {
  const intl = useIntl()
  const { state } = useDocumentPanel()

  const label = intl.formatMessage({ id: 'app.document-panel.header.label', defaultMessage: 'Document panel' })

  return (
    <StyledBox className='document-panel-header'>
      {state.viewMode !== 'LIST_VIEW' && <BackToListView />}
      {state.viewMode === 'LIST_VIEW' && <Typography variant='overline'>{label}</Typography>}
      {state.viewMode !== 'LIST_VIEW' && <SecondFileToggleButton />}
    </StyledBox>
  )
}

export default DocumentPanelHeader
