import { ChevronRight } from '@mui/icons-material'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import { Button, Typography } from '@mui/material'
import { type FC, useState } from 'react'
import { useIntl } from 'react-intl'
import TipConfidentialityToggleSwitch from './TipConfidentialityToggleSwitch'
import { StyledContainer, StyledContent, StyledHeader } from './TipOfTheDayCarousel.styles'
import TipTaskSelect from './TipTaskSelect'
import TipFeedback from './TipFeedback'

/**
 * A carousel of tips of the day
 */
export const TipOfTheDayCarousel: FC = (): JSX.Element => {
  const intl = useIntl()

  const [currentTipIndex, setCurrentTipIndex] = useState<number>(0)

  const tips = [
    <TipTaskSelect key="tip-task-select" />,
    <TipConfidentialityToggleSwitch key="tip-confidentiality-toggle-switch" />,
    <TipFeedback key="tip-feedback" />
  ]

  const handleNext = (): void => {
    setCurrentTipIndex((prev) => (prev < tips.length - 1 ? prev + 1 : 0))
  }

  return (
    <StyledContainer className='tip-of-the-day-carousel-container'>
      <StyledHeader className='tip-of-the-day-carousel-header'>
        <TipsAndUpdatesOutlinedIcon />
        <Typography fontWeight="bold">
          {
            intl.formatMessage({
              id: 'app.chat.splash-screen.tips.label',
              defaultMessage: 'Tip of the day:'
            })
          }
        </Typography>
      </StyledHeader>
      <StyledContent className='tip-of-the-day-carousel-content'>
        {tips[currentTipIndex]}
      </StyledContent>
      <Button onClick={handleNext} endIcon={<ChevronRight />}>
        {intl.formatMessage({
          id: 'app.chat.splash-screen.tips.next-tip'
        })}
      </Button>
    </StyledContainer>
  )
}

export default TipOfTheDayCarousel
