import { IconButton, Tooltip } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useIntl } from 'react-intl'

interface Props {
  onClick: () => void
}

const CloseModalButton: React.FC<Props> = ({ onClick }: Props) => {
  const intl = useIntl()
  const tooltip = intl.formatMessage({
    id: 'app.close-modal-button.tooltip',
    defaultMessage: 'Close the window'
  })

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={onClick} size='small'>
        <Close />
      </IconButton>
    </Tooltip>
  )
}

export default CloseModalButton
