import { ViewIconButton } from 'components/icons/FloatingViewIcon'
import CorrectionsDisplay from 'features/analyze/components/CorrectionsDisplay'
import CriticalityDisplay from 'features/analyze/components/CriticalityDisplay'
import EvidenceDisplay from 'features/analyze/components/evidence/EvidenceDisplay'
import { EvidenceLocation } from 'features/analyze/components/evidence/EvidenceLocation'
import ExplanationDisplay from 'features/analyze/components/ExplanationDisplay'
import Title from 'features/analyze/components/internal-issue/Title'
import { IssueAccordion, IssueAccordionDetails, IssueAccordionIconsContainer, IssueAccordionSummary, IssueAccordionSummaryContainer } from 'features/analyze/components/IssueAccordion'
import { type DocReviewIssue, type Evidence } from 'features/analyze/types'
import { useState } from 'react'
import { IssueDetailsContainer, TitleAndLocationContainer } from './InternalIssueDisplay.style'

interface Props {
  idx: number
  result: DocReviewIssue
  onSummaryClick?: () => void
  onEvidenceClick?: (evidence: Evidence) => void
  onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void
}

/**
 * Presentational component for a single internal issue.
 */
export const InternalIssueDisplay = ({
  idx,
  result,
  onSummaryClick,
  onEvidenceClick,
  onChange
}: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false)

  /**
   * Helper function to get the main evidence,
   * used to display one main location for an issue.
   * It assumes that the first evidence is the most relevant one.
   */
  const getMainEvidence = (evidences: Evidence[]): Evidence | null => {
    if (evidences.length === 0) {
      return null
    }
    return evidences.find(evidence =>
      evidence.location !== null && evidence.location !== ''
    ) ?? null
  }

  const mainEvidence = getMainEvidence(result.evidences)
  const titleAndLocationContainer = (
    <TitleAndLocationContainer>
      <Title result={result} />
      {
        !isExpanded && mainEvidence !== null && (
          <EvidenceLocation evidence={mainEvidence} />
        )
      }
    </TitleAndLocationContainer>
  )

  // Handler to prevent expansion when clicking on the view icon button
  const handleViewIconButtonClick = (event: React.MouseEvent): void => {
    event.stopPropagation()
    onSummaryClick?.()
  }

  const viewIconButton = (
    <ViewIconButton
      onClick={handleViewIconButtonClick}
    />
  )

  const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean): void => {
    setIsExpanded(isExpanded)
    onChange?.(event, isExpanded)
  }

  return (
    <IssueAccordion
      className={`doc-review-issue-${idx + 1}`}
      expanded={isExpanded}
      onChange={handleAccordionChange}
    >
      <IssueAccordionSummary
        aria-controls={`doc-review-issue-${idx + 1}-details`}
        id={`doc-review-issue-${idx + 1}-summary`}
      >
        <IssueAccordionSummaryContainer>
          <IssueAccordionIconsContainer>
            <CriticalityDisplay criticality={result.criticality} />
          </IssueAccordionIconsContainer>
          {titleAndLocationContainer}
          {viewIconButton}
        </IssueAccordionSummaryContainer>
      </IssueAccordionSummary>
      <IssueAccordionDetails className={`doc-review-issue-${idx + 1}-details`}>
        <IssueDetailsContainer>
          {/** Put the corrections if any, otherwise put the explanation */}
          {
            result.evidences.map((evidence, idx) => {
              return <EvidenceDisplay
                key={idx}
                evidence={evidence}
                onClick={() => onEvidenceClick?.(evidence)}
              />
            })
          }
          {
            result.explanation !== null &&
              <ExplanationDisplay explanation={result.explanation} />
          }
          {
            // If there are corrections, display them instead of the explanation
            result.corrections !== null
              ? <CorrectionsDisplay corrections={result.corrections} />
              : result.explanation
          }
        </IssueDetailsContainer>
      </IssueAccordionDetails>
    </IssueAccordion>
  )
}

export default InternalIssueDisplay
