import { Box } from '@mui/material'
import ChatLayout from 'components/central-pane/chat/ChatLayout'
import { MessageBox } from 'components/central-pane/chat/MessageBox'
import SplashScreen from 'components/central-pane/chat/splash-screen'
import Loading from 'components/progress/Loading'
import { useAuth } from 'context/AuthContext'
import { useGlobals } from 'context/GlobalsContext'
import { type Assistant, assistantIdToSettings, getSelectedAssistant } from 'features/assistants'
import { type Message, Role } from 'features/messages'
import { type Session } from 'features/sessions'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

const isCopilex = (assistant: Assistant): boolean => {
  return assistant.id.startsWith('copilex')
}

interface Props {
  session: Session
  messages: Message[]
  onFeedbackSubmitted: (idx: number, feedback: number) => void
  onCorrectionSubmitted: (idx: number, correction: string) => void
}

interface ChatState {
  isReady: boolean
  assistant: Assistant | null
  showSplashScreen: boolean
}

/**
 * Component that manages and renders the chat.
 */
export const Chat: React.FC<Props> = ({
  session, messages, onFeedbackSubmitted, onCorrectionSubmitted
}: Props): JSX.Element => {
  const intl = useIntl()
  const currentUser = useAuth()
  const globals = useGlobals()

  const [state, setState] = useState<ChatState>({
    isReady: false,
    assistant: null,
    showSplashScreen: true
  })

  // Update internal state
  useEffect(() => {
    console.debug('> Chat [session, globals, messages]')

    // Put the chat in a loading state
    setState(prevState => ({ ...prevState, isReady: false }))

    if (globals === null || globals === undefined || session === null || session === undefined) {
      return
    }

    const assistant = getSelectedAssistant(session, globals)

    setState({
      isReady: true,
      assistant,
      showSplashScreen: messages.length === 0 && isCopilex(assistant)
    })
  }, [session, globals, messages])

  if (!state.isReady) {
    return <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Loading />
    </Box>
  }
  if (state.assistant === null) {
    throw new Error('Assistant not found despite being ready')
  }

  const getGreetings = (assistant: Assistant, username: string): string => {
    if (isCopilex(assistant)) {
      return intl.formatMessage({
        id: 'app.greetings.copilex',
        defaultMessage: (
          'Hi, {username}. How can I help? (Please note that I am still in Beta version, my answers ' +
          'can still be limited and/or incorrect. Use them carefully)'
        )
      }, { username })
    }
    return intl.formatMessage({
      id: 'app.greetings.default',
      defaultMessage: (
        'You are now interacting with {name}, a model developed by {author}. ' +
        'Answers are generated by {author}\'s model only. The Copilex team has no control on them and is ' +
        'not responsible for their content. (Please note that this feature is provided for experimental purposes ' +
        'and may not be accessible at all time)'
      )
    }, { name: assistant.displayName, author: assistant.modelAuthor })
  }

  // Create greetings message to show at the top of the chat
  const settings = assistantIdToSettings(state.assistant.id)
  const greetings = getGreetings(state.assistant, currentUser.userProfile.firstName)
  const greetingsMsg: Message = {
    role: Role.ASSISTANT,
    cleartextContent: greetings,
    anonContent: greetings,
    settings
  }

  const handleCloseSplashScreen = (): void => {
    setState(prevState => ({ ...prevState, showSplashScreen: false }))
  }

  if (state.showSplashScreen) {
    return <SplashScreen username={currentUser.userProfile.firstName} onClose={handleCloseSplashScreen} />
  }

  return (
    <ChatLayout>
      {/* Greetings message (not an actual message) */}
      {messages.length === 0 && <MessageBox msg={greetingsMsg} disableButtons={true} sessionId={session.id} />}

      {/* Messages */}
      {messages.map((msg, idx) =>
        <MessageBox
          key={idx}
          msg={msg}
          sessionId={session.id}
          onFeedbackSubmitted={(feedback) => { onFeedbackSubmitted(idx, feedback) }}
          onCorrectionSubmitted={(correction) => { onCorrectionSubmitted(idx, correction) }} />
      )}
    </ChatLayout>
  )
}

export default Chat
