import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { DraftTaskAction, type DraftTaskParams } from '../types'
import { type AttachmentListItem } from 'features/documents/types'
import { type UUID } from 'crypto'

export const defaultDraftTaskParams: DraftTaskParams = {
  action: DraftTaskAction.TEMPLATE_FILLING,
  templateId: null,
  sourceIds: []
}

interface UseDraftTaskParamsProps {
  draftTaskParams: DraftTaskParams
  templateId: UUID | null
  setTemplateId: (templateId: UUID | null) => void
  setSourceIds: (attachmentIds: UUID[]) => void
  addSourceIds: (attachmentIds: UUID[]) => void
  removeSourceIds: (attachmentIds: UUID[]) => void
  swapTemplateAndFirstSource: () => void
  initWithAttachments: (attachments: AttachmentListItem[]) => void
}

/**
 * Hook to get and update the draft task params.
 */
const useDraftTaskParams = (): UseDraftTaskParamsProps => {
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const draftTaskParams: DraftTaskParams = userInputForm.taskParams as DraftTaskParams ?? defaultDraftTaskParams

  const setTemplateId = (attachmentId: UUID | null): void => {
    const newSourceIds = attachmentId !== null
      ? draftTaskParams.sourceIds?.filter(id => id !== attachmentId) ?? []
      : draftTaskParams.sourceIds ?? []

    setUserInputForm((prevForm) => ({
      ...prevForm,
      taskParams: {
        ...draftTaskParams,
        templateId: attachmentId,
        sourceIds: newSourceIds
      }
    }))
  }

  const setSourceIds = (attachmentIds: UUID[]): void => {
    const templateId = draftTaskParams.templateId
    const newTemplateId = templateId !== null && attachmentIds.includes(templateId)
      ? null
      : templateId

    setUserInputForm((prevForm) => ({
      ...prevForm,
      taskParams: {
        ...draftTaskParams,
        templateId: newTemplateId,
        sourceIds: attachmentIds
      }
    }))
  }

  /**
   * Add some attachment ids as sources, if they are not already present,
   * adding them to the end of the list.
   */
  const addSourceIds = (attachmentIds: UUID[]): void => {
    const presentIds = draftTaskParams.sourceIds ?? []
    const notPresentIds = attachmentIds.filter((id) => !presentIds.includes(id))
    const newSourceIds = [...presentIds, ...notPresentIds]
    setSourceIds(newSourceIds)
  }

  const removeSourceIds = (attachmentIds: UUID[]): void => {
    const newSourceIds = draftTaskParams.sourceIds?.filter((id) => !attachmentIds.includes(id)) ?? []
    setSourceIds(newSourceIds)
  }

  const swapTemplateAndFirstSource = (): void => {
    if (draftTaskParams.templateId === null) {
      return
    }
    if (draftTaskParams.sourceIds === null || draftTaskParams.sourceIds.length === 0) {
      return
    }
    const newTemplateId = draftTaskParams.sourceIds[0]
    const newSourceIds = [draftTaskParams.templateId, ...draftTaskParams.sourceIds.slice(1)]
    setUserInputForm((prevForm) => ({
      ...prevForm,
      taskParams: {
        ...draftTaskParams,
        templateId: newTemplateId,
        sourceIds: newSourceIds
      }
    }))
  }

  /**
   * Initialize the draft task params with the given attachments, if needed
   */
  const initWithAttachments = (attachments: AttachmentListItem[]): void => {
    // Check which attachments are already selected either as template or source
    const alreadySelectedAttachmentIds = [
      draftTaskParams.templateId,
      ...(draftTaskParams.sourceIds ?? [])
    ].filter((id) => id !== null) as UUID[]
    // Filter out the already selected attachments
    const availableAttachmentIds = attachments.filter((attachment) => !alreadySelectedAttachmentIds.includes(attachment.id))
    // Use the first available attachment that matches as the template, if needed
    let newTemplateId = draftTaskParams.templateId
    if (newTemplateId === null) {
      // Pop the first available attachment as the template
      newTemplateId = availableAttachmentIds.shift()?.id ?? null
    }
    // Use the remaining available attachments as sources
    const newSourceIds = [
      ...(draftTaskParams.sourceIds ?? []),
      ...availableAttachmentIds.map((attachment) => attachment.id)
    ]
    // Update the user input form
    setUserInputForm((prevForm) => ({
      ...prevForm,
      taskParams: {
        ...draftTaskParams,
        templateId: newTemplateId,
        sourceIds: newSourceIds
      }
    }))
  }

  return {
    draftTaskParams,
    initWithAttachments,
    templateId: draftTaskParams.templateId,
    setTemplateId,
    setSourceIds,
    addSourceIds,
    removeSourceIds,
    swapTemplateAndFirstSource
  }
}

export default useDraftTaskParams
