import { IconButton } from '@mui/material'
import Icon from 'components/icons/feather'
import PdfViewer, { type PdfViewerId } from 'components/PdfViewer'
import SearchComponent from 'components/PdfViewer/SearchComponent'
import useAttachmentAsPDF from 'features/documents/api/getAttachmentAsPDF'
import { type DocumentData } from 'features/documents/providers/DocumentPanelProvider'
import { useSession } from 'features/sessions'
import type React from 'react'
import FilenameWithIcon from '../FilenameWithIcon'
import { DocumentViewContainer, DocumentViewHeader, FilenameWithIconContainer } from './DocumentView.styles'

interface Props {
  viewerId: PdfViewerId
  document: DocumentData
  width: number
  onClose?: () => void
  onLoad?: () => void
}

/**
 * Display a document inside the document workspace.
 */
export const DocumentView: React.FC<Props> = ({ viewerId, document, width, onClose, onLoad }: Props): JSX.Element => {
  const { selectedSessionId } = useSession()
  const { data: file } = useAttachmentAsPDF({
    sessionId: selectedSessionId ?? '', // TODO: handle this better
    attachmentId: document.id
  })

  if (file === undefined) {
    return <></>
  }

  const closeButton = <IconButton onClick={onClose} size='small' sx={{ flexShrink: 0 }}>
    <Icon.X size={16} />
  </IconButton>

  return (
    <DocumentViewContainer className='document-view' width={width}>
      <DocumentViewHeader className='document-view-header'>
        <FilenameWithIconContainer>
          <FilenameWithIcon filename={document.filename} />
        </FilenameWithIconContainer>
        {onClose !== undefined && closeButton}
      </DocumentViewHeader>
      <PdfViewer viewerId={viewerId} file={file} width={width} onLoad={onLoad} />
      {/* Only show search component in development mode */}
      {process.env.NODE_ENV === 'development' && <SearchComponent viewerId={viewerId} />}
    </DocumentViewContainer>
  )
}

export default DocumentView
