import { type FC } from 'react'
import { Divider } from '@mui/material'
import useSidepanes from 'components/sidepanes/SidepanesContext'
import useDocumentPanel from 'features/documents/hooks/useDocumentPanel'
import { useEffect, useState } from 'react'
import DocumentView from './DocumentView'
import { DocumentViewsContainer, DocumentWorkspaceContainer, VIEWS_GAP_IN_PX } from './DocumentWorkspace.styles'
import SelectSecondDocument from './SelectSecondDocument'
import { getSidepaneWidthInPx } from 'components/sidepanes'

/**
 * A component that displays the content of one or two documents side by side.
 */
export const DocumentWorkspace: FC = (): JSX.Element => {
  const { rightPane } = useSidepanes()
  const {
    state,
    closePrimaryDocument,
    closeSecondaryDocument,
    openListView,
    handlePrimaryDocumentLoad,
    handleSecondaryDocumentLoad
  } = useDocumentPanel()
  const [width, setWidth] = useState<number>(0)

  useEffect((): void => {
    // Compute the available width for each document view, which needs a fixed width value
    // to be able to display the document content.
    // Leave some space to match the padding + gap between the two views
    const totalWidth = getSidepaneWidthInPx(rightPane) - 16
    setWidth(state.viewMode === 'COMPARE_VIEW' ? (totalWidth - VIEWS_GAP_IN_PX * 2) / 2 : totalWidth)
  }, [state.viewMode, rightPane])

  // If the primary document is undefined, switch back to the list view
  // because the workspace is not usable without a document.
  if (state.primaryDocument === undefined) {
    console.error('Primary document is undefined, cannot render DocumentView')
    openListView()
    return <></>
  }

  const handleClosePrimaryDocument = (): void => {
    closePrimaryDocument()
  }

  const handleCloseSecondaryDocument = (): void => {
    closeSecondaryDocument()
  }

  return (
    <DocumentWorkspaceContainer className='document-workspace' >
      <DocumentViewsContainer className='document-views'>
        {/* Primary document view */}
        <DocumentView
          viewerId='primary'
          document={state.primaryDocument}
          width={width}
          onClose={handleClosePrimaryDocument}
          onLoad={handlePrimaryDocumentLoad}
        />
        {/* Divider between primary and secondary document views, for readability */}
        {state.viewMode === 'COMPARE_VIEW' && (
          <Divider orientation='vertical' flexItem />
        )}
        {/* Secondary document view, or document selection */}
        {state.viewMode === 'COMPARE_VIEW' && (
          state.secondaryDocument !== undefined
            ? <DocumentView
                viewerId='secondary'
                document={state.secondaryDocument}
                width={width}
                onClose={handleCloseSecondaryDocument}
                onLoad={handleSecondaryDocumentLoad}
              />
            : <SelectSecondDocument />
        )}
      </DocumentViewsContainer>
    </DocumentWorkspaceContainer>
  )
}

export default DocumentWorkspace
