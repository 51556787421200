import { useAuth0 } from '@auth0/auth0-react'
import Loading from 'components/progress/Loading'
import { useEffect } from 'react'

/**
 * Handles both sign in and sign up, and redirects to the page the user came from
 */
const LoginPage = (): JSX.Element => {
  const {
    loginWithRedirect
  } = useAuth0()

  useEffect(() => {
    void loginWithRedirect()
  }, [])

  return <Loading />
}

export default LoginPage
