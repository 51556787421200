import { Avatar, Box, styled } from '@mui/material'

/**
 * Avatar size in pixels for the user avatar displayed in left pane (in pixels)
 */
export const AVATAR_SIZE = 44

export const StyledBox = styled(Box)(() => ({
  width: AVATAR_SIZE,
  height: AVATAR_SIZE
}))

export const StyledAvatar = styled(Avatar)(() => ({
  position: 'relative',
  top: -42,
  left: -2,
  width: AVATAR_SIZE,
  height: AVATAR_SIZE
}))
