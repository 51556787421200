import { MenuItem, styled, type SxProps, Typography } from '@mui/material'

export const nestedMenuItemSx: SxProps = {
  display: 'flex',
  paddingLeft: '4px',
  paddingRight: '4px'
}

export const EntityLabelTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: '8px',
  paddingRight: '8px'
}))

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  // I think it has to be a block element and not a flex container
  // to be able to force the child icon to the right (with margin-left: auto and margin-right: 0).
  // At least, it would not work with display: flex.
  display: 'block',
  alignItems: 'center',
  paddingLeft: '4px',
  paddingRight: '4px'
}))
