import { Box, styled } from '@mui/material'
import { ICON_BUTTON_WIDTH_IN_PX } from 'components/sidepanes/SidepaneToggleButton'

interface StyledContainerProps {
  containerWidth: number
}

export const StyledContainer = styled(Box)<StyledContainerProps>(({ theme, containerWidth }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  // Pad to avoid floating button icons to appear above it.
  paddingLeft: ICON_BUTTON_WIDTH_IN_PX,
  paddingRight: ICON_BUTTON_WIDTH_IN_PX,
  zIndex: 1,
  color: 'text.primary',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
  // When not enough width, center the content. Otherwise, the floating button icons to
  // open the sidepanes will appear above it.
  // ...(containerWidth < 800 && {
  //   justifyContent: 'center',
  //   marginLeft: 'auto',
  //   marginRight: 'auto'
  // })
}))
