import { useMemo, useState } from 'react'
import { type Criticality, type DocReviewIssue, type IssueType, type ComparativeAnalysisNature } from '../types'
import { SanityCheckOption } from 'features/user-input-form'

export type SortCriteria = 'document-order' | 'criticality-high-to-low' | 'criticality-low-to-high'

/**
 * Whether to show items that are issues (criticality is not null and not 'non applicable'),
 * non-issues (criticality is either null or 'non applicable'), or all.
 * The naming is weird because there is already an 'IssueType' type for something else.
 */
export const ISSUE_NON_ISSUE_TYPE_VALUES = ['all', 'issues', 'non-issues'] as const
export type IssueNonIssueType = (typeof ISSUE_NON_ISSUE_TYPE_VALUES)[number]

interface FilterState {
  /**
   * The criteria to sort the issues by, e.g., in the order of the document,
   * from high to low criticality, ...
   */
  sortBy: SortCriteria
  /**
   * Whether to show issues (criticality is not null and not 'non applicable'),
   * non-issues (criticality is either null or 'non applicable'), or all.
   */
  type: IssueNonIssueType
  /**
   * The criticalities to filter by. Won't be applied if empty.
   */
  criticalities: Criticality[]
  /**
   * The natures to filter by. Won't be applied if empty.
   */
  natures: ComparativeAnalysisNature[]
  /**
   * The issue types to filter by, e.g. 'Typographical errors', 'Internal cross-reference check', etc.
   * Won't be applied if empty.
   */
  issueTypes: IssueType[]
}

/**
 * Hook to filter and sort issues.
 */
export const useDocReviewIssuesFilter = (issues: DocReviewIssue[]): {
  filteredIssues: DocReviewIssue[]
  filters: FilterState
  setType: (type: IssueNonIssueType) => void
  setSort: (criteria: SortCriteria) => void
  setCriticalities: (criticalities: Criticality[]) => void
  setNatures: (natures: ComparativeAnalysisNature[]) => void
  setIssueTypes: (issueTypes: IssueType[]) => void
} => {
  const [filters, setFilters] = useState<FilterState>({
    sortBy: 'document-order',
    type: 'all',
    criticalities: [],
    natures: [],
    issueTypes: []
  })

  const filteredIssues = useMemo(() => {
    let result = [...issues]

    // Apply filters
    if (filters.criticalities.length > 0) {
      result = result.filter(issue => issue.criticality !== null && filters.criticalities.includes(issue.criticality))
    }
    if (filters.natures.length > 0) {
      result = result.filter(issue => issue.nature !== null && filters.natures.includes(issue.nature as ComparativeAnalysisNature))
    }
    if (filters.issueTypes.length > 0) {
      result = result.filter(issue => issue.type !== null && filters.issueTypes.includes(issue.type))
    }
    if (filters.type === 'issues') {
      result = result.filter(issue => issue.criticality !== null && issue.criticality !== 'non applicable')
    } else if (filters.type === 'non-issues') {
      result = result.filter(issue => issue.criticality === null || issue.criticality === 'non applicable')
    }

    // Apply sorting
    const criticalityOrder: Record<Criticality, number> = { 'non applicable': 0, low: 1, medium: 2, high: 3 }
    const toOrder = (criticality: Criticality | null): number => (
      criticality !== null ? criticalityOrder[criticality] : -1
    )
    const sortFromHightToLowCriticality = (result: DocReviewIssue[]): void => {
      result.sort((a, b) => {
        const orderDiff = toOrder(b.criticality) - toOrder(a.criticality)
        if (orderDiff === 0) {
          return issues.indexOf(a) - issues.indexOf(b)
        }
        return orderDiff
      })
    }
    const sortFromLowToHighCriticality = (result: DocReviewIssue[]): void => {
      result.sort((a, b) => {
        const orderDiff = toOrder(a.criticality) - toOrder(b.criticality)
        if (orderDiff === 0) {
          return issues.indexOf(a) - issues.indexOf(b)
        }
        return orderDiff
      })
    }

    switch (filters.sortBy) {
      case 'criticality-high-to-low':
        sortFromHightToLowCriticality(result)
        break
      case 'criticality-low-to-high':
        sortFromLowToHighCriticality(result)
        break
      default:
        // 'document-order' - no sorting needed as we use the original array order
        break
    }

    return result
  }, [issues, filters])

  const setSort = (sortBy: SortCriteria): void => {
    setFilters(prev => ({ ...prev, sortBy }))
  }

  const setType = (type: IssueNonIssueType): void => {
    setFilters(prev => ({ ...prev, type }))
  }

  const setCriticalities = (criticalities: Criticality[]): void => {
    setFilters(prev => ({ ...prev, criticalities }))
  }

  const setNatures = (natures: ComparativeAnalysisNature[]): void => {
    setFilters(prev => ({ ...prev, natures }))
  }

  const setIssueTypes = (issueTypes: IssueType[]): void => {
    setFilters(prev => ({ ...prev, issueTypes }))
  }

  return {
    filteredIssues,
    filters,
    setType,
    setSort,
    setCriticalities,
    setNatures,
    setIssueTypes
  }
}

export default useDocReviewIssuesFilter
