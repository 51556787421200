import { Box, Stack, styled } from '@mui/material'
import { scrollbarYFix } from 'theme/utils'

export const StyledBox = styled(Box)({
  // Shrinks to leave enough space for the compose container
  minHeight: 0,
  flexShrink: 1,
  display: 'flex',
  // Take up all the remain space available, once the compose container
  // has taken up the space it needs
  flexGrow: 1,
  overflowX: 'hidden',
  ...scrollbarYFix
})

export const StyledStack = styled(Stack)(({ theme }) => ({
  minWidth: 0,
  flexGrow: 1,
  padding: theme.spacing(1)
}))
