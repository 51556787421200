import { Box, styled, Typography } from '@mui/material'
import { scrollbarYFix } from 'theme/utils'

export const StyledContainer = styled(Box)(({ theme }) => ({
  minHeight: 0,
  flexShrink: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: theme.palette.divider
}))

export const StyledDocumentContainer = styled(Box)(({ theme }) => ({
  minHeight: 0,
  flexShrink: 1, // Always leave space for the page number footer
  height: '100%',
  position: 'relative',
  overflowX: 'auto',
  ...scrollbarYFix,

  // Add some gap between the pages
  '& .react-pdf__Document': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& .react-pdf__Page': {
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.5)'
    }
  }
}))

export const FooterContainer = styled(Box)({
  flexShrink: 0, // Prevent the footer from shrinking
  p: 1,
  borderTop: '1px solid',
  borderColor: 'divider',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  gap: 2
})

export const PageControls = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 2
})

export const PageNumberTypography = styled(Typography)({}, {
  defaultProps: {
    variant: 'caption'
  }
})
