import { Box, styled } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}))

export const FiltersContainer = styled(Box)(({ theme }) => ({
  // Make filters sticky: they will be visible at the top of the page when scrolling down
  position: 'sticky',
  top: 0,
  zIndex: 1001, // Ensure it's above floating view icons
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(2),

  // Make sure the background is solid, otherwise it would be transparent
  // and when scrolling down, the filters won't be readable because
  // of the stuff behind.
  // Use a gradient effect to make the background progressively transparent
  // at the bottom of the filters
  background: `linear-gradient(to bottom,
    ${theme.palette.background.default} 0%,
    ${theme.palette.background.default} calc(100% - 10px),
    transparent 100%
  )`
}))
