/**
 * Modified version of mui-nested-menu's NestedMenuItem
 * that has a onOpen callback. This allows us close
 * sibling sub-menus when a different sub-menu is opened,
 * and only close all sub-menus when the parent menu is closed
 * (e.g., when the user clicks outside of the menu).
 * See CHANGE comments below.
 */
import { ChevronRight } from '@mui/icons-material'
import Menu, { type MenuProps } from '@mui/material/Menu'
import { type MenuItemProps } from '@mui/material/MenuItem'
import { IconMenuItem } from 'mui-nested-menu'
import {
  type ElementType,
  type FocusEvent,
  forwardRef,
  type HTMLAttributes,
  type KeyboardEvent,
  type MouseEvent,
  type ReactNode,
  type RefAttributes,
  useImperativeHandle,
  useRef,
  useState,
  type Ref
} from 'react'

export type NestedMenuItemProps = Omit<MenuItemProps, 'button'> & {
  onOpen: () => void // CHANGE: Added the onOpen callback
  parentMenuOpen: boolean
  component?: ElementType
  label?: string
  rightIcon?: ReactNode
  leftIcon?: ReactNode
  children?: ReactNode
  className?: string
  tabIndex?: number
  disabled?: boolean
  ContainerProps?: HTMLAttributes<HTMLElement> & RefAttributes<HTMLElement | null>
  MenuProps?: Partial<Omit<MenuProps, 'children'>>
  button?: true | undefined
}

const NestedMenuItem = forwardRef<HTMLLIElement | null, NestedMenuItemProps>(function NestedMenuItem (
  props,
  ref
) {
  const {
    parentMenuOpen,
    label,
    rightIcon = <ChevronRight />,
    leftIcon = null,
    children,
    className,
    tabIndex: tabIndexProp,
    ContainerProps: ContainerPropsProp = {},
    MenuProps,
    ...MenuItemProps
  } = props

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp

  const menuItemRef = useRef<HTMLLIElement | null>(null)
  useImperativeHandle(ref, () => menuItemRef.current!) // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const containerRef = useRef<HTMLDivElement | null>(null)
  useImperativeHandle(containerRefProp as Ref<HTMLDivElement | null>, () => containerRef.current)

  const menuContainerRef = useRef<HTMLDivElement | null>(null)

  // CHANGE: Added timer to delay sub-menu opening
  const hoverTimeout = useRef<NodeJS.Timeout | null>(null)

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  const handleMouseEnter = (e: MouseEvent<HTMLElement>): void => {
    // CHANGE: Use a timer to open sub-menu only if the mouse stays
    // on the menu item for some time
    hoverTimeout.current = setTimeout(() => {
      // CHANGE: Added the onOpen callback
      props.onOpen()

      setIsSubMenuOpen(true)

      if (ContainerProps.onMouseEnter != null) {
        ContainerProps.onMouseEnter(e)
      }
    }, 200)
  }

  const handleMouseLeave = (e: MouseEvent<HTMLElement>): void => {
    // CHANGE: If we did not stay over the tag long enough,
    // clear the timeout responsible for opening the popover
    if (hoverTimeout.current !== null) clearTimeout(hoverTimeout.current)

    // CHANGE: Commented the original code to prevent sub-menus
    // from closing when the mouse leaves

    // setIsSubMenuOpen(false)

    // if (ContainerProps.onMouseLeave != null) {
    //   ContainerProps.onMouseLeave(e)
    // }
  }

  // Check if any immediate children are active
  const isSubmenuFocused = (): boolean => {
    const active = containerRef.current?.ownerDocument.activeElement ?? null
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const children = Array.from(menuContainerRef.current!.children) as HTMLElement[]
    for (const child of children) {
      if (child === active) {
        return true
      }
    }

    return false
  }

  const handleFocus = (e: FocusEvent<HTMLElement>): void => {
    if (e.target === containerRef.current) {
      setIsSubMenuOpen(true)
    }

    if (ContainerProps.onFocus != null) {
      ContainerProps.onFocus(e)
    }
  }

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.key === 'Escape') {
      return
    }

    if (isSubmenuFocused()) {
      e.stopPropagation()
    }

    const active = containerRef.current?.ownerDocument.activeElement

    if (e.key === 'ArrowLeft' && isSubmenuFocused()) {
      containerRef.current?.focus()
    }

    if (e.key === 'ArrowRight' && e.target === containerRef.current && e.target === active) {
      const firstChild = menuContainerRef.current?.children[0] as HTMLDivElement
      firstChild?.focus()
    }
  }

  const open = isSubMenuOpen && parentMenuOpen

  // Root element must have a `tabIndex` attribute for keyboard navigation
  let tabIndex
  if (!(props.disabled ?? false)) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1
  }

  return (
        <div
            className='nested-menu-item'
            {...ContainerProps}
            ref={containerRef}
            onFocus={handleFocus}
            tabIndex={tabIndex}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onKeyDown={handleKeyDown}
        >
            <IconMenuItem
                MenuItemProps={MenuItemProps}
                className={className}
                ref={menuItemRef}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                label={label}
            />

            <Menu
                // Set pointer events to 'none' to prevent the invisible Popover div
                // from capturing events for clicks and hovers
                style={{ pointerEvents: 'none' }}
                anchorEl={menuItemRef.current}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'top'
                }}
                transformOrigin={{
                  horizontal: 'left',
                  vertical: 'top'
                }}
                open={open}
                autoFocus={false}
                disableAutoFocus
                disableEnforceFocus
                onClose={() => {
                  setIsSubMenuOpen(false)
                }}
                {...MenuProps}
            >
                <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
                    {children}
                </div>
            </Menu>
        </div>
  )
})

NestedMenuItem.displayName = 'NestedMenuItem'
export { NestedMenuItem }
