import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import Icon from 'components/icons/feather'
import LocaleSelect from 'components/left-pane/LocaleSelect'

export const LanguageMenuItem: React.FC = () => {
  return (
    <MenuItem>
      <ListItemIcon>
        <Icon.Globe />
      </ListItemIcon>
      <ListItemText>
        <LocaleSelect />
      </ListItemText>
    </MenuItem>
  )
}

export default LanguageMenuItem
