import { Help } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material"
import { openHelpPage } from "features/help"
import { useIntl } from "react-intl"

export const HelpMenuItem = (): JSX.Element => {
  const intl = useIntl()
  const helpLabel = intl.formatMessage({
    id: 'app.help-button.label',
    defaultMessage: 'Help'
  })

  return (
    <MenuItem key={'help'} onClick={() => { openHelpPage(intl.locale) }}>
      <ListItemIcon>
        <Help />
      </ListItemIcon>
      <ListItemText>
        {helpLabel}
      </ListItemText>
    </MenuItem>
  )
}

export default HelpMenuItem
