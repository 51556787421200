import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Icon from 'components/icons/feather'
import { DEFAULT_ICON_SIZE_IN_PX } from 'components/icons/types'
import type * as React from 'react'
import { useIntl } from 'react-intl'
import { getSidepaneWidthInPx } from './types'
import { useEffect, useState } from 'react'
import useSidepanes from './SidepanesContext'

interface Props {
  open: boolean
  handleDrawerOpen: () => void
  handleDrawerClose: () => void
  anchor: 'left' | 'right'
}

// Horizontal padding of the icon button, to readjust if we customize the theme!
const ICON_BUTTON_PADDING_IN_PX = 8

export const ICON_BUTTON_WIDTH_IN_PX = DEFAULT_ICON_SIZE_IN_PX + ICON_BUTTON_PADDING_IN_PX * 2

/**
 * Renders a button that opens or closes a side pane.
 */
const SidepaneToggleButton: React.FC<Props> = (
  { open, handleDrawerOpen, handleDrawerClose, anchor }: Props
): JSX.Element => {
  const intl = useIntl()
  const [openedXOffset, setOpenedXOffset] = useState<number>(ICON_BUTTON_WIDTH_IN_PX)

  // Get the relevant pane state once
  const { leftPane, rightPane } = useSidepanes()
  const pane = anchor === 'left' ? leftPane : rightPane

  useEffect(() => {
    const openedXOffset = getSidepaneWidthInPx(pane) - ICON_BUTTON_WIDTH_IN_PX
    setOpenedXOffset(openedXOffset)
  }, [pane])

  const closedXOffset = 0
  const xOffset = open ? openedXOffset : closedXOffset

  const tooltip = (
    open
      ? intl.formatMessage({
        id: 'app.close-side-panel',
        defaultMessage: 'Close side panel'
      })
      : intl.formatMessage({
        id: 'app.open-side-panel',
        defaultMessage: 'Open side panel'
      })
  )

  return <Box
      className='drawer-pane-button'
      sx={{
        ...(!open
          ? {
              // When sidepane is closed, the toggle button is floating over the top-left or top-right corner
              position: 'absolute',
              top: '0%',
              ...(anchor === 'left' ? { left: xOffset } : { right: xOffset }),
              zIndex: 1201,
              // Needed to be able to see the button when the sidepane is closed
              visibility: 'visible !important',
              display: 'block !important'
            }
          : {
              // When sidepane is open, the toggle button is inside the sidepane's header
              display: 'block'
            }
        )
      }}
    >
      <Tooltip title={tooltip}>
        <IconButton
          onClick={() => { open ? handleDrawerClose() : handleDrawerOpen() }}
          sx={{
            // Instead of the brand color (default), use the text color for the icon button,
            // and adapt the hover color accordingly. This is a bit more subtle as we don't
            // want it to be too intrusive.
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText'
            }
          }}
        >
          {open !== (anchor === 'left')
            ? <Icon.Sidebar />
            : <Icon.Sidebar style={{ transform: 'rotate(180deg)' }} />}
        </IconButton>
      </Tooltip>
    </Box>
}

export default SidepaneToggleButton
