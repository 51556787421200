import { type ReactNode, createContext, useContext, useState } from 'react'

interface ErrorModalContextType {
  isOpen: boolean
  showError: () => void
  hideError: () => void
}

const ErrorModalContext = createContext<ErrorModalContextType | null>(null)

interface Props {
  children: ReactNode
}

export const ErrorModalProvider: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const showError = (): void => {
    setIsOpen(true)
  }

  const hideError = (): void => {
    setIsOpen(false)
  }

  return (
    <ErrorModalContext.Provider value={{ isOpen, showError, hideError }}>
      {children}
    </ErrorModalContext.Provider>
  )
}

export const useErrorModal = (): ErrorModalContextType => {
  const context = useContext(ErrorModalContext)
  if (context === null) {
    throw new Error('useErrorModal must be used within an ErrorModalProvider')
  }
  return context
}

export default useErrorModal
