import { Box, type Palette, styled, type SxProps, type Theme } from '@mui/material'
import { type Message, Role } from 'features/messages/types'

export const containerSx = (msg: Message, palette: Palette, containerWidth: number): SxProps<Theme> => ({
  minWidth: 0,
  flexShrink: 1,
  display: 'flex',
  // When not enough width, show the avatar on top of the message content.
  flexDirection: containerWidth < 800 ? 'column' : 'row',
  paddingY: 2,
  // Space out the avatar and the message content
  gap: containerWidth < 800 ? 1 : 2,
  textAlign: 'left',
  alignItems: 'flex-start',
  ...(msg.role === Role.ASSISTANT && {
    alignSelf: 'flex-start',
    bgcolor: 'background.default',
    borderRadius: 0,
    maxWidth: containerWidth < 800 ? '100%' : '95%'
  }),
  ...(msg.role === Role.USER && {
    // Need to add horizontal padding because of the solid background,
    // to not have the text touch the edge of the background.
    paddingX: containerWidth < 800 ? 1 : 2,
    alignSelf: 'flex-end',
    bgcolor: palette.mode === 'light' ? 'grey.200' : 'grey.800',
    borderRadius: '8px 0 8px 8px',
    maxWidth: containerWidth < 800 ? '100%' : '80%'
  })
})

export const MessageContentContainer = styled(Box)(({ theme }) => ({
  minWidth: 0,
  flexShrink: 1,
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('xs')]: {
    alignSelf: 'flex-start'
  },
  [theme.breakpoints.up('sm')]: {
    alignSelf: 'center'
  },
  gap: theme.spacing(1)
}))

export const FeedbackButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignSelf: 'flex-start'
}))

export const AvatarContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    scale: '0.75'
  },
  [theme.breakpoints.up('md')]: {
    scale: '1'
  }
}))

export const TopSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}))

export const BottomSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5)
}))

export const Spacer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1)
}))
