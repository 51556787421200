import { Box, styled } from '@mui/material'

export const outerContainerSx = {
  minWidth: 0,
  flexShrink: 1,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: { xs: 0.5, sm: 1 }
}

export const InnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center'
}))
