import { AuthContext, User } from 'context/AuthContext'

const mockUserData = new User({
  id: 'aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee',
  email: 'test@test.com',
  firstName: 'Test',
  lastName: 'User',
  fullName: 'Test User',
  orgName: 'Test Org',
  title: 'Test Title',
  role: 'Test Role',
  termsAccepted: true
}, {
  sub: 'aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee',
  email: 'test@test.com',
  email_verified: true,
  name: 'Test User'
})

interface Props {
  children?: React.ReactNode
}

/**
 * Mock AuthContext.Provider for testing
 */
export const MockAuthContextProvider = ({ children }: Props): JSX.Element => (
  <AuthContext.Provider value={mockUserData}>
    {children}
  </AuthContext.Provider>
)

export default MockAuthContextProvider
