import { useCallback, useEffect, useRef, useState } from 'react'

export const useCurrentPage = (numPages: number | undefined): number => {
  const [visiblePage, setVisiblePage] = useState<number>(1)
  const observers = useRef<IntersectionObserver[]>([])

  const setupObservers = useCallback(() => {
    // Cleanup previous observers
    observers.current.forEach(observer => { observer.disconnect() })
    observers.current = []

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const pageNum = parseInt(entry.target.getAttribute('data-page-number') ?? '1')
          setVisiblePage(pageNum)
        }
      })
    }

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Consider page visible when 50% is in view
    }

    // Create new observer
    const observer = new IntersectionObserver(callback, options)
    observers.current.push(observer)

    // Observe all page elements
    document.querySelectorAll('.react-pdf__Page').forEach(pageElement => {
      observer.observe(pageElement)
    })

    return () => {
      observers.current.forEach(observer => { observer.disconnect() })
      observers.current = []
    }
  }, [])

  useEffect(() => {
    // Wait a bit for the PDF pages to render
    const timeoutId = setTimeout(setupObservers, 1000)
    return () => { clearTimeout(timeoutId) }
  }, [numPages, setupObservers])

  useEffect(() => {
    console.log('Visible page changed to:', visiblePage)
  }, [visiblePage])

  return visiblePage
}

export default useCurrentPage
