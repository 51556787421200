import { Box, type ButtonProps, type MenuProps, styled } from '@mui/material'
import { selectIconStyle } from 'theme'

export const TaskSelectContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1)
}))

export const MenuItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}))

export const menuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  // Add some space between the button and the menu
  sx: {
    transform: 'translate(0px, -8px)'
  }
}

export const buttonProps = (colorMode: 'light' | 'dark'): Partial<ButtonProps> => ({
  variant: 'outlined',
  sx: {
    // Use the same border color as the Select component
    borderColor: 'divider',
    // Adjust padding to match Select components
    paddingY: '7.5px',
    // Make the chevron icon (.MuiButton-endIcon) at the end less visible
    '& .MuiButton-endIcon .MuiSvgIcon-root': selectIconStyle(colorMode)
  }
})
