import { Typography } from '@mui/material'
import { useIntl } from 'react-intl'

interface Props {
  explanation: string
}

const ExplanationDisplay = ({ explanation }: Props): JSX.Element => {
  const intl = useIntl()

  return (
    <Typography className='explanation-display'>
      {intl.formatMessage({
        id: 'app.doc-review.explanation'
      }, {
        explanation,
        bold: (chunks: any) => <strong>{chunks}</strong>
      })}
    </Typography>
  )
}

export default ExplanationDisplay
