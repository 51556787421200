import { Box, styled } from '@mui/material'

export const StyledSelectContainer = styled(Box)(() => ({
  minWidth: 0,
  flexShrink: 1,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  flexDirection: 'row'
}))

// For some reason, could not turn this into a styled component,
// so we have to set the sx directly on the Select component
export const documentSelectSx = {
  minWidth: '200px',
  maxWidth: '400px'
}
