import { Box, styled } from '@mui/material'

export const SelectSecondDocumentContainer = styled(Box)(({ theme }) => ({
  // Make sure it shrinks to give space to the primary document view
  minWidth: 0,
  flexShrink: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  overflow: 'hidden'
}))
