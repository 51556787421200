import ConfidentialityToggleSwitch from 'features/send-message/components/confidentiality-toggle-switch/ConfidentialityToggleSwitch'
import { type FC } from 'react'
import { useIntl } from 'react-intl'
import DisabledOverlay from './DisabledOverlay'
import { ComponentWrapper, TipContainer, TipDescription } from './TipContainer.style'

export const TipConfidentialityToggleSwitch: FC = (): JSX.Element => {
  const intl = useIntl()
  return (
    <TipContainer>
      <ComponentWrapper>
        <DisabledOverlay>
          <ConfidentialityToggleSwitch active={true} />
        </DisabledOverlay>
      </ComponentWrapper>
      <TipDescription>
        {
          intl.formatMessage({
            id: 'app.chat.splash-screen.tips.tip-anon-switch'
          })
        }
      </TipDescription>
    </TipContainer>
  )
}

export default TipConfidentialityToggleSwitch
