import { type InputProps, type Theme } from '@mui/material'

export const inputProps = (disabled: boolean, theme: Theme): InputProps => {
  return {
    disableUnderline: true,
    sx: {
      '& .MuiOutlinedInput-root': {
        padding: theme.spacing(1)
      },
      '& textarea': {
        width: 'calc(100% - 10px)',
        padding: theme.spacing(1),
        ...(disabled && {
          height: '0px !important'
        })
      }
    }
  }
}
