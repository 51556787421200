import AddIcon from '@mui/icons-material/Add'
import {
  Divider,
  Menu,
  type PopoverPosition
} from '@mui/material'
import { type EntityType } from 'features/anonymization/types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useGlobals } from '../../../context/GlobalsContext'
import { AddAliasIcon } from '../AliasIcons'
import EntitySelectMenuItem from '../selectors/EntitySelectMenuItem'
import MenuItemLabelAndActionIcon from '../selectors/MenuItemLabelAndIcon'
import { StyledMenuItem } from '../selectors/Selectors.styles'

// const defaultEntityType = 'MISC'

interface Props {
  open: boolean
  anchorPosition: PopoverPosition | undefined
  entityTypeToActiveEntitiesCleartext: Map<string, string[]>
  onClose: () => void
  onAddExactMatch?: (entityType: EntityType, asAliasOf?: string) => void
}

const TextSelectPopover: React.FC<Props> = ({
  open,
  anchorPosition,
  entityTypeToActiveEntitiesCleartext,
  onClose,
  onAddExactMatch
}: Props) => {
  const intl = useIntl()

  const [openedLevel1MenuIdx, setOpenedLevel1MenuIdx] = useState<number | null>(null)
  const [openedLevel2MenuIdx, setOpenedLevel2MenuIdx] = useState<number | null>(null)

  const globals = useGlobals()
  if (globals === null) return <></>

  const handleSelectEntityCategory = (category: string): void => {
    if (onAddExactMatch === undefined) return
    if (category === '') return

    onAddExactMatch(category, undefined)
    onClose()
  }

  const handleSelectEntityAlias = (cleartext: string, entityType: EntityType): void => {
    if (onAddExactMatch === undefined) return

    // Will create an anonymization rule for the selected text,
    // as well as an alias rule for the selected entity's cleartext
    onAddExactMatch(entityType, cleartext)
    onClose()
  }

  const listAliasOptions = (entityType: EntityType): JSX.Element[] => {
    const aliasCleartexts: string[] = entityTypeToActiveEntitiesCleartext.get(entityType) ?? []

    if (aliasCleartexts.length === 0) return []

    return [
      <Divider key={1} />,
      <StyledMenuItem
        key={2}
        data-value={''}
        onClick={() => {} }
        disabled
      >
        {
          intl.formatMessage({
            id: 'app.add-entity-type-select.alias-of',
            defaultMessage: 'Alias of'
          })
        }
      </StyledMenuItem>,
      ...aliasCleartexts.map((cleartext, idx3) => (
        <StyledMenuItem
          key={idx3 + 3}
          onClick={() => {
            handleSelectEntityAlias(cleartext, entityType)
          } }
          sx={{ gap: 1 }}
        >
          <MenuItemLabelAndActionIcon
            label={`${cleartext}`}
            actionIcon={<AddAliasIcon />}
            openDirection={openDirection}
          />
        </StyledMenuItem>
      ))
    ]
  }

  const openDirection: 'left' | 'right' = (
    (anchorPosition?.left ?? 0) > (window.innerWidth / 2) ? 'left' : 'right'
  )

  return (
    <Menu
    anchorPosition={anchorPosition}
    anchorReference="anchorPosition"
    open={open}
    onClose={onClose ?? (() => {})}
    >
      {
        [
          // // First, show the quick options to create a new entity with the MISC category,
          // // or a variant from an existing MISC entity
          // // (This will later be replaced by whatever entity category was recognized by the model)
          // <MenuItem
          //   key={0}
          //   onClick={() => {
          //     handleSelectEntityCategory(defaultEntityType)
          //   }}
          // >
          //   {`Add new ${defaultEntityType}`}
          // </MenuItem>,
          // <NestedMenuItem
          //   key={1}
          //   label={`Add variant of ${defaultEntityType}`}
          //   parentMenuOpen={open}
          // >
          //   {(entityTypeToActiveEntitiesCleartext.get(defaultEntityType) ?? []).map((cleartext, idx3) => (
          //     <MenuItem
          //       key={idx3 + 1}
          //       onClick={() => {
          //         handleSelectEntityAlias(cleartext)
          //       } }
          //     >
          //       {`${cleartext}`}
          //     </MenuItem>
          //   ))}
          // </NestedMenuItem>,

          // <Divider key={1} />,

          // Then, show the full menu
          ...Array.from(globals.entityTypesByGroup).map(([group, entityTypeInfos], idx) => (
            // First level of menu is entity groups
            // Make a sub-menu for each or them,
            // except for those that we want to "flatten".
            (globals.flattenedGroups.includes(group)
              ? entityTypeInfos.map((entityTypeInfo, idx2) => (
                <StyledMenuItem
                  key={(idx + 1) * 100 + idx2}
                  data-value={'sub-menu-item'}
                  onClick={() => {
                    handleSelectEntityCategory(entityTypeInfo.entityType)
                  } }
                  onMouseEnter={() => {
                    setOpenedLevel1MenuIdx(idx)
                    setOpenedLevel2MenuIdx(null)
                  }}
                >
                  <MenuItemLabelAndActionIcon
                    label={
                      intl.formatMessage({
                        id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                        defaultMessage: entityTypeInfo.prettyName
                      })
                    }
                    actionIcon={<AddIcon />}
                    openDirection={openDirection}
                  />
                </StyledMenuItem>
              ))
              : [
                <EntitySelectMenuItem
                  key={(idx + 1) * 100}
                  label={
                    intl.formatMessage({
                      id: `app.entity-group.${group}`,
                      defaultMessage: group
                    })
                  }
                  openDirection={openDirection}
                  open={idx === openedLevel1MenuIdx}
                  onOpen={() => {
                    setOpenedLevel1MenuIdx(idx)
                    setOpenedLevel2MenuIdx(null)
                  }}
                >
                  {entityTypeInfos.map((entityTypeInfo, idx2) => (
                    // Second level of menu is entity types.
                    // If aliases are not enabled for this entity type,
                    // then clicking on the entry should select it.
                    !entityTypeInfo.aliasesEnabled
                      ? (
                        <StyledMenuItem
                          key={(idx + 1) * 100 + idx2}
                          data-value={'sub-menu-item'}
                          onClick={() => {
                            handleSelectEntityCategory(entityTypeInfo.entityType)
                          } }
                        >
                          <MenuItemLabelAndActionIcon
                            label={
                              intl.formatMessage({
                                id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                                defaultMessage: entityTypeInfo.prettyName
                              })
                            }
                            actionIcon={<AddIcon />}
                            openDirection={openDirection}
                          />
                        </StyledMenuItem>
                        )
                      : (
                        // Otherwise, make it a sub-menu
                        <EntitySelectMenuItem
                          key={(idx + 1) * 100 + idx2}
                          label={
                            intl.formatMessage({
                              id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                              defaultMessage: entityTypeInfo.prettyName
                            })
                          }
                          openDirection={openDirection}
                          open={idx2 === openedLevel2MenuIdx}
                          onOpen={() => { setOpenedLevel2MenuIdx(idx2) }}
                        >
                          {/* Either add as a new entity... */}
                          <StyledMenuItem
                            key={0}
                            data-value={'sub-menu-item'}
                            onClick={() => {
                              handleSelectEntityCategory(entityTypeInfo.entityType)
                            }}
                          >
                            <MenuItemLabelAndActionIcon
                              label={
                                intl.formatMessage({
                                  id: 'app.add-entity-type-select.add-new-entity',
                                  defaultMessage: 'New'
                                })
                              }
                              actionIcon={<AddIcon />}
                              openDirection={openDirection}
                            />
                          </StyledMenuItem>

                          {/* ...or as an alias of an existing one */}
                          {entityTypeInfo.aliasesEnabled && listAliasOptions(entityTypeInfo.entityType)}
                        </EntitySelectMenuItem>
                        )
                  ))}
                </EntitySelectMenuItem>
                ]
            )
          )).flat()
        ]
      }
    </Menu>
  )
}

export default TextSelectPopover
