import { Box } from '@mui/material'
import Chat from 'components/central-pane/chat/Chat'
import { Compose } from 'components/central-pane/compose/Compose'
import TitleBar from 'components/central-pane/title-bar/TitleBar'
import { LeftPane } from 'components/left-pane/LeftPane'
import { PdfSearchProviders } from 'components/PdfViewer'
import ReviewModal from 'components/review-modal/ReviewModal/ReviewModal'
import { CentralPane, SidepanesProvider } from 'components/sidepanes'
import DocumentPanel from 'features/documents/components/DocumentPanel'
import DocumentPanelProvider from 'features/documents/providers/DocumentPanelProvider'
import { useChatMessages } from 'features/messages/hooks/useChatMessages'
import { useSession } from 'features/sessions'
import type React from 'react'
import { useParams } from 'react-router-dom'

const HomePage = (): JSX.Element => {
  const { sessionId } = useParams<string>()

  const {
    messages,
    input,
    attachmentIds,
    existingEntities,
    processingInput,
    reviewModalOpen,
    reviewModalInitResponse,
    onSubmitInput,
    onReviewSubmitted,
    onReviewSkipped,
    onReviewCancelled,
    onCorrectionSubmitted,
    onFeedbackSubmitted
  } = useChatMessages({ sessionId })

  const { selectedSession: session } = useSession()

  const withProviders = (children: React.ReactNode): JSX.Element => (
    <SidepanesProvider>
      <PdfSearchProviders>
        <DocumentPanelProvider>
          {children}
        </DocumentPanelProvider>
      </PdfSearchProviders>
    </SidepanesProvider>
  )

  return withProviders(
    <Box className="App" sx={{
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      minWidth: 0,
      overflow: 'hidden'
    }}>
      {/* Left pane that contains the user settings and the sessions list */}
      <LeftPane />

      {/* Central pane displaying the chat and compose */}
      <CentralPane>
        <TitleBar />
        {session !== null && (
          <Chat
            session={session}
            messages={messages}
            onCorrectionSubmitted={(idx, correction) => { onCorrectionSubmitted(session.id, idx, correction) }}
            onFeedbackSubmitted={(idx, feedback) => { onFeedbackSubmitted(session.id, idx, feedback) }} />
        )}
        {sessionId != null && (
          <Compose
            initInput={input}
            processing={processingInput}
            onSubmit={onSubmitInput}
          />
        )}
      </CentralPane>

      {/* Right pane: Document panel */}
      <DocumentPanel />

      {reviewModalInitResponse !== undefined && <ReviewModal
        open={reviewModalOpen}
        input={input}
        attachmentIds={attachmentIds}
        initExistingEntities={existingEntities}
        initResponse={reviewModalInitResponse}
        onClose={onReviewCancelled}
        onSkip={onReviewSkipped}
        onSubmit={onReviewSubmitted}
      />}
    </Box>
  )
}

export default HomePage
