import { type Session } from 'features/sessions'
import { useSessions } from 'features/sessions/api/getSessions'
import { type ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCreateSession } from 'features/sessions/api/createSession'

export interface SessionContextType {
  selectedSessionId: string | null
  selectedSession: Session | null
  changeSelectedSession: (sessionId: string | null) => void
}

export const SessionContext = createContext<SessionContextType>({
  selectedSessionId: null,
  selectedSession: null,
  changeSelectedSession: (sessionId: string | null) => {
    console.error('No session provider!')
  }
})

interface Props {
  children: ReactNode
}

export const SessionProvider = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate()
  const { isPending, isError, data, error } = useSessions()
  const { mutate: createSession } = useCreateSession()
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null)
  const [selectedSession, setSelectedSession] = useState<Session | null>(null)

  // Update the selectedSessionId when the sessions are fetched,
  // or check if the selected session still exists and update it if needed
  useEffect(() => {
    console.debug('> SessionProvider [isPending, isError, data, selectedSessionId]', isPending, isError, data, selectedSessionId)

    // If we are still fetching the sessions, or if there was an error, do nothing
    if (isPending) {
      console.debug('Still fetching sessions...')
      return
    }
    if (isError) {
      console.error('Error fetching sessions:', error)
      return
    }
    if (data === undefined) {
      console.warn('Sessions data is undefined')
      return
    }

    // If there are no sessions, create a new one automatically
    if (data.length === 0) {
      console.debug('No sessions found, creating a new one automatically')
      createSession({})
      return
    }

    const firstSession = data[0]

    // If no selection is selected, select the first one
    if (selectedSessionId === null) {
      console.debug('Selecting the first session ID')
      setSelectedSessionId(firstSession.id)
      return
    }

    // If the selected session still exists, update it with the latest data
    const updatedSession = data.find((s) => s.id === selectedSessionId)
    if (updatedSession !== undefined) {
      console.debug(`Update selected session (${updatedSession.title})`)
      setSelectedSession(updatedSession)
      return
    }

    // Otherwise, if our selected session has been removed, deselect it
    // and select the next one (chronologically)
    const newerSession = selectedSession !== null ? data.find((s) => (s.createdAt ?? '') > (selectedSession.createdAt ?? '')) : undefined
    if (newerSession !== undefined) {
      console.debug(`Select newer session ID ${newerSession.id} (${newerSession.title}) instead of the removed one`)
      setSelectedSessionId(newerSession.id)
      return
    }

    // If there is no newer session, default to the first session
    console.debug('No newer session found, selecting the first session instead')
    setSelectedSessionId(firstSession.id)
  }, [isPending, isError, data, selectedSessionId])

  // Update the selectedSession when the selectedSessionId changes
  useEffect(() => {
    console.debug('> SessionProvider [selectedSessionId]')

    // Handle cases where the selected session is not valid
    if (selectedSessionId === null) {
      console.debug('Deselecting session')
      setSelectedSession(null)
      return
    }
    if (data === undefined) {
      console.error('Sessions data is undefined')
      setSelectedSession(null)
      return
    }
    if (data.length === 0) {
      console.warn('No sessions to select from')
      setSelectedSession(null)
      return
    }

    // Find the session with the given ID
    const newSession = data.find((s) => s.id === selectedSessionId)
    if (newSession !== undefined) {
      console.debug(`Selecting session that matches ID ${newSession.id} (${newSession.title})`)
      setSelectedSession(newSession)
      return
    }
    // If the session doesn't exist, select the first session if any
    console.warn(`Session ${selectedSessionId} not found, selecting the first session instead`)
    setSelectedSession(data[0])
  }, [selectedSessionId])

  // Update the URL when the selected session changes,
  // to allow for direct linking to a session
  useEffect(() => {
    console.debug('> SessionProvider [selectedSession]')
    if (selectedSession !== null && selectedSession !== undefined) {
      console.debug('Navigate to session:', selectedSession.id)
      navigate(`/${selectedSession.id}`)
    }
  }, [selectedSession])

  if (isPending) {
    return <></>
  }

  if (isError) {
    console.error(error)
    throw Error('Error fetching sessions!')
  }

  if (selectedSession === undefined) {
    return <></>
  }

  const changeSelectedSession = (sessionId: string | null): void => {
    setSelectedSessionId(sessionId)
  }

  return (
    <SessionContext.Provider value={{
      selectedSessionId,
      selectedSession,
      changeSelectedSession
    }}>
      {children}
    </SessionContext.Provider>
  )
}

export function useSession (): SessionContextType {
  return useContext(SessionContext)
}
