import { Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material'
import Icon, { DEFAULT_ICON_SIZE_IN_PX } from 'components/icons/feather'
import type React from 'react'
import { useIntl } from 'react-intl'

interface SendButtonProps {
  onClick: () => void
  mayReview: boolean
  disabled: boolean
  iconOnly?: boolean
  processing?: boolean
  /**
   * A message explaining why the input is not valid, to be displayed in the tooltip.
   */
  validationMessage?: string
}

const SendButton: React.FC<SendButtonProps> = ({
  onClick,
  mayReview,
  disabled,
  iconOnly = false,
  processing = false,
  validationMessage = ''
}) => {
  const intl = useIntl()

  // Define all possible labels and tooltips
  const label = intl.formatMessage({
    id: `app.send-button.label.${mayReview ? 'review' : 'send'}`
  })
  const processingLabel = intl.formatMessage({
    id: 'app.send-button.label.processing'
  })
  const actionTooltip = (
    mayReview
      ? intl.formatMessage({
        id: 'app.send-button.tooltip.send-with-review'
      })
      : intl.formatMessage({
        id: 'app.send-button.tooltip.send-directly'
      })
  )
  const disabledTooltip = intl.formatMessage({
    id: 'app.send-button.tooltip.disabled'
  })
  const shortcutTooltip = intl.formatMessage({
    id: 'app.send-button.tooltip.send-shortcut'
  })
  // Format a validation message as a tooltip message when the button is disabled,
  // e.g. "Can't send message : Wait for uploads to complete first"
  const validationMessageTranslated = (
    validationMessage !== ''
      ? intl.formatMessage({
        id: 'app.send-button.tooltip.validation-message.Can\'t send message',
        defaultMessage: 'Can\'t send message'
      }) + ' : ' + intl.formatMessage({
        id: `app.send-button.tooltip.validation-message.${validationMessage}`,
        defaultMessage: validationMessage
      })
      : ''
  )
  // Determine which tooltip to display
  const tooltip = disabled ? (validationMessageTranslated !== '' ? validationMessageTranslated : disabledTooltip) : `${actionTooltip}\n${shortcutTooltip}`

  const processingTooltip = intl.formatMessage({
    id: 'app.send-button.tooltip.processing'
  })

  const progress = <CircularProgress
    size={DEFAULT_ICON_SIZE_IN_PX}
    sx={{
      color: {
        // Adapt the color to the button variant (contained or icon) and state (disabled or not)
        xs: 'primary.main',
        sm: disabled ? 'grey.400' : 'primary.contrastText'
      }
    }}
  />

  return (
    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{processing ? processingTooltip : tooltip}</span>}>
      <Box className="send-icon-button-container">
        {/* Show the button with a label on desktop */}
        {iconOnly
          ? <Box className={`send-button-container ${iconOnly ? 'send-button-container-icon-only' : ''}`}>
            <IconButton disabled={processing || disabled} onClick={onClick}>
              {processing ? progress : <Icon.Send />}
            </IconButton>
          </Box>
          : <Box>
            <Button
              variant="contained"
              disabled={processing || disabled}
              endIcon={!processing && <Icon.Send />}
              onClick={onClick}
              sx={{
                '&.MuiButton-root': {
                  transition: 'background-color 0.3s'
                }
              }}
            >
              {processing ? processingLabel : label}
            </Button>
          </Box>
        }
      </Box>
    </Tooltip>
  )
}

export default SendButton
