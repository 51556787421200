import { Box, styled } from '@mui/material'

export const DocumentWorkspaceContainer = styled(Box)(({ theme }) => ({
  height: '100%', // Let it expand to fill all the document panel
  minHeight: 0,
  flexShrink: 1,
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  overflow: 'hidden'
}))

export const VIEWS_GAP_IN_PX = 2

export const DocumentViewsContainer = styled(Box)(({ theme }) => ({
  minHeight: 0,
  flexShrink: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  overflow: 'hidden',
  // Put some small gap between the two views and the divider,
  // otherwise the divider is too close to the views.
  gap: `${VIEWS_GAP_IN_PX}px`
}))
