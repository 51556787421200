import { useEffect, useRef, useState } from 'react'
import { Document, Page } from 'react-pdf'
import PageInput from './PageInput'
import { FooterContainer, PageControls, PageNumberTypography, StyledContainer, StyledDocumentContainer } from './PdfViewer.styles'
import { type PdfViewerId } from './types'
import useCurrentPage from './useCurrentPage'
import { usePdfSearch } from './usePdfSearch'
import ZoomControls from './ZoomControls'

interface Props {
  /**
   * Unique identifier for this PDF viewer instance
   */
  viewerId: PdfViewerId
  /**
   * The file to view. Can be a File object or a string URL.
   */
  file: File | string
  /**
   * The width of the PDF viewer in pixels.
   */
  width?: number
  /**
   * Callback fired when the document is fully loaded and ready for searching
   */
  onLoad?: () => void
}

export const PdfViewer: React.FC<Props> = ({ viewerId, file, width = 300, onLoad }: Props): JSX.Element => {
  // Keep track of the number of pages in the document, and which one is currently visible.
  const [numPages, setNumPages] = useState<number>()
  const currentPage = useCurrentPage(numPages)

  // Width changes is a source of issues, as it can cause a scroll to the top of the first page.
  // To work-around this, we detect when width starts changing and store what the current page is,
  // so that we can restore it after the width change is complete.
  const prevWidth = useRef(width)
  const lastCurrentPage = useRef<number>(1)

  // Hook to search for text inside the document
  const { containerRef } = usePdfSearch(viewerId)

  // Hook to manage the scale of the rendered pages, to zoom in and out
  const [scale, setScale] = useState<number>(1)

  // When the width changes, store the current page number to be able to restore it afterwards
  useEffect(() => {
    if (prevWidth.current !== width) {
      lastCurrentPage.current = currentPage
      console.log('Storing page before width change:', lastCurrentPage.current)
    }
    prevWidth.current = width
  }, [width, currentPage])

  // When the width changes, restore the page to what was stored before the change
  useEffect(() => {
    if (lastCurrentPage.current !== currentPage) {
      console.log('Restoring to page:', lastCurrentPage.current)
      scrollToPage(lastCurrentPage.current)
    }
  }, [width])

  const handlePageInputChange = (pageNumber: number): void => {
    scrollToPage(pageNumber)
  }

  const scrollToPage = (pageNumber: number): void => {
    const pageElement = document.querySelector(`.react-pdf__Page[data-page-number="${pageNumber}"]`)
    if (pageElement != null) {
      pageElement.scrollIntoView()
    }
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages)
    console.debug('PDF loaded, numPages:', numPages)
    onLoad?.()
  }

  return (
    <StyledContainer className={`pdf-viewer-container-${viewerId}`}>
      <StyledDocumentContainer ref={containerRef} className={`pdf-viewer-document-container-${viewerId}`}>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => {
            return (
              <Page
                key={`page-${index + 1}`}
                pageNumber={index + 1}
                width={width}
                scale={scale}
                // Optimize performance by capping at device's pixel ratio
                devicePixelRatio={Math.max(1, window.devicePixelRatio)}
              />
            )
          })}
        </Document>
      </StyledDocumentContainer>
      <FooterContainer>
        <PageControls>
          <PageNumberTypography>
            Page
          </PageNumberTypography>
          <PageInput onPageInputChange={handlePageInputChange} currentPage={currentPage} numPages={numPages} />
          <PageNumberTypography>
            of {numPages ?? '-'}
          </PageNumberTypography>
        </PageControls>
        <ZoomControls scale={scale} setScale={setScale} />
      </FooterContainer>
    </StyledContainer>
  )
}

export default PdfViewer
