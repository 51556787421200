import { TextField, styled } from '@mui/material'

export const StyledTextField = styled(TextField)({
  width: '4em',
  '& input': {
    padding: '4px 8px',
    textAlign: 'center'
  }
}, {
  defaultProps: {
    variant: 'outlined',
    size: 'small'
  }
})
