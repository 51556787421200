import { axiosInstance, createAuthHeaderFromToken, defaultTTL } from 'services/axios'
import { type ExportModifiedAttachmentOptions } from 'features/export-document/types'

export const checkExportModifiedAttachment = async (
  token: string,
  sessionId: string,
  messageIdx: number
): Promise<ExportModifiedAttachmentOptions> => {
  const response = await axiosInstance.options(
    `/sessions/${sessionId}/messages/${messageIdx}/export-modified-attachment`,
    {
      headers: createAuthHeaderFromToken(token),
      cache: {
        ttl: defaultTTL
      }
    }
  )
  return response.data
}

export const exportModifiedAttachment = async (
  token: string,
  sessionId: string,
  messageIdx: number
): Promise<{
  blob: Blob
  filename: string
}> => {
  const response = await axiosInstance.get(
    `/sessions/${sessionId}/messages/${messageIdx}/export-modified-attachment`,
    {
      responseType: 'blob',
      headers: createAuthHeaderFromToken(token),
      cache: {
        ttl: defaultTTL
      }
    }
  )
  const contentDisposition = response.headers.contentDisposition
  const filename = contentDisposition?.split('filename=')[1].replace(/"/g, '') ?? 'reviewed-document.docx'

  return {
    blob: response.data,
    filename
  }
}
