import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledContainer = styled(Box)(({ theme }) => ({
  // Take the full width, but only as much height as needed
  width: '100%',
  height: 'auto',
  // Center the container vertically
  marginTop: 'auto',
  marginBottom: 'auto',
  // Make the container scrollable, such that Compose stays in view
  overflowY: 'auto',
  // To position the close button
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3)
}))

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3)
}))

export const ContentItemContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2)
}))

export const CloseButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1
}))
