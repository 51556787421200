import { useGlobals } from 'context/GlobalsContext'
import getComparativeAnalysisOptions from 'features/comparative-analysis/api/getComparativeAnalysisOptions'
import { type ComparativeAnalysisOption, type ComparisonType } from 'features/comparative-analysis/types'
import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { AnalysisTaskAction } from 'features/user-input-form/types'

interface UseComparativeAnalysisOptions {
  options: ComparativeAnalysisOption
  updateOptions: (update: Partial<ComparativeAnalysisOption>) => void
  selectedComparisonType: ComparisonType | undefined
}

/**
 * Hook to get and update the options for the comparative analysis,
 * which are nested within the user input form state.
 */
const useComparativeAnalysisOptions = (): UseComparativeAnalysisOptions => {
  const globals = useGlobals()
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const options: ComparativeAnalysisOption = getComparativeAnalysisOptions(userInputForm)

  const comparisonTypes = globals.taskParams.analyze.comparativeAnalysis.comparisonTypes
  const selectedComparisonType = comparisonTypes.find((comparisonType) => comparisonType.id === options.comparisonTypeId)
  const updateOptions = (update: Partial<ComparativeAnalysisOption>): void => {
    setUserInputForm(prevForm => ({
      ...prevForm,
      taskParams: {
        action: AnalysisTaskAction.ComparativeAnalysis,
        comparativeAnalysisOptions: {
          ...options,
          ...update
        }
      }
    }))
  }

  return { options, updateOptions, selectedComparisonType }
}

export default useComparativeAnalysisOptions
