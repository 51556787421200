import { useEffect, useState } from 'react'
import { type AttachmentOrUploadListItem, type AttachmentListItem, type UploadListItem, DOC_TYPE_NOT_CERTAIN } from 'features/documents'
import { useAttachments } from './getAttachments'
import { type UUID } from 'crypto'

/**
 * Provides a consolidated list of uploaded attachments and pending uploads
 * for the current session, to be displayed in the upload modal.
 * It excludes deleted attachments.
 */
export const useListAttachmentsAndUploads = (): AttachmentOrUploadListItem[] | undefined => {
  const [autoRefetch, setAutoRefetch] = useState(false)
  const { data } = useAttachments({ withContent: false, autoRefetch })

  if (data === undefined) {
    return undefined
  }

  // We don't want to show deleted attachments in the upload modal,
  // so we don't extract them from the data.
  const { uploaded, pending } = data

  // Will refetch automatically (at regular intervals)
  // when there are pending uploads
  useEffect(() => {
    setAutoRefetch(pending.length > 0)
  }, [pending])

  // Combine the attachments and uploads into a single list:
  // - Uploads are pending and have a task ID
  // - Attachments are already uploaded and have an ID that corresponds
  //   to the task ID they were uploaded with
  // - If we find an upload and an attachment with the same ID,
  //   we disregard the upload and only show the attachment
  // - Everything is sorted by creation date

  // Create a map by ID for quick lookup
  const attachmentsById = new Map<UUID, AttachmentListItem>()
  for (const attachment of uploaded) {
    attachmentsById.set(attachment.id, attachment)
  }

  // Filter out uploads that have a corresponding attachment
  const filteredPending: UploadListItem[] = []
  for (const upload of pending) {
    if (upload.taskId === undefined || !attachmentsById.has(upload.taskId)) {
      filteredPending.push(upload)
    }
  }

  // Combine the lists and sort by creation date
  const attachmentsAndUploads: AttachmentOrUploadListItem[] = [
    ...uploaded.map((attachment: AttachmentListItem) => ({
      id: attachment.id,
      filename: attachment.filename,
      truncated: attachment.truncated,
      text: attachment.text,
      docType: attachment.docType,
      taskUri: null,
      createdAt: attachment.createdAt,
      status: undefined
    })),
    ...filteredPending.map((upload: UploadListItem) => ({
      id: upload.taskId,
      filename: upload.filename,
      truncated: false,
      text: null,
      docType: DOC_TYPE_NOT_CERTAIN,
      taskUri: upload.taskUri,
      createdAt: upload.createdAt,
      status: upload.status
    }))
  ].sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  })

  return attachmentsAndUploads
}

export default useListAttachmentsAndUploads
