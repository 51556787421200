import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ErrorModalContainer from 'components/error-modal/ErrorModalContainer'
import { ErrorModalProvider } from 'context/ErrorModalContext'
import { IntlProviderWrapper } from 'context/IntlProviderWrapper'
import type React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { ProgressBackdropContainer } from './components/progress/ProgressBackdropContainer'
import ColorModeContext from './context/ColorModeContext'
import { ProgressProvider } from './context/ProgressContext'
import './index.css'
import Root from './Root'
import { getCookies, setCookie } from './services/cookies'
import { queryClient } from './services/react-query'
import { getTheme } from './theme'

const App: React.FC = () => {
  const cookies = getCookies()
  const storedColorMode: 'light' | 'dark' | undefined = cookies.get('colorMode')
  const [colorMode, setColorMode] = useState<'light' | 'dark'>(storedColorMode ?? 'light')

  const theme = useMemo(
    () => getTheme(colorMode),
    [colorMode]
  )

  // Save color mode to cookies
  useEffect(() => {
    setCookie('colorMode', colorMode)
  }, [colorMode])

  return (
    <IntlProviderWrapper>
      <ColorModeContext.Provider value={{ colorMode, setColorMode }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorModalProvider>
            <ProgressProvider>
              <QueryClientProvider client={queryClient}>
                <Root />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
              <ProgressBackdropContainer />
            </ProgressProvider>
            <ErrorModalContainer />
          </ErrorModalProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </IntlProviderWrapper>
  )
}

export default App
