import { Box, styled } from '@mui/material'

export const TitleAndLocationContainer = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}))

export const IssueDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}))
