import { Box, styled } from '@mui/material'

export const LeftPaneContainer = styled(Box)(({ theme }) => ({
  // Hack to leave some space for the user menu that overflows from the pane header
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  overflowY: 'hidden',
  gap: theme.spacing(1)
}))

export const assistantConfigurationContainerSx = {
  gap: 1.5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}
