import { SvgIcon, type SvgIconProps, Tooltip, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { type Criticality } from '../types'

interface Props {
  criticality?: Criticality | null
  svgIconProps?: SvgIconProps
}

const CriticalityDisplay = ({ criticality, svgIconProps }: Props): JSX.Element => {
  const theme = useTheme()
  const intl = useIntl()

  const CRITICALITY_TO_COLOR: Record<Criticality, string> = {
    low: 'yellow',
    medium: 'orange',
    high: 'red',
    // non applicable is when the model was not able to determine the criticality,
    // so we use grey to indicate that it is uncertain / unknown
    'non applicable': 'grey'
  }
  const noCriticalityColor = 'green'

  const Flag = (fillColor: string, strokeColor: string): JSX.Element => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 21V3.90002C5 3.90002 5.875 3 8.5 3C11.125 3 12.875 4.8 15.5 4.8C18.125 4.8 19 3.9 19 3.9V14.7C19 14.7 18.125 15.6 15.5 15.6C12.875 15.6 11.125 13.8 8.5 13.8C5.875 13.8 5 14.7 5 14.7" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill={fillColor}/>
    </svg>
  )

  const flagColor = (
    criticality !== null && criticality !== undefined
      ? CRITICALITY_TO_COLOR[criticality]
      : noCriticalityColor
  )
  const flag = Flag(flagColor, theme.palette.text.primary)

  const tooltipText = intl.formatMessage({
    id: criticality !== null && criticality !== undefined
      ? `app.doc-review.result-type.criticality.${criticality}`
      : 'app.doc-review.result-type.no-criticality'
  })

  return (
    <Tooltip title={tooltipText}>
      <SvgIcon {...svgIconProps}>
        {flag}
      </SvgIcon>
    </Tooltip>
  )
}

export default CriticalityDisplay
