import { type AttachmentListItem } from 'features/documents'
import { type Evidence, type DocReviewIssue } from 'features/analyze/types'
import { useViewInDocument } from 'features/analyze/hooks/useViewInDocument'
import DocReviewIssueDisplay from './DocReviewIssueDisplay'

interface Props {
  idx: number
  result: DocReviewIssue
  reviewedDoc: AttachmentListItem | null
  referenceDoc: AttachmentListItem | null
}

/**
 * Container component for a single DocReviewIssue.
 */
const DocReviewIssueDisplayContainer: React.FC<Props> = ({
  idx,
  result,
  reviewedDoc,
  referenceDoc
}: Props) => {
  const { openFirstQuoteForBothDocs, openQuoteFromEvidence } = useViewInDocument(result, reviewedDoc, referenceDoc)

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean): void => {
    if (isExpanded) {
      console.debug('DocReviewIssueDisplayContainer: handleChange', isExpanded)
    }
  }

  const handleSummaryClick = (): void => {
    openFirstQuoteForBothDocs()
  }

  const handleEvidenceClick = (evidence: Evidence, doc: AttachmentListItem): void => {
    openQuoteFromEvidence(evidence, doc)
  }

  return (
    <DocReviewIssueDisplay
      idx={idx}
      result={result}
      reviewedDoc={reviewedDoc}
      referenceDoc={referenceDoc}
      onChange={handleChange}
      onSummaryClick={handleSummaryClick}
      onEvidenceClick={handleEvidenceClick}
    />
  )
}

export default DocReviewIssueDisplayContainer
