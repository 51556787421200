import AddIcon from '@mui/icons-material/Add'
import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  type SxProps,
  type Theme,
  Typography
} from '@mui/material'
import { useGlobals } from 'context/GlobalsContext'
import { type EntityType } from 'features/anonymization/types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { AddAliasIcon } from '../AliasIcons'
import EntitySelectMenuItem from './EntitySelectMenuItem'
import MenuItemLabelAndActionIcon from './MenuItemLabelAndIcon'
import { EntityLabelTypography, StyledMenuItem } from './Selectors.styles'

const defaultOpenDirection = 'right'

interface Props {
  open: boolean
  onOpen?: () => void
  onClose?: () => void
  value: string
  openDirection?: 'left' | 'right'
  disabled?: boolean
  cleartext: string
  entityTypeToActiveEntitiesCleartext: Map<EntityType, string[]>
  onAddExactMatch?: (entityType: EntityType, asAliasOf?: string) => void
  sx?: SxProps<Theme>
}

/**
 * Component to select an entity type, or existing entity
 * for which to create an alias of.
 */
const EntityTypeOrAliasSelector: React.FC<Props> = ({
  open,
  onOpen,
  onClose,
  value,
  openDirection,
  disabled,
  cleartext,
  entityTypeToActiveEntitiesCleartext,
  onAddExactMatch,
  sx
}: Props) => {
  const intl = useIntl()
  const [openedLevel1MenuIdx, setOpenedLevel1MenuIdx] = useState<number | null>(null)
  const [openedLevel2MenuIdx, setOpenedLevel2MenuIdx] = useState<number | null>(null)

  const globals = useGlobals()
  if (globals === null) return <></>

  const handleSelect = (entityType: EntityType, asAliasOf?: string): void => {
    if (onAddExactMatch === undefined) return

    // Will create an anonymization rule for the selected text,
    // as well as an alias rule for the selected entity's cleartext
    onAddExactMatch(entityType, asAliasOf)

    if (onClose !== undefined) onClose()
  }

  const listAliasOptions = (entityType: EntityType): JSX.Element[] => {
    const aliasCleartexts: string[] = (entityTypeToActiveEntitiesCleartext.get(entityType) ?? [])
      .filter((aliasCleartext) => aliasCleartext !== cleartext)

    if (aliasCleartexts.length === 0) return []

    return [
      <Divider key={1} />,
      // "Alias of" section
      <StyledMenuItem
        className='menu-item-alias-placeholder'
        key={2}
        data-value={''}
        onClick={() => {} }
        disabled
      >
        <EntityLabelTypography>
          {
            intl.formatMessage({
              id: 'app.add-entity-type-select.alias-of',
              defaultMessage: 'Alias of'
            })
          }
        </EntityLabelTypography>
      </StyledMenuItem>,
      // Alias options, i.e., all the existing entities of the same type
      ...aliasCleartexts.map((aliasCleartext, idx3) => (
        <StyledMenuItem
          className='menu-item-alias-entity'
          key={idx3 + 3}
          onClick={() => {
            handleSelect(entityType, aliasCleartext)
          }}
        >
          <MenuItemLabelAndActionIcon
            label={aliasCleartext}
            actionIcon={<AddAliasIcon />}
            openDirection={openDirection ?? defaultOpenDirection}
          />
        </StyledMenuItem>
      ))
    ]
  }

  return (
    <FormControl className="entity-type-alias-selector-form" sx={{ display: 'flex' }}>
      <Select
        className='entity-type-alias-select'
        id="entity-type-alias-select"
        labelId='entity-type-alias-select-label'
        value={value}
        renderValue={() => <Typography>{value}</Typography>}
        open={open}
        onOpen={onOpen ?? (() => {})}
        onClose={onClose ?? (() => {})}
        sx={sx ?? {}}
        disabled={disabled ?? false}
        autoWidth
      >
        {[
          // Dummy menu item to avoid a MUI warning about value not being a selectable option
          <MenuItem key={0} value={value} sx={{ display: 'none' }} />,

          ...Array.from(globals.entityTypesByGroup).map(([group, entityTypeInfos], idx) => (
            // First level of menu is entity groups.
            // Make a sub-menu for each or them,
            // except for those that we want to "flatten".
            (globals.flattenedGroups.includes(group)
              ? entityTypeInfos.map((entityTypeInfo, idx2) => (
                <StyledMenuItem
                  className='menu-item-entity-without-group'
                  key={(idx + 1) * 100 + idx2}
                  data-value={'menu-item'}
                  onClick={() => {
                    handleSelect(entityTypeInfo.entityType, undefined)
                  } }
                  onMouseEnter={() => {
                    setOpenedLevel1MenuIdx(idx)
                    setOpenedLevel2MenuIdx(null)
                  }}
                >
                  <MenuItemLabelAndActionIcon
                    label={
                      intl.formatMessage({
                        id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                        defaultMessage: entityTypeInfo.prettyName
                      })
                    }
                    actionIcon={<AddIcon />}
                    openDirection={openDirection ?? defaultOpenDirection}
                  />
                </StyledMenuItem>
              ))
              : [
                <EntitySelectMenuItem
                  key={(idx + 1) * 100}
                  label={
                    intl.formatMessage({
                      id: `app.entity-group.${group}`,
                      defaultMessage: group
                    })
                  }
                  openDirection={openDirection ?? defaultOpenDirection}
                  open={openedLevel1MenuIdx === idx}
                  onOpen={() => {
                    setOpenedLevel1MenuIdx(idx)
                    setOpenedLevel2MenuIdx(null)
                  }}
                >
                  {entityTypeInfos.map((entityTypeInfo, idx2) => (
                    // Second level of menu is entity types.
                    // If aliases are not enabled for this entity type,
                    // then clicking on the entry should select it.
                    !entityTypeInfo.aliasesEnabled
                      ? (
                          <StyledMenuItem
                            className='menu-item-entity-lvl2'
                            key={(idx + 1) * 100 + idx2}
                            data-value={'menu-item'}
                            onClick={() => {
                              handleSelect(entityTypeInfo.entityType, undefined)
                            } }
                          >
                            <MenuItemLabelAndActionIcon
                              label={
                                intl.formatMessage({
                                  id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                                  defaultMessage: entityTypeInfo.prettyName
                                })
                              }
                              actionIcon={<AddIcon />}
                              openDirection={openDirection ?? defaultOpenDirection}
                            />
                          </StyledMenuItem>
                        )
                      : (
                        // Otherwise, make it a sub-menu
                        <EntitySelectMenuItem
                          key={(idx + 1) * 100 + idx2}
                          label={
                            intl.formatMessage({
                              id: `app.entity-type.${entityTypeInfo.entityType}.pretty-name`,
                              defaultMessage: entityTypeInfo.prettyName
                            })
                          }
                          openDirection={openDirection ?? defaultOpenDirection}
                          open={openedLevel2MenuIdx === idx2}
                          onOpen={() => { setOpenedLevel2MenuIdx(idx2) }}
                        >
                          {/* Either add as a new entity... */}
                          <StyledMenuItem
                            className='menu-item-submenu-entity'
                            key={0}
                            data-value={'sub-menu-item'}
                            onClick={() => {
                              handleSelect(entityTypeInfo.entityType, undefined)
                            } }
                          >
                            <MenuItemLabelAndActionIcon
                              label={
                                intl.formatMessage({
                                  id: 'app.add-entity-type-select.add-new-entity',
                                  defaultMessage: 'New'
                                })
                              }
                              actionIcon={<AddIcon />}
                              openDirection={openDirection ?? defaultOpenDirection}
                            />
                          </StyledMenuItem>

                          {/* ...or as an alias of an existing one */}
                          {entityTypeInfo.aliasesEnabled && listAliasOptions(entityTypeInfo.entityType)}
                        </EntitySelectMenuItem>
                        )
                  ))}
                </EntitySelectMenuItem>
                ]
            )
          )).flat()
        ]}
      </Select>
    </FormControl>
  )
}

export default EntityTypeOrAliasSelector
