import { Box, styled, Typography } from '@mui/material'

export const CellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: 0,
  margin: 0,
  // To position the view icon
  position: 'relative',
  width: '100%'
}))
