import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import Icon from 'components/icons/feather'
import { useColorMode } from 'context/ColorModeContext'
import { useIntl } from 'react-intl'

export const ColorModeMenuItem: React.FC = () => {
  const intl = useIntl()
  const { colorMode, setColorMode } = useColorMode()

  const toggleColorMode = (): void => {
    setColorMode(colorMode === 'light' ? 'dark' : 'light')
  }

  const label = intl.formatMessage({
    id: 'app.color-mode-menu-item.label',
    defaultMessage: 'Color mode'
  })

  const icon = colorMode === 'light' ? <Icon.Sun /> : <Icon.Moon />

  return (
    <MenuItem onClick={toggleColorMode}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  )
}

export default ColorModeMenuItem
