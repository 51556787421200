import { Box } from '@mui/material'
import { type DocReviewIssue } from 'features/analyze'
import { FiltersContainer } from 'features/analyze/components/contextual-issue/ContextualIssuesDisplay.styles'
import DocReviewIssueDisplay from 'features/analyze/components/contextual-issue/DocReviewIssueDisplay'
import DocReviewIssuesFilters from 'features/analyze/components/DocReviewIssuesFilters'
import useDocReviewIssuesFilter from 'features/analyze/hooks/useDocReviewIssuesFilter'
import { type FC } from 'react'
import { loremIpsum, mockAttachment1, mockAttachment2 } from '../mockData'
import Section from './Section'

const mockResult: DocReviewIssue = {
  category: 'Contextual',
  type: 'Deviation',
  title: 'Title',
  reasoning: 'Reasoning that leads to the explanation',
  explanation: 'Explanation',
  nature: 'modification',
  criticality: 'medium',
  evidences: [
    {
      docRef: {
        docId: mockAttachment1.id,
        docType: mockAttachment1.docType
      },
      location: 'Section 1',
      quote: 'Quote with some highlighted text',
      markedSpan: [16, 27],
      startingWith: 'first five words of the paragraph',
      targetLocation: null
    },
    {
      docRef: {
        docId: mockAttachment1.id,
        docType: mockAttachment1.docType
      },
      location: 'Section 2',
      quote: 'Another quote from the reviewed document',
      markedSpan: null,
      startingWith: null,
      targetLocation: null
    },
    {
      docRef: {
        docId: mockAttachment2.id,
        docType: mockAttachment2.docType
      },
      location: 'Article 42',
      quote: 'Quote from the reference document. This is a very long quote that should not be truncated at all. ' + loremIpsum.repeat(5),
      markedSpan: null,
      startingWith: null,
      targetLocation: null
    }
  ],
  corrections: [],
  reviewedClause: null,
  reviewedClauseLocation: null,
  referenceClause: null,
  referenceClauseLocation: null,
  isPointOfAttention: false,
  term: null,
  termNature: null
}

export const ContextualDocReviewSection: FC = () => {
  const results: DocReviewIssue[] = [
    {
      ...mockResult,
      nature: 'modification',
      criticality: 'high',
      explanation: 'Highly critical modification'
    },
    {
      ...mockResult,
      nature: 'addition',
      criticality: 'medium',
      explanation: 'Medium critical addition'
    },
    {
      ...mockResult,
      nature: 'omission',
      criticality: 'low',
      explanation: 'Low critical omission'
    },
    {
      ...mockResult,
      nature: 'non applicable',
      criticality: 'non applicable',
      explanation: 'Criticality and nature are non applicable'
    },
    {
      ...mockResult,
      nature: null,
      criticality: null,
      evidences: [],
      explanation: 'Nature and criticality are null, and there are no evidences'
    },
    {
      ...mockResult,
      nature: 'point of attention',
      criticality: null,
      explanation: 'Point of attention with no criticality'
    }
  ]

  const {
    filteredIssues,
    filters,
    setType,
    setSort,
    setCriticalities,
    setNatures
  } = useDocReviewIssuesFilter(results)

  return (
    <Section title="Contextual Doc Review">
      <FiltersContainer className='filters-container'>
        <DocReviewIssuesFilters
          issueCategory='Contextual'
          sortBy={filters.sortBy}
          type={filters.type}
          onTypeChange={setType}
          onSortChange={setSort}
          onCriticalitiesChange={setCriticalities}
          onNaturesChange={setNatures}
        />
      </FiltersContainer>
      <Box className='doc-review-issues'>
        {
          filteredIssues.map((result, idx) => {
            return (
              <div className='doc-review-issue-item' key={idx}>
                <DocReviewIssueDisplay
                  idx={idx}
                  result={result}
                  reviewedDoc={mockAttachment1}
                  referenceDoc={mockAttachment2}
                />
              </div>
            )
          })
        }
      </Box>
    </Section>
  )
}

export default ContextualDocReviewSection
