import { createContext, type ReactNode, useContext, useEffect, useState, type Dispatch, type SetStateAction } from 'react'
import { type UserInputForm } from '../types'

interface UserInputFormContextType {
  userInputForm: Partial<UserInputForm>
  setUserInputForm: Dispatch<SetStateAction<Partial<UserInputForm>>>
}

export const UserInputFormContext = createContext<UserInputFormContextType>(
  { userInputForm: {}, setUserInputForm: () => {} }
)

interface Props {
  children: ReactNode
}

export const UserInputFormProvider = ({ children }: Props): JSX.Element => {
  const [userInputForm, setUserInputForm] = useState<Partial<UserInputForm>>({})

  useEffect(() => {
    console.debug('> UserInputFormProvider [userInputForm]', userInputForm)
  }, [userInputForm])

  return (
    <UserInputFormContext.Provider value={{ userInputForm, setUserInputForm }}>
      {children}
    </UserInputFormContext.Provider>
  )
}

export const useUserInputForm = (): UserInputFormContextType => {
  return useContext(UserInputFormContext)
}
