import { ListItemText, type TypographyProps } from '@mui/material'
import RenameModal from 'components/left-pane/RenameModal'
import { useIntlContext } from 'context/IntlProviderWrapper'
import { useUpdateSession } from 'features/sessions/api/updateSession'
import { type Session } from 'features/sessions/types'
import type React from 'react'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { formatDateStringToLocale } from 'services/utils'
import { textSmRegular, textSmSemiBold } from 'theme/typography'
import DeleteSessionButton from './DeleteSession'
import { ButtonsContainer, StyledListItem } from './SessionItem.styles'

interface Props {
  session: Session
  selected: boolean
  onClick: () => void
  scrollIntoView?: boolean
}

const SessionItem: React.FC<Props> = ({
  session,
  selected,
  onClick,
  scrollIntoView = false
}: Props) => {
  const { localeConfig } = useIntlContext()
  const ref = useRef<HTMLLIElement>(null)

  const { mutate } = useUpdateSession()

  useEffect(() => {
    if (ref.current != null && selected && scrollIntoView) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [selected, scrollIntoView])

  const handleSessionRenamed = (newTitle: string): void => {
    if (session === undefined || session === null) {
      throw new Error('No session selected!')
    }

    mutate({ sessionId: session.id, update: { title: newTitle } })
  }

  // Needed to truncate long session titles
  const primaryTypographyProps: TypographyProps<'span', { component?: 'span' }> = {
    variant: 'body2',
    component: 'span',
    style: {
      ...textSmSemiBold,
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }

  const secondaryTypographyProps: TypographyProps<'span', { component?: 'span' }> = {
    variant: 'body1',
    component: 'span',
    style: {
      ...textSmRegular,
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }

  return (
    <StyledListItem className="sessions-list-item" onClick={onClick} ref={ref} selected={selected}>
      {/* Session title and creation time */}
      <ListItemText
        primary={session.title}
        primaryTypographyProps={primaryTypographyProps}
        secondary={formatDateStringToLocale(localeConfig.locale, session.createdAt)}
        secondaryTypographyProps={secondaryTypographyProps}
      />

      {/* Edit and delete buttons */}
      {selected && (
        <ButtonsContainer>
          <RenameModal initTitle={session.title} onRenamed={handleSessionRenamed} />
          <DeleteSessionButton sessionId={session.id} />
        </ButtonsContainer>
      )}
    </StyledListItem>
  )
}

export default SessionItem
