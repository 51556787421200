import { MenuItem, Select, Tooltip, type SelectChangeEvent } from '@mui/material'
import { FileUploader, type AttachmentListItem } from 'features/documents'
import useListAttachments from 'features/documents/api/listAttachments'
import FilenameWithIcon from 'features/documents/components/FilenameWithIcon'
import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import useSelectedDocuments from '../hooks/useSelectedDocuments'

/**
 * A dropdown menu for selecting one of the uploaded documents
 * as the template document of the Draft task.
 * All the other attached documents will be used as sources.
 */
export const TemplateDocumentSelect: React.FC = () => {
  const intl = useIntl()
  const { selectedTemplate, selectedSources, otherAttachments, disabled, setTemplateId } = useSelectedDocuments()

  // The list of documents to choose from is the list of all attachments
  const _attachments = useListAttachments()
  const attachments = _attachments ?? []

  // List attachments that are not already selected
  const availableAttachments = useMemo((): AttachmentListItem[] => {
    return [selectedTemplate, ...selectedSources, ...otherAttachments].filter((attachment) => attachment !== null) as AttachmentListItem[]
  }, [attachments, selectedTemplate, selectedSources, otherAttachments])

  const handleChange = useCallback((event: SelectChangeEvent<string>): void => {
    if (attachments === undefined) {
      return
    }
    const newAttachmentId = attachments.find((attachment) => attachment.id === event.target.value)
    if (newAttachmentId === undefined) {
      setTemplateId(null)
    } else {
      setTemplateId(newAttachmentId.id)
    }
  }, [attachments, setTemplateId])

  const tooltip = (
    disabled
      ? intl.formatMessage({ id: 'app.draft-task.template-document-select.tooltip.disabled', defaultMessage: 'Please upload a document first' })
      : undefined
  )

  return (
    <>
      <Tooltip
        title={tooltip}
      >
        <Select
          labelId='attachment-select-label'
          id='attachment-select'
          value={selectedTemplate?.id ?? ''}
          onChange={handleChange}
          aria-label="Attachment"
          disabled={disabled}
          sx={{
            minWidth: '100px',
            maxWidth: '400px'
          }}
        >
          {Array.from(availableAttachments).map((attachment, idx) => (
            <MenuItem key={idx} value={attachment.id}>
              <FilenameWithIcon filename={attachment.filename}/>
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
      <FileUploader iconOnly />
    </>
  )
}

export default TemplateDocumentSelect
