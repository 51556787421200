import { SanityCheckOption, type DocReviewIssue, type IssueType } from 'features/analyze'
import { InternalIssuesDisplay } from 'features/analyze/components/internal-issue'
import { mockAttachment1 } from 'features/component-catalog/mockData'
import MockDocumentPanelContextProvider from 'features/component-catalog/providers/MockDocumentPanelContextProvider'
import MockSessionContextProvider from 'features/component-catalog/providers/MockSessionContextProvider'
import MockSidepanesContextProvider from 'features/component-catalog/providers/MockSidepanesContextProvider'
import { type FC } from 'react'
import Section from './Section'

const mockResult: DocReviewIssue = {
  category: 'Internal',
  type: SanityCheckOption.TypographicalErrors,
  title: 'Title',
  reasoning: 'Reasoning that leads to the explanation',
  explanation: 'Explanation',
  criticality: 'medium',
  evidences: [
    {
      docRef: {
        docId: mockAttachment1.id,
        docType: mockAttachment1.docType
      },
      location: 'Section 1',
      quote: 'Quote with some highlighted text in Section 1',
      markedSpan: [16, 27],
      startingWith: 'first five words of the paragraph',
      targetLocation: null
    }
  ],
  corrections: [
    {
      before: 'text to correct',
      after: 'corrected text'
    }
  ],
  nature: null,
  reviewedClause: null,
  reviewedClauseLocation: null,
  referenceClause: null,
  referenceClauseLocation: null,
  isPointOfAttention: false,
  term: null,
  termNature: null
}

export const InternalDocReviewSection: FC = () => {
  const typographicalErrorsResults: DocReviewIssue[] = [
    {
      ...mockResult,
      title: 'Highly critical cross-reference issue and a long title that should be displayed nicely in the UI or so I hope',
      criticality: 'high',
      explanation: 'Explanation of the highly critical issue',
      evidences: [
        ...mockResult.evidences,
        {
          docRef: {
            docId: mockAttachment1.id,
            docType: mockAttachment1.docType
          },
          location: 'Section 2',
          quote: 'As stated in the Section 3 of the document',
          markedSpan: [17, 26],
          startingWith: 'As stated in the Section',
          targetLocation: 'Section 3'
        }
      ]
    },
    {
      ...mockResult,
      title: 'Medium critical issue, multiple evidences',
      criticality: 'medium',
      evidences: [
        ...mockResult.evidences,
        {
          docRef: {
            docId: mockAttachment1.id,
            docType: mockAttachment1.docType
          },
          location: 'Section 2',
          quote: 'Another quote from the reviewed document, from Section 2',
          markedSpan: null,
          startingWith: null,
          targetLocation: null
        }
      ],
      explanation: 'Explanation of the medium critical issue with multiple evidences'
    },
    {
      ...mockResult,
      title: 'Low critical issue',
      criticality: 'low',
      explanation: 'Explanation of the low critical issue'
    },
    {
      ...mockResult,
      title: 'Non applicable issue',
      criticality: 'non applicable',
      explanation: 'Explanation of the non applicable issue'
    },
    {
      ...mockResult,
      title: 'No evidences and null criticality',
      criticality: null,
      evidences: [],
      explanation: 'Criticality is null, and there are no evidences'
    },
    {
      ...mockResult,
      title: 'No corrections',
      corrections: [],
      explanation: 'No corrections'
    },
    {
      ...mockResult,
      title: 'Multiple corrections',
      corrections: [
        {
          before: 'text to correct',
          after: 'corrected text'
        },
        {
          before: 'text to correct 2',
          after: 'corrected text 2'
        }
      ],
      explanation: 'Multiple corrections'
    },
    {
      ...mockResult,
      title: null,
      explanation: 'No title for that issue, which means the option name will be displayed instead'
    }
  ]
  const definedTermsResults: DocReviewIssue[] = [
    {
      ...mockResult,
      type: SanityCheckOption.DefinedTerms,
      title: 'Effective date', // title is the term name?
      nature: 'clear definition',
      termNature: 'explicitly defined term',
      evidences: [
        {
          docRef: {
            docId: mockAttachment1.id,
            docType: mockAttachment1.docType
          },
          location: 'Article 1, "Effective date"',
          quote: 'Effective date | means the date on which this Agreement shall commence, being January 1, 2025',
          markedSpan: [0, 14],
          startingWith: 'means the date on which',
          targetLocation: null
        }
      ],
      explanation: 'The definition included in Article 1 of the contract is explicit and clear.',
      corrections: null
    }, {
      ...mockResult,
      title: 'Goods',
      nature: 'missing definition',
      termNature: 'implicitly defined term',
      evidences: [
        {
          docRef: {
            docId: mockAttachment1.id,
            docType: mockAttachment1.docType
          },
          location: 'Article 3',
          quote: 'The Supplier shall deliver the goods (i.e personal computers and peripherals) to the destination site.',
          markedSpan: [31, 36],
          startingWith: 'The Supplier shall deliver the',
          targetLocation: null
        }
      ],
      explanation: 'Article 3 of the contract refers to the goods delivered by the Supplier. Although this term is not explicitly defined, the description given to it is clear and could benefit from being explicitly defined.',
      corrections: [
        {
          // FIXME: this is a different correction format than the one we used until now,
          // to be discussed how we can handle this better.
          before: '',
          after: 'Define explicitly the term "Goods", by adding it to a definition clause, or capitalizing it for further use in the contract.'
        }
      ]
    }
  ]
  const redundancyResults: DocReviewIssue[] = [
    {
      ...mockResult,
      type: SanityCheckOption.Redundancies,
      title: 'Redundancy',
      nature: 'addition',
      explanation: 'The clause is redundant, as it is already defined in the contract.'
    }
  ]
  const crossReferenceResults: DocReviewIssue[] = [
    {
      ...mockResult,
      criticality: null,
      type: SanityCheckOption.InternalCrossReferenceCheck,
      title: 'Accurate cross-reference to Annex 1',
      nature: 'accurate cross-reference',
      explanation: (
        'Annex 1 of the contract indeed includes a pro-forma of invoice to be sent by the seller. ' +
        'The cross-reference is correct.'
      ),
      evidences: [
        {
          docRef: {
            docId: mockAttachment1.id,
            docType: mockAttachment1.docType
          },
          location: 'Article 7',
          quote: 'Seller shall send invoices in compliance with the pro-forma provided for in Annex 1 of the Agreement.',
          markedSpan: [76, 83],
          startingWith: 'Seller shall send invoices in',
          targetLocation: 'Annex 1'
        }
      ],
      corrections: null
    }, {
      ...mockResult,
      criticality: 'high',
      type: SanityCheckOption.InternalCrossReferenceCheck,
      title: 'Inaccurate cross-reference to Article 17',
      nature: 'inaccurate cross-reference',
      explanation: (
        'Article 17 of the contract refers to the payment obligation, and does not include any dispute resolution procedure or mechanism. ' +
        'The contract\'s clause 17.1 does not refer to the payment obligation, but instead to the dispute resolution procedure or mechanism. ' +
        'The contract\'s dispute resolution procedure is contained at Article 20 of the contract.'
      ),
      evidences: [
        {
          docRef: {
            docId: mockAttachment1.id,
            docType: mockAttachment1.docType
          },
          location: 'Article 3',
          quote: 'The parties agree to refer to Article 17.',
          markedSpan: [30, 40],
          startingWith: 'The parties agree to refer to',
          targetLocation: null
        }
      ],
      corrections: [{
        before: 'The parties agree to refer to Article 17.',
        after: 'The parties agree to refer to Article 20.'
      }]
    }, {
      ...mockResult,
      criticality: 'low',
      type: SanityCheckOption.InternalCrossReferenceCheck,
      title: 'Unverifiable cross-reference to Annex 1',
      nature: 'unverifiable cross-reference',
      explanation: (
        'Annex 1 does not appear to be included in the analyzed contract. ' +
        'The verification of the cross-reference could not be done.'
      ),
      evidences: [
        {
          docRef: {
            docId: mockAttachment1.id,
            docType: mockAttachment1.docType
          },
          location: 'Article 3',
          quote: 'The parties agree to refer to Annex 1.',
          markedSpan: [30, 37],
          startingWith: 'The parties agree to refer to',
          targetLocation: null
        }
      ],
      corrections: [{
        before: '',
        after: (
          'Verify whether content of Annex 1 is consistent with the description made to it in Article 17, ' +
          'and adapt if need be.'
        )
      }]
    }
  ]

  const results: DocReviewIssue[] = [
    ...typographicalErrorsResults,
    ...redundancyResults,
    ...definedTermsResults,
    ...crossReferenceResults
  ]

  const typeToResults = new Map<IssueType, DocReviewIssue[]>()
  results.forEach(result => {
    typeToResults.set(result.type, [...(typeToResults.get(result.type) ?? []), result])
  })
  // Adding this to test the case of 0 issues for a given option
  typeToResults.set(SanityCheckOption.OmissionsOfImportantTerms, [])

  return (
    <Section title="Internal Doc Review">
      <MockSidepanesContextProvider>
        <MockDocumentPanelContextProvider>
          <MockSessionContextProvider>
            <InternalIssuesDisplay
              typeToResults={typeToResults}
              reviewedDoc={mockAttachment1}
            />
          </MockSessionContextProvider>
        </MockDocumentPanelContextProvider>
      </MockSidepanesContextProvider>
    </Section>
  )
}

export default InternalDocReviewSection
