import { useState, useEffect } from 'react'

export const useContainerDimensions = (myRef: React.RefObject<HTMLDivElement>): { width: number, height: number } => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = (): { width: number, height: number } => ({
      width: myRef.current?.offsetWidth ?? 0,
      height: myRef.current?.offsetHeight ?? 0
    })

    const handleResize = (): void => {
      setDimensions(getDimensions())
    }

    if (myRef.current !== null) {
      setDimensions(getDimensions())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef])

  return dimensions
}

export default useContainerDimensions
