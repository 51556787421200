import { Box, Card, CardActions, CardContent, CardMedia, styled, Typography } from '@mui/material'

const FILE_ICON_SIZE = 32

export const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(1),
  '& .clickable-area:hover': {
    backgroundColor: theme.palette.action.hover
  }
}))

export const ClickableArea = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  gap: theme.spacing(1),
  // minWidth: 0 is needed because flexbox items have an implicit minimum width that prevents them
  // from shrinking below their content's width. Without it, the text won't truncate because the
  // container will expand to fit the full text instead of allowing overflow.
  // This property allows the flex item to shrink smaller than its content.
  minWidth: 0,
  alignItems: 'center',
  cursor: 'pointer',
  transition: theme.transitions.create(['background-color'])
}))

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  flexGrow: 0,
  flexShrink: 0,
  width: FILE_ICON_SIZE,
  height: FILE_ICON_SIZE,
  margin: theme.spacing(1)
}))

export const StyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  flexGrow: 1,
  flexShrink: 1,
  minWidth: 0,
  padding: 0,
  // Override MUI's default padding on the last child to make the content centered
  // (`justifyContent: center` is not enough because the padding is applied to the last child)
  '&:last-child': {
    paddingBottom: 0
  }
})

export const StyledTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: 0
})

export const StyledSecondaryTypography = styled(Typography)({
  color: 'text.secondary',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

export const StyledCardActions = styled(CardActions)({
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: 'auto',
  padding: 0
})
