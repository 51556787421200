import { IconButton, Tooltip } from '@mui/material'
import Icon from 'components/icons/feather'
import { useIntl } from 'react-intl'

interface Props {
  feedback: number
  onClick: () => void
}

export const ThumbsUpButton: React.FC<Props> = ({ feedback, onClick }: Props) => {
  return <Tooltip title="Helpful answer">
    <IconButton onClick={onClick}>
      {feedback === 1 ? <Icon.ThumbsUp /> : <Icon.ThumbsUp />}
    </IconButton>
  </Tooltip>
}

export const ThumbsDownButton: React.FC<Props> = ({ feedback, onClick }: Props) => {
  return <Tooltip title="Not helpful">
    <IconButton onClick={onClick}>
      {feedback === -1 ? <Icon.ThumbsDown /> : <Icon.ThumbsDown />}
    </IconButton>
  </Tooltip>
}

interface EditButtonProps {
  disabled?: boolean
  previouslyEdited: boolean
  onClick: () => void
}

export const EditButton: React.FC<EditButtonProps> = (
  { disabled = false, previouslyEdited, onClick }: EditButtonProps
) => {
  const intl = useIntl()
  const title = intl.formatMessage({
    id: 'app.edit-button.label',
    defaultMessage: 'Suggest a correction'
  })
  return <Tooltip title={title}>
    <span>
      <IconButton disabled={disabled} onClick={onClick}>
        {previouslyEdited ? <Icon.Edit2 style={{ fill: 'inherit' }} /> : <Icon.Edit2 />}
      </IconButton>
    </span>
  </Tooltip>
}
