import { AddBoxOutlined, ErrorOutline, IndeterminateCheckBoxOutlined, Iso, QuestionMark } from '@mui/icons-material'
import { Tooltip, type SvgIconProps } from '@mui/material'
import { type ComparativeAnalysisNature } from 'features/analyze'
import { useIntl } from 'react-intl'

interface Props {
  nature?: ComparativeAnalysisNature | null
  svgIconProps?: SvgIconProps
}

/**
 * Icon to represent the nature of an issue
 */
const ComparativeAnalysisNatureDisplay = ({ nature, svgIconProps }: Props): JSX.Element => {
  const intl = useIntl()

  const COMPARATIVE_ANALYSIS_NATURE_TO_ICON: Record<ComparativeAnalysisNature, JSX.Element> = {
    modification: <Iso {...svgIconProps} />,
    omission: <IndeterminateCheckBoxOutlined {...svgIconProps} />,
    addition: <AddBoxOutlined {...svgIconProps} />,
    'point of attention': <ErrorOutline {...svgIconProps} />,
    'non applicable': <QuestionMark {...svgIconProps} />
  }

  const tooltipText = intl.formatMessage({
    id: nature !== null && nature !== undefined
      ? `app.doc-review.result-type.nature.${nature}`
      : 'app.doc-review.result-type.no-nature'
  })

  if (nature === null || nature === undefined) {
    return <></>
  }

  return (
    <Tooltip title={tooltipText}>
      {COMPARATIVE_ANALYSIS_NATURE_TO_ICON[nature]}
    </Tooltip>
  )
}

export default ComparativeAnalysisNatureDisplay
