/**
 * Typography settings
 *
 * For reference, in Ed's design system the sizes were defined like this:
 * - Display xxl => Font size: 72px / 4.5rem | Line height: 90px / 5.625rem | Letter spacing: -2%
 * - Display xl  => Font size: 60px / 3.75rem | Line height: 72px / 4.5rem | Letter spacing: -2%
 * - Display lg  => Font size: 48px / 3rem | Line height: 60px / 3.75rem | Letter spacing: -2%
 * - Display md  => Font size: 36px / 2.25rem | Line height: 44px / 2.75rem | Letter spacing: -2%
 * - Display sm  => Font size: 30px / 1.875rem | Line height: 38px / 2.375rem
 * - Display xs  => Font size: 24px / 1.5rem | Line height: 32px / 2rem
 * - Text xl => Font size: 20px / 1.25rem | Line height: 30px / 1.875rem
 * - Text md => Font size: 16px / 1rem | Line height: 24px / 1.5rem
 * - Text sm => Font size: 14px / 0.875rem | Line height: 20px / 1.25rem
 * - Text xs => Font size: 12px / 0.75rem | Line height: 18px / 1.125rem
 */

import { type TypographyOptions } from '@mui/material/styles/createTypography'

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
}

/**
 * For "Save this request" and other small texts
 */
export const textXs = {
  fontSize: '12px',
  lineHeight: '18px'
}

/**
 * For most text in the app (body2)
 */
export const textMd = {
  fontSize: '16px',
  lineHeight: '24px'
}

/**
 * For slightly smaller text (body1)
 */
export const textSm = {
  fontSize: '14px',
  lineHeight: '20px'
}

/**
 * For slightly larger text (body1)
 */
export const textXl = {
  fontSize: '20px',
  lineHeight: '30px'
}

/**
 * For helper text below TextField
 */
export const textSmRegular = {
  ...textSm,
  fontWeight: fontWeights.regular
}

/**
 * For label text in TextField
 */
export const textSmMedium = {
  ...textSm,
  fontWeight: fontWeights.medium
}

/**
 * For text inside TextField
 */
export const textMdRegular = {
  ...textMd,
  fontWeight: fontWeights.regular
}

export const textMdMedium = {
  ...textMd,
  fontWeight: fontWeights.medium
}

/**
 * For settings menu items text
 */
export const textSmSemiBold = {
  ...textSm,
  fontWeight: fontWeights.semiBold
}

/**
 * For button text, menu item text, etc.
 */
export const textMdSemiBold = {
  ...textMd,
  fontWeight: fontWeights.semiBold
}

export const typography: TypographyOptions = {
  fontFamily: '"Inter", sans-serif',
  fontWeightLight: fontWeights.light,
  fontWeightRegular: fontWeights.regular,
  fontWeightMedium: fontWeights.medium,
  fontWeightSemiBold: fontWeights.semiBold,
  fontWeightBold: fontWeights.bold,
  h1: {
    // FIXME: Could not use rem sizes here because it's not compatible
    //        with responsiveFontSizes. I'm sticking to responsiveFontSizes
    //        for now, which provides good defaults.
    // fontSize: '4.5rem',
    // lineHeight: '5.625rem',
    // letterSpacing: '-0.02em',
    fontWeight: fontWeights.semiBold,
    letterSpacing: '-0.02em'
  },
  h2: {
    // fontSize: '3.75rem',
    // lineHeight: '4.5rem',
    // letterSpacing: '-0.02em',
    fontWeight: fontWeights.semiBold
  },
  h3: {
    // fontSize: '3rem',
    // lineHeight: '3.75rem',
    // letterSpacing: '-0.02em',
    fontWeight: fontWeights.semiBold
  },
  h4: {
    // fontSize: '2.25rem',
    // lineHeight: '2.75rem',
    // letterSpacing: '-0.02em',
    fontWeight: fontWeights.semiBold
  },
  h5: {
    // fontSize: '1.875rem',
    // lineHeight: '2.375rem',
    fontWeight: fontWeights.semiBold
  },
  h6: {
    // fontSize: '1.5rem',
    // lineHeight: '2rem',
    fontWeight: fontWeights.semiBold
  },
  body1: textSmMedium,
  body2: textMdRegular,
  button: {
    ...textMdSemiBold,
    letterSpacing: '0.01em',
    // Remove uppercase text transformation
    textTransform: 'none'
  },
  // Custom typography for the tooltip
  custom: textSmRegular
}

export default typography
