import { createAuthHeaderFromToken, axiosInstance } from 'services/axios'
import { type Session } from '../types'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { queryClient } from 'services/react-query'
import { useSession } from 'features/sessions'

export function useCreateSession (): UseMutationResult<Session, Error> {
  const { getAccessTokenSilently } = useAuth0()
  const { changeSelectedSession } = useSession()

  const mutationFn = async (): Promise<Session> => (
    await getAccessTokenSilently().then(async (token) =>
      await createNewSessionIfLastOneNotEmpty(token)
    )
  )

  return useMutation({
    mutationKey: ['createSession'],
    mutationFn,
    onError: (error, variables, context) => {
      console.error('Create session failed', error)
    },
    onSuccess: (session, variables, context) => {
      // Make an optimistic update to the cache
      queryClient.setQueryData<Session[]>(
        ['sessions'],
        (oldSessions) => (
          oldSessions !== undefined
            ? [session, ...oldSessions.filter((s) => s.id !== session.id)]
            : [session]
        )
      )
      // Set the selected session to the newly created one
      console.debug(`Selecting newly created session ${session.id}: ${session.title} `)
      changeSelectedSession(session.id)
    }
  })
}

const createNewSessionIfLastOneNotEmpty = async (token: string): Promise<Session> => {
  const authHeader = createAuthHeaderFromToken(token)
  const response = await axiosInstance.post<Session>(
    '/sessions',
    {
      createIfLastOneNotEmpty: true
    },
    {
      headers: authHeader,
      cache: false // Let react-query handle caching
    }
  )
  return response.data
}
