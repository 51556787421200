import { Box, styled } from '@mui/material'
import { scrollbarYFix } from 'theme/utils'

export const StyledList = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  overflowY: 'hidden',

  '& .sessions-list-content': {
    height: '100%',
    ...scrollbarYFix,
    // Fade out effect at the bottom
    paddingBottom: theme.spacing(3),
    maskImage: `linear-gradient(to bottom, black calc(100% - ${theme.spacing(3)}), transparent 100%)`,
    WebkitMaskImage: `linear-gradient(to bottom, black calc(100% - ${theme.spacing(3)}), transparent 100%)`,
  }
}))
