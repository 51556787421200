const HELP_PAGE_URL_BY_LOCALE: Record<string, string> = {
  en: 'https://gatewai.notion.site/User-Help-Page-42ec93be1d064e25956a86e947ad5bf3',
  fr: 'https://gatewai.notion.site/Assistance-Aide-7e1f58f9429c4e18923171daecf2825f'
}

export const openHelpPage = (locale: string): void => {
  window.open(
    HELP_PAGE_URL_BY_LOCALE[locale] ?? HELP_PAGE_URL_BY_LOCALE.en,
    '_blank'
  )
}
