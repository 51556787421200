import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import useDocumentPanel from 'features/documents/hooks/useDocumentPanel'
import { useIntl } from 'react-intl'

/**
 * Toggle button to switch between single and multi (two) document view.
 */
export const SecondFileToggleButton = (): JSX.Element => {
  const intl = useIntl()
  const { state, setViewMode } = useDocumentPanel()

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: 'SINGLE_VIEW' | 'COMPARE_VIEW'): void => {
    setViewMode(newAlignment)
  }

  const oneFileLabel = intl.formatMessage({ id: 'app.document-panel.second-file-toggle-button.one-file' })
  const secondFileLabel = intl.formatMessage({ id: 'app.document-panel.second-file-toggle-button.second-file' })

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={state.viewMode}
      exclusive
      onChange={handleChange}
      aria-label="Toggle secondary document view"
      sx={{
        '& .MuiToggleButton-root': {
          padding: '4px 8px',
          marginTop: '4px'
        }
      }}
    >
      <ToggleButton value="SINGLE_VIEW">{oneFileLabel}</ToggleButton>
      <ToggleButton value="COMPARE_VIEW">{secondFileLabel}</ToggleButton>
    </ToggleButtonGroup>
  )
}

export default SecondFileToggleButton
