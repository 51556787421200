import { type UserInputForm, AnalysisTaskAction, SanityCheckOption } from 'features/user-input-form/types'
import OptionsSelect, { type Option } from 'components/OptionsSelect'
import { useIntl } from 'react-intl'

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

const SanityCheckOptionsSelect: React.FC<Props> = (
  { userInputForm, onChange }: Props
) => {
  const intl = useIntl()

  // Create a map from the option id to the option object.
  // Starts at 1, as 0 is reserved for 'Select all' option
  const optionsMap = Object.values(SanityCheckOption).reduce<Record<number, SanityCheckOption>>((acc, option, idx) => {
    acc[idx + 1] = option
    return acc
  }, {})

  return (
    <OptionsSelect
      // TODO: We don't display the label yet because it appears next to the select component instead
      // of inside it, like a placeholder.
      // label={ intl.formatMessage({ id: 'app.doc-review.sanity-check.options-select.label' }) }
      items={
        Object.entries(optionsMap).map(([id, option]) => {
          return {
            id: parseInt(id),
            // Translate the option name, as it will be displayed in the select component.
            // We can use to id to match back to the option object.
            name: intl.formatMessage({
              id: `app.doc-review.sanity-check.options.${option}`,
              defaultMessage: option
            })
          }
        })
      }
      onChange={(selectedOps) => {
        const sanityCheckOptions: SanityCheckOption[] = selectedOps.map((option: Option) =>
          optionsMap[option.id]
        ).filter((option) => option !== undefined)
        onChange({
          ...userInputForm,
          taskParams: {
            action: AnalysisTaskAction.SanityCheck,
            sanityCheckOptions
          }
        })
      } }
    />
  )
}

export default SanityCheckOptionsSelect
