import { Table, TableBody, TableContainer, TableHead } from '@mui/material'
import createEvidencePairs from 'features/analyze/api/createEvidencePairs'
import { EvidenceTableCell } from 'features/analyze/components/evidence'
import { StyledHeaderTableCell, StyledTableRow } from 'features/analyze/components/evidence/EvidenceTableCell.styles'
import { type Evidence } from 'features/analyze/types'
import { type AttachmentListItem } from 'features/documents'
import { useIntl } from 'react-intl'
import { capitalizeFirstLetter } from 'services/utils'

interface Props {
  evidences: Evidence[]
  reviewedDoc: AttachmentListItem | null
  referenceDoc: AttachmentListItem | null
  onEvidenceClick?: (evidence: Evidence, doc: AttachmentListItem) => void
}

/**
 * Display relevant passages (evidences) side by side in a table,
 * with those from the reviewed document on the left and those from the reference document on the right.
 */
const ComparisonOverview: React.FC<Props> = ({
  evidences,
  reviewedDoc,
  referenceDoc,
  onEvidenceClick
}: Props) => {
  const intl = useIntl()

  const reviewedDocId = reviewedDoc?.id ?? null
  const referenceDocId = referenceDoc?.id ?? null

  const evidencesPairs: Array<[Evidence | null, Evidence | null]> = createEvidencePairs(reviewedDocId, referenceDocId, evidences)

  const formatHeaderText = (whichDoc: 'reviewed' | 'reference'): string => (
    capitalizeFirstLetter(
      intl.formatMessage({
        id: `app.comparison-overview.${whichDoc}-document`,
        defaultMessage: `${whichDoc} document`
      })
    )
  )

  return (
    <TableContainer className='comparison-overview'>
      <Table className='comparison-overview-table'>
        <TableHead className='comparison-overview-table-head'>
          <StyledTableRow className='comparison-overview-table-header-row'>
            <StyledHeaderTableCell>{formatHeaderText('reviewed')}</StyledHeaderTableCell>
            <StyledHeaderTableCell>{formatHeaderText('reference')}</StyledHeaderTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {/* <TableRow key={0}>
            <DocRefDisplay attachment={reviewedDoc} />
            <DocRefDisplay attachment={referenceDoc} />
          </TableRow> */}
          {evidencesPairs.map(([reviewedDocEvidence, referenceDocEvidence], idx) => (
            <StyledTableRow className='comparison-overview-row' key={idx + 1}>
              <EvidenceTableCell
                evidence={reviewedDocEvidence}
                onClick={() => {
                  if (reviewedDocEvidence !== null && reviewedDoc !== null) {
                    onEvidenceClick?.(reviewedDocEvidence, reviewedDoc)
                  } else {
                    console.warn('ComparisonOverview: no reviewed doc evidence or reviewed doc, cannot open evidence!')
                  }
                }}
              />
              <EvidenceTableCell
                evidence={referenceDocEvidence}
                onClick={() => {
                  if (referenceDocEvidence !== null && referenceDoc !== null) {
                    onEvidenceClick?.(referenceDocEvidence, referenceDoc)
                  } else {
                    console.warn('ComparisonOverview: no reference doc evidence or reference doc, cannot open evidence!')
                  }
                }}
              />
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ComparisonOverview
