import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const TipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  // Fix the height of the carousel to make it consistent between tips
  minHeight: '152px',
  alignItems: 'center'
}))

export const ComponentWrapper = styled(Box)(({ theme }) => ({
  // Adjust the basis width to make it consistent between tips
  flex: '0 0 160px',
  padding: theme.spacing(2),
  justifyItems: 'center',
  alignItems: 'center'
}))

export const TipDescription = styled(Typography)(() => ({
  flex: '1 1 100%'
}))
