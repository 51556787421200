import UploadInProgress from 'features/documents/components/UploadInProgress'
import { type AttachmentsAndUploads } from 'features/documents/types'
import type React from 'react'
import AttachmentChipContainer from './AttachmentChipContainer'
import StyledStack from './AttachmentsStack.styles'

interface Props {
  attachments: AttachmentsAndUploads
  deletable?: boolean
  openable?: boolean
}

/**
 * A stack (row) of attached files, represented as chips.
 */
export const AttachmentsStack: React.FC<Props> = (
  { attachments, deletable = true, openable = true }: Props
) => {
  const uploadInProgress = attachments.pending.some((a) =>
    a.status !== 'SUCCESS' && a.status !== 'FAILURE'
  )

  return <StyledStack direction='row' spacing={1}>
    {attachments.uploaded.map((a) => (
      <AttachmentChipContainer
        key={a.id}
        attachment={a}
        deletable={deletable}
        openable={openable}
      />
    ))}
    {uploadInProgress && <UploadInProgress />}
  </StyledStack>
}

export default AttachmentsStack
