import { TaskSelect } from 'components/central-pane/task-select'
import { type UserInputForm } from 'features/user-input-form'
import { type FC } from 'react'
import { useIntl } from 'react-intl'
import DisabledOverlay from './DisabledOverlay'
import { ComponentWrapper, TipContainer, TipDescription } from './TipContainer.style'

export const TipTaskSelect: FC = (): JSX.Element => {
  const intl = useIntl()
  return (
    <TipContainer>
      <ComponentWrapper>
        <DisabledOverlay>
          <TaskSelect
            userInputForm={{}}
            onChange={(userInputForm: Partial<UserInputForm>): void => {
              // Empty function since it won't be called anyway
            }}
          />
        </DisabledOverlay>
      </ComponentWrapper>
      <TipDescription>
        {
          intl.formatMessage({
            id: 'app.chat.splash-screen.tips.tip-task-select'
          })
        }
      </TipDescription>
    </TipContainer>
  )
}

export default TipTaskSelect
