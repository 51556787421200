import { type CSSObject } from '@mui/material'

/**
 * Prevents scrollbar from always showing up in Chrome.
 */
export const scrollbarYFix: CSSObject = {
  // If overlay scrollbars are not supported by the browser,
  // this will reserve space for the scrollbar. It's cleaner
  // to have that space on both sides of the container, to make
  // it look centered.
  // If the browser supports overlay scrollbars, the both-edges
  // still has some effect: without it, the component will slightly
  // shift to the opposite side when the scrollbar appears.
  // (See https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter)
  scrollbarGutter: 'stable both-edges',
  overflowY: 'hidden',
  '&:hover': {
    overflowY: 'auto'
  }
}
