import { InputLabel, type InputLabelProps } from '@mui/material'

interface Props extends InputLabelProps {
  showLabel: boolean
}

export const SelectInputLabel = ({ showLabel, ...props }: Props): JSX.Element => (
  <InputLabel
    disableAnimation
    // Prevent the label from moving to the top when the select is focused
    shrink={false}
    sx={{
      // Hide the label when the user has selected any option
      display: (showLabel ? 'block' : 'none')
    }}
  >
    {props.children}
  </InputLabel>
)

export default SelectInputLabel
