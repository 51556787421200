import { type ReactNode, createContext, useContext, useState } from 'react'

interface ProgressState {
  isOpen: boolean
  message?: string
  cancelable: boolean
  canceled: boolean
}

interface ProgressContextType {
  progressState: ProgressState
  showProgress: (message?: string, cancelable?: boolean) => void
  closeProgress: () => void
  cancelProgress: () => void
}

const ProgressContext = createContext<ProgressContextType | null>(null)

interface Props {
  children: ReactNode
}

/**
 * Provider for the state of the progress backdrop,
 * which is used to display a progress message to the user
 * when the application is processing a request.
 */
export const ProgressProvider = ({ children }: Props): JSX.Element => {
  const [progressState, setProgressState] = useState<ProgressState>({
    isOpen: false,
    cancelable: false,
    canceled: false
  })

  const showProgress = (message?: string, cancelable = false): void => {
    setProgressState({
      isOpen: true,
      message,
      cancelable,
      canceled: false
    })
  }

  /**
   * Close the progress backdrop when it finishes normally
   */
  const closeProgress = (): void => {
    setProgressState({
      ...progressState,
      isOpen: false,
      canceled: false
    })
  }

  /**
   * Close the progress backdrop when it was canceled
   */
  const cancelProgress = (): void => {
    if (progressState.cancelable) {
      setProgressState({
        ...progressState,
        isOpen: false,
        canceled: true
      })
    } else {
      console.warn('Progress backdrop is not cancelable')
    }
  }

  return (
    <ProgressContext.Provider value={{ progressState, showProgress, closeProgress, cancelProgress }}>
      {children}
    </ProgressContext.Provider>
  )
}

export const useProgressBackdrop = (): ProgressContextType => {
  const context = useContext(ProgressContext)
  if (context === null) {
    throw new Error('useProgressBackdrop must be used within a ProgressProvider')
  }
  return context
}
