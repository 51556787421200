import SendIcon from '@mui/icons-material/Send'
import {
  Box,
  Button,
  FormControl,
  TextField
} from '@mui/material'
import CancelButton from 'components/buttons/CancelButton'
import { FormButtonsContainer, formControlSx, textFieldSx } from './ReportModal.styles'
import { useIntl } from 'react-intl'
import { useState } from 'react'

interface Props {
  onSubmit: (comment: string) => void
  onClose: () => void
}

export const ReportModalForm = ({ onSubmit, onClose }: Props): JSX.Element => {
  const intl = useIntl()
  const [comment, setComment] = useState('')

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    onSubmit(comment)
  }

  const sendLabel = intl.formatMessage({
    id: 'app.report-modal.send-button.label',
    defaultMessage: 'Send'
  })

  return (
  <Box>
      <form onSubmit={handleSubmit}>
        <FormControl sx={formControlSx}>
          <TextField
            id="report-modal-input"
            multiline
            autoFocus
            fullWidth
            rows={19}
            variant="outlined"
            value={comment}
            onChange={(event) => { setComment(event.target.value) }}
            sx={textFieldSx}
          />
        </FormControl>

        {/* Buttons */}
        <FormButtonsContainer>
          <CancelButton onClick={onClose} />
          <Button
            type="submit"
            variant="contained"
            disabled={comment === ''}
            startIcon={<SendIcon />}
          >
            {
              sendLabel
            }
          </Button>
        </FormButtonsContainer>
      </form>
    </Box>
  )
}

export default ReportModalForm
