import { type User } from 'context/AuthContext'
import { type UserInputForm } from 'features/user-input-form/types'
import { axiosInstance, createAuthHeaderFromToken } from 'services/axios'
import { Role, type Message, type SessionHistory } from '..'

export const getSessionMessages = async (token: string, user: User, sessionId: string): Promise<SessionHistory> => {
  const authHeader = createAuthHeaderFromToken(token)
  return await axiosInstance.get<{ history: Message[] } >(
    `/sessions/${sessionId}`,
    {
      headers: authHeader,
      cache: false
    }
  ).then((response) => {
    const history: Message[] = response.data.history
    const lastUserInputForm: UserInputForm | null = (
      history.map((msg) => msg.role === Role.USER ? msg.state?.userInputForm : undefined
      ).filter((userInputForm) => userInputForm !== undefined
      ).pop() ?? null
    )
    return {
      history,
      lastUserInputForm
    }
  })
}
