import useDocumentPanel from 'features/documents/hooks/useDocumentPanel'
import createEvidencePairs from 'features/analyze/api/createEvidencePairs'
import { type AttachmentListItem } from 'features/documents'
import { type Evidence, type DocReviewIssue } from 'features/analyze/types'

/**
 * Process the text into chunks that can be searched for by the highlighter.
 */
const textToSearchableChunks = (text: string): string[] => {
  // Replace line breaks with spaces.
  // This is important because the highlighter won't match across line breaks.
  const textWithoutLineBreaks = text.replace(/\n+/g, ' ')
  // If ellipsis are present in the text, split the text before /
  // between / after the ellipsis to search for each chunk separately.
  return textWithoutLineBreaks.split('...')
}

interface UseViewInDocumentReturn {
  openQuoteFromEvidence: (evidence: Evidence, doc: AttachmentListItem) => void
  openFirstQuoteForBothDocs: () => void
}

/**
 * Hook to view (scroll to) the quoted passages in the document panel.
 */
export const useViewInDocument = (
  result: DocReviewIssue,
  reviewedDoc: AttachmentListItem | null,
  referenceDoc: AttachmentListItem | null
): UseViewInDocumentReturn => {
  const { openPrimaryDocument, openSecondaryDocument, compareDocuments } = useDocumentPanel()

  const getDocIdFromEvidence = (evidence: Evidence): string | null => {
    if (evidence.docRef?.docId != null) {
      return evidence.docRef.docId
    }
    console.warn('No document reference found in evidence, using reviewed doc')
    return reviewedDoc?.id ?? null
  }

  const openQuoteFromEvidence = (evidence: Evidence, doc: AttachmentListItem): void => {
    // FIXME: The evidence should contain the doc id in its docRef field,
    // expect that sometimes it is not present because of a bug in the backend.
    // We pass the doc explicitly to ensure we open the correct document.
    const evidenceDocId = getDocIdFromEvidence(evidence)
    if (evidenceDocId !== null && evidenceDocId !== doc.id) {
      console.warn('Evidence document reference does not match the provided document')
    }

    const searchTexts = textToSearchableChunks(evidence.quote)

    if (reviewedDoc !== null && referenceDoc !== null) {
      // If it was found in one of the docs, open the comparison view
      // but only search for the text in the doc that was quoted
      const primarySearchTexts = doc.id === reviewedDoc?.id ? searchTexts : undefined
      const secondarySearchTexts = doc.id === referenceDoc?.id ? searchTexts : undefined
      compareDocuments(reviewedDoc.id, referenceDoc.id, primarySearchTexts, secondarySearchTexts)
    } else if (reviewedDoc !== null) {
      // If there is only a reviewed doc, it should be the primary document
      openPrimaryDocument(reviewedDoc.id, searchTexts)
    } else if (referenceDoc !== null) {
      // If there is only a reference doc, it should be the secondary document
      openSecondaryDocument(referenceDoc.id, searchTexts)
    }
  }

  /**
   * Open both reviewed and reference docs in the document panel,
   * and scroll to the respective quotes
   */
  const openFirstQuoteForBothDocs = (): void => {
    if (reviewedDoc === null && referenceDoc === null) {
      console.error('reviewedDoc or referenceDoc is null, cannot compare!')
      return
    }

    const evidencePairs = createEvidencePairs(reviewedDoc?.id ?? null, referenceDoc?.id ?? null, result.evidences)
    if (evidencePairs.length === 0) {
      return
    }
    const primarySearchText = evidencePairs[0][0]?.quote
    const secondarySearchText = evidencePairs[0][1]?.quote

    // Quotes may contain an ellipsis, so we take the text before it to search for
    const primarySearchTexts = primarySearchText !== undefined ? textToSearchableChunks(primarySearchText) : undefined
    const secondarySearchTexts = secondarySearchText !== undefined ? textToSearchableChunks(secondarySearchText) : undefined

    console.debug(
      'Will compare documents and find matches for quotes: ' +
      `${primarySearchTexts?.map(text => `"${text}"`).join(', ') ?? '(undefined)'} // ` +
      `${secondarySearchTexts?.map(text => `"${text}"`).join(', ') ?? '(undefined)'}`
    )
    if (reviewedDoc !== null && referenceDoc !== null) {
      compareDocuments(reviewedDoc.id, referenceDoc.id, primarySearchTexts, secondarySearchTexts)
    } else if (reviewedDoc !== null) {
      openPrimaryDocument(reviewedDoc.id, primarySearchTexts)
    } else if (referenceDoc !== null) {
      openSecondaryDocument(referenceDoc.id, secondarySearchTexts)
    }
  }

  return {
    openQuoteFromEvidence,
    openFirstQuoteForBothDocs
  }
}
