import type * as React from 'react'
import { useEffect, useState } from 'react'
import MaximizePaneButton from './MaximizePaneButton'
import { SidepaneContainer, SidepaneHeader, SidepaneHeaderButtons, SidepaneHeaderContent } from './Sidepane.styles'
import { useSidepanes } from './SidepanesContext'
import SidepaneToggleButton from './SidepaneToggleButton'
import { getSidepaneWidthInPx } from './types'

interface Props {
  anchor: 'left' | 'right'
  resizable?: boolean
  children: React.ReactNode
  headerChildren?: React.ReactNode
}

/**
 * Renders a side panel that can be opened and closed.
 */
export const Sidepane: React.FC<Props> = (
  { anchor, resizable = false, children, headerChildren }: Props
) => {
  // Get the relevant pane state and setters
  const { leftPane, rightPane, openLeftPane, openRightPane, closeLeftPane, closeRightPane } = useSidepanes()
  const pane = anchor === 'left' ? leftPane : rightPane
  const openPane = anchor === 'left' ? openLeftPane : openRightPane
  const closePane = anchor === 'left' ? closeLeftPane : closeRightPane

  // Manage the width of the sidepane based on the relevant pane state
  const [width, setWidth] = useState<number>(0)

  // Update the width of the sidepane when the relevant pane changes
  useEffect(() => {
    setWidth(getSidepaneWidthInPx(pane))
  }, [pane])

  // const [hovering, setHovering] = React.useState(false)
  // const hoverTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleDrawerOpen = (): void => {
    openPane()
  }

  const handleDrawerClose = (): void => {
    closePane()
  }

  return (
    <SidepaneContainer
      className='sidepane-container'
      // Aside element conveys that this is not the main content
      component='aside'
      // Collapse props to animate the sidepane when it opens
      orientation='horizontal'
      in={pane.isOpen}
      timeout={200}
      anchor={anchor}
      isOpen={pane.isOpen}
      width={width}
      // Handle hover to open the drawer
      // onMouseEnter={() => {
      //   if (!pane.isOpen) return
      //   if (hoverTimeout.current !== null) clearTimeout(hoverTimeout.current)
      //   setHovering(true)
      // }}
      // onMouseLeave={() => {
      //   if (!pane.isOpen) return
      //   // If the mouse leaves the drawer, wait a bit before hiding the button
      //   // to close it in case the user moved their mouse too far
      //   hoverTimeout.current = setTimeout(() => {
      //     setHovering(false)
      //   }, 500)
      // }}
    >
      {/* Top-part of the sidepane, which contains its buttons */}
      <SidepaneHeader className='sidepane-header' anchor={anchor}>
        <SidepaneHeaderContent className='sidepane-header-content'>
          {headerChildren}
        </SidepaneHeaderContent>

        <SidepaneHeaderButtons className='sidepane-header-buttons' anchor={anchor}>
          {/* Maximize/minimize button, if the sidepane is resizable */}
          {resizable && <MaximizePaneButton
            anchor={anchor}
          />}

          {/* Button to open/close the drawer */}
          <SidepaneToggleButton
            open={pane.isOpen}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            anchor={anchor}
          />
        </SidepaneHeaderButtons>
      </SidepaneHeader>

      {/* The content of the sidepane */}
      {children}
    </SidepaneContainer>
  )
}

export default Sidepane
