import defaultFileIconSrc from 'assets/file-icons/default.svg'

export { defaultFileIconSrc }

/**
 * The size of the file icon in pixels.
 */
export const FILE_ICON_SIZE = 26

export const getFileIconSrc = (extension: string): string => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require(`assets/file-icons/${extension}.svg`)
  } catch {
    return defaultFileIconSrc
  }
}

interface Props {
  extension: string
}

const FiletypeIcon: React.FC<Props> = ({ extension }: Props) => {
  const src = getFileIconSrc(extension)
  return <img src={src} width={FILE_ICON_SIZE} height={FILE_ICON_SIZE} />
}

export default FiletypeIcon
