// React Feather Icons re-imported with default icon size set to match button text height.
//
// Use like this:
//
//     import Icon from 'components/icons/feather'
//     ...
//     <Icon.Send />
//
// References:
// - https://github.com/feathericons/react-feather
// - https://feathericons.com/

import React from 'react'
import type { Icon } from 'react-feather'
import * as icons from 'react-feather'
import { DEFAULT_ICON_SIZE_IN_PX } from './types'

// Override default icon size to match button text height
const defaultIconProps = {
  size: DEFAULT_ICON_SIZE_IN_PX
} as const

// Create wrapped icons with default props
const wrapIcon = (IconComponent: Icon): Icon =>
  Object.assign(
    (props: any): JSX.Element => {
      return React.createElement(IconComponent, { ...defaultIconProps, ...props })
    },
    IconComponent
  )

// Apply wrapper to all icons
const wrappedIcons = Object.fromEntries(
  Object.entries(icons).map(([key, IconComponent]) => [
    key,
    wrapIcon(IconComponent)
  ])
) as typeof icons

export { wrappedIcons as default, DEFAULT_ICON_SIZE_IN_PX }
export * from 'react-feather'
