import { AuthModalContainer } from 'components/AuthModalContainer'
import { BackToAuthButton } from 'components/BackToAuthButton'
import TermsForm from 'features/signup/components/TermsForm'
import { useIntl } from 'react-intl'
import { ModalHeader, ModalTitle } from 'theme'

interface Props {
  userFirstName?: string
  onAccept: () => void
}

export const AcceptTermsModal = ({ userFirstName, onAccept }: Props): JSX.Element => {
  const intl = useIntl()

  return (
    <AuthModalContainer sx={{ width: '800px' }}>
      <ModalHeader>
        <ModalTitle>
        {
          intl.formatMessage({
            id: 'app.callback-page.almost-there',
            defaultMessage: 'Almost there!'
          })
        }
        </ModalTitle>
        <BackToAuthButton />
      </ModalHeader>
      <TermsForm userFirstName={userFirstName} onAccept={onAccept} />
    </AuthModalContainer>
  )
}

export default AcceptTermsModal
