import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { TipOfTheDayCarousel } from 'components/central-pane/chat/tips'
import { type FC } from 'react'
import { CloseButtonContainer, ContentContainer, ContentItemContainer, StyledContainer } from './SplashScreen.styles'
import TaskSuggestion from './TaskSuggestion'
import TitleContainer from './TitleContainer'

interface Props {
  username: string
  onClose: () => void
}

/**
 * Splash screen to display in the chat before any message is sent,
 * to explain what can be done with Copilex.
 */
export const SplashScreen: FC<Props> = ({ username, onClose }): JSX.Element => {
  /**
   * When a task is selected, close the splash screen.
   */
  const handleTaskSelected = (): void => {
    console.log('handleTaskSelected')
    onClose()
  }

  return (
    <StyledContainer>
      <TitleContainer username={username} />
      <ContentContainer>
        {/* <ContentItemContainer>
          <TaskSuggestion onSelected={handleTaskSelected} />
        </ContentItemContainer> */}
        <ContentItemContainer>
          <TipOfTheDayCarousel />
        </ContentItemContainer>
      </ContentContainer>
      <CloseButtonContainer>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </CloseButtonContainer>
    </StyledContainer>
  )
}

export default SplashScreen
