import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, type AccordionProps, AccordionSummary, type AccordionSummaryProps, Box, styled } from '@mui/material'

/**
 * Customized Accordion component for an issue or item of a document analysis.
 */
export const IssueAccordion = styled((props: AccordionProps) => (
  // Improve performance by unmounting the details when the accordion is closed
  <Accordion {...props} slotProps={{ transition: { unmountOnExit: true } }} />
))(({ theme }) => ({
  // By default, make it look like a regular block of text (no background or border)
  elevation: 0,
  boxShadow: 'none',
  border: '1px solid transparent',
  backgroundColor: 'transparent',
  // But when hovered, the border will be shown to indicate that it is interactive
  '&:hover': {
    borderColor: theme.palette.primary.main
  },
  // When expanded, the border is shown in gray to materialize the accordion
  '&.Mui-expanded': {
    borderColor: theme.palette.divider
  },
  transition: 'border 0.3s ease-in'
}))

export const IssueAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<ExpandMore />}
    {...props}
  />
))(() => ({
  // Make the expand icon be on the left side
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    // Point to the right when not expanded
    transform: 'rotate(-90deg)',
    // Point to the bottom when expanded
    '&.Mui-expanded': {
      transform: 'rotate(0deg)'
    }
  }
}))

export const IssueAccordionSummaryContainer = styled(Box)(({ theme }) => ({
  // Take all available width, which will make the view icon buttons
  // all on the far right side and horizontally aligned
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center'
}))

export const IssueAccordionIconsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}))

export const IssueAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1)
}))
