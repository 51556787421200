import { Collapse } from '@mui/material'
import Box from '@mui/material/Box'
import { styled, type Theme } from '@mui/material/styles'

const borderStyle = (theme: Theme): string => `1px solid ${theme.palette.divider}`

interface SidepaneContainerProps {
  isOpen: boolean
  width: number
  anchor: 'left' | 'right'
}

export const SidepaneContainer = styled(Collapse)<SidepaneContainerProps>(({ theme, isOpen, width, anchor }) => ({
  // Collapse is used to animate the sidepane when it opens.
  // The component nests its children in a wrapper element.
  // Collapse width is auto and will expand to fit the nested children,
  // but it needs flex-shrink: 0 to leave space for siblings (central pane and other sidepane)
  flexShrink: 0,
  '.MuiCollapse-wrapperInner': {
    // The sidepane has a controlled width
    width: isOpen ? width : 0,
    minWidth: isOpen ? width : 0,
    flexShrink: 0,
    height: '100vh', // Always takes full height even if it has no content
    display: 'flex',
    flexDirection: 'column',
    ...(anchor === 'right' ? { borderLeft: borderStyle(theme) } : { borderRight: borderStyle(theme) }),
    backgroundColor: 'background.paper',
    overflow: 'hidden'
  },
  // Once the collapse transition has ended (.MuiCollapse-entered),
  // the sidepane will animate when changing width.
  '&.MuiCollapse-entered .MuiCollapse-wrapperInner': {
    transition: 'width 0.2s ease-in-out, min-width 0.2s ease-in-out'
  }
}))

interface SidepaneHeaderProps {
  anchor: 'left' | 'right'
}

export const SidepaneHeader = styled(Box)<SidepaneHeaderProps>(({ theme, anchor }) => ({
  flexShrink: 0, // Prevent the header from shrinking relative to the sidepane content
  minHeight: 0,
  width: '100%', // Always take up the entire width of the sidepane
  flexBasis: '40px', // Fixed height for the header, to fit the side pane icons
  display: 'flex',
  flexDirection: anchor === 'right' ? 'row-reverse' : 'row',
  alignItems: 'center',
  padding: 0 // No padding, let the children manage their own padding
}))

export const SidepaneHeaderContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

interface SidepaneHeaderButtonsProps {
  anchor: 'left' | 'right'
}

export const SidepaneHeaderButtons = styled(Box)<SidepaneHeaderButtonsProps>(({ theme, anchor }) => ({
  flexGrow: 0, // Don't grow, so the buttons don't take up the entire header
  // Justify the buttons to the border of the sidepane
  marginLeft: anchor === 'right' ? 'auto' : '0',
  marginRight: anchor === 'left' ? 'auto' : '0',
  display: 'flex',
  flexDirection: anchor === 'right' ? 'row-reverse' : 'row',
  alignItems: 'center'
}))
