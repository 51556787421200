import Icon from 'components/icons/feather'
import { assistantIdToSettings } from 'features/assistants'
import { type AttachmentListItem, DOC_TYPE_NOT_CERTAIN } from 'features/documents'
import { type Message, Role } from 'features/messages'
import { type MenuItemData } from 'mui-nested-menu'
import { PosAnonymizeStatus, type ReviewStateItem } from 'services/reviewState'

export const mockAttachment1: AttachmentListItem = {
  id: '00000000-0000-0000-0000-000000000000',
  filename: 'test contract.docx',
  truncated: false,
  text: null,
  createdAt: '2020-12-03',
  docType: DOC_TYPE_NOT_CERTAIN,
  deleted: false
}

export const mockAttachment2: AttachmentListItem = {
  id: '11111111-1111-1111-1111-111111111111',
  filename: 'test term sheet.pdf',
  truncated: true,
  text: null,
  createdAt: '2023-08-20',
  docType: 'Term sheet',
  deleted: false
}

export const mockMenuItemsData: MenuItemData = {
  label: 'NestedDropdown',
  leftIcon: <Icon.Circle />,
  items: [
    {
      label: 'MenuItem 1',
      leftIcon: <Icon.Circle />,
      items: [
        { label: 'MenuItem 1.1', leftIcon: <Icon.Circle /> },
        {
          label: 'MenuItem 1.2',
          leftIcon: <Icon.Circle />,
          items: [
            { label: 'MenuItem 1.2.1', leftIcon: <Icon.Circle /> },
            { label: 'MenuItem 1.2.2', leftIcon: <Icon.Circle /> }
          ]
        }
      ]
    },
    { label: 'MenuItem 2', leftIcon: <Icon.Circle />, disabled: true },
    {
      label: 'MenuItem 3',
      leftIcon: <Icon.Circle />,
      items: [
        { label: 'MenuItem 3.1', leftIcon: <Icon.Circle /> },
        { label: 'MenuItem 3.2', leftIcon: <Icon.Circle /> }
      ]
    }
  ]
}

export const loremIpsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
export const longTooltipText = (
  'This is a long tooltip to explain something to the user. ' + loremIpsum
)

export const reviewStateItem: ReviewStateItem = {
  cleartext: 'John Doe',
  entityType: 'PERSON_NAME',
  entityId: 42,
  anonymize: true,
  posAnonymizeStatus: PosAnonymizeStatus.ALL,
  aliases: []
}

const settings = assistantIdToSettings('copilex')
export const greetingsMsg: Message = {
  role: Role.ASSISTANT,
  cleartextContent: 'Hello world!',
  anonContent: 'Hello world!',
  settings
}
