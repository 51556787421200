import {
  Box,
  Typography,
  type PopoverPosition
} from '@mui/material'
import { type EntityType, type Tag } from 'features/anonymization/types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { InputDiffContainer } from './InputDiff.styles'
import InputDiffLabel from './InputDiffLabel'
import InputDiffSpan from './InputDiffSpan'
import TextSelectPopover from './TextSelectPopover'

/**
 * Compute the offset of the (beginning of the) selection within the parent node.
 * @param parentNode Parent node of the selection
 * @param selection Selection object, or undefined if we are not currently iterating over the selection
 * @returns The offset of the (beginning of the) selection within the parent node
 */
const computeTextOffsetBeforeSelection = (selection: Selection): number => {
  // Iterate over previous siblings to add their lengths to the offset,
  // until there are no more siblings which means we have reached the beginning
  // of the text.
  let additionalOffset = 0
  let node = selection.anchorNode as HTMLElement
  while (true) {
    node = node.previousSibling as HTMLElement
    if (node === null) {
      break
    }

    // If the child node is a BR element, add 1 to the offset
    // to account for the line break character that it represents
    if (node.tagName === 'BR') {
      additionalOffset += 1
    } else if (node.nodeType === Node.TEXT_NODE) {
      // If the child node is a text node, add its text length to the offset
      additionalOffset += node.textContent?.length ?? 0
    } else {
      console.error('Unsupported node: ', node)
    }
  }
  return additionalOffset
}

/**
 * Compute the offset of the selected text within the text.
 * This is done by adding:
 * the offset of the parent element within the input diff,
 * the offset of the selection within the parent element,
 * and the anchor offset of the selection.
 * @param selection Selection object
 * @returns computed offset
 */
const computeSelectedTextOffset = (selection: Selection): number | null => {
  // Check that nodes where the selection starts and where
  // it ends do actually exists
  if (selection.anchorNode === null) {
    return null
  }
  if (selection.focusNode === null) {
    return null
  }

  // The anchor node's parent should have an id that corresponds to its offset.
  const anchorParentNode = selection.anchorNode.parentNode as HTMLElement | null
  if (anchorParentNode === null) {
    return null
  }
  // The node should have an id that corresponds to its offset.
  if (anchorParentNode.id === null) {
    return null
  }
  if (anchorParentNode.id === '') {
    return null
  }
  const elementOffset: number = anchorParentNode.id === '' ? 0 : parseInt(anchorParentNode.id)
  if (isNaN(elementOffset)) {
    return null
  }

  // Check that the grandparent node of both the anchor and focus nodes
  // is the same input diff container (such that the selection is not
  // split between two different texts!)
  const focusNodeInputDiff = getParentInputDiffContainer(selection.focusNode)
  if (focusNodeInputDiff === null) {
    return null
  }
  const anchorNodeInputDiff = getParentInputDiffContainer(selection.anchorNode)
  if (focusNodeInputDiff !== anchorNodeInputDiff) {
    return null
  }

  // If there are multiple child nodes, we need to calculate the offset of anchor
  // within its parent element
  const additionalOffset = computeTextOffsetBeforeSelection(selection)

  return elementOffset + additionalOffset + selection.anchorOffset
}

const getParentInputDiffContainer = (node: Node): HTMLElement | null => {
  // Iterate over parent nodes until we find the input diff container
  let parentNode = node.parentNode as HTMLElement | null
  while (parentNode !== null) {
    if (parentNode.id?.startsWith('input-diff-container-')) {
      return parentNode
    }
    parentNode = parentNode.parentNode as HTMLElement | null
  }
  return null
}

interface Props {
  inputDiff: Array<[string, number, Tag | null]>
  docIdx: number
  entityTypeToActiveEntitiesCleartext: Map<EntityType, string[]>
  snap?: boolean
  onAddExactMatch?: (cleartext: string, entityType: EntityType, position: [number, number], asAliasOf?: string) => void
  onDeactivate?: (tag: Tag) => void
}

/**
 * InputDiff renders an anonymized text as a collection of InputDiffSpan fragments.
 * It handles actions on those fragments, such as adding or removing tags.
 */
const InputDiff: React.FC<Props> = (
  {
    inputDiff,
    docIdx,
    entityTypeToActiveEntitiesCleartext,
    snap,
    onAddExactMatch,
    onDeactivate
  }: Props
) => {
  const intl = useIntl()
  const [openTextSelectPopover, setOpenTextSelectPopover] = useState(false)
  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | undefined>(undefined)
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  // Keep track of the selected text and the position it starts at
  const [selectedText, setSelectedText] = useState<[string, number]>(['', 0])

  useEffect(() => {
    // Keep track of mouse position to show popover at its location
    const handleWindowMouseMove = (event: MouseEvent): void => {
      setCoords({
        x: event.clientX,
        y: event.clientY
      })
    }
    window.addEventListener('mousemove', handleWindowMouseMove)

    return () => {
      window.removeEventListener(
        'mousemove',
        handleWindowMouseMove
      )
    }
  }, [])

  const handleMouseUp = (): void => {
    const selection = window.getSelection()
    if (selection === null) {
      return
    }
    if (selection.rangeCount === 0) {
      // Has already happened, but could not reproduce it
      console.warn('Selection is empty: ', selection)
      return
    }

    // Save the selected range, to be able to re-select the text
    // later after having removed the selection
    const savedRange = selection.getRangeAt(0).cloneRange()

    /**
     * FIXME: both the following methods do not work as expected to get the selected text.
     * savedRange.toString() will not have the line breaks.
     * selection.toString() will have additional line breaks after each tag.
     * selection.toString() is preferable because users won't typically select
     * text that contains an existing anonymization tag. They can always remove
     * the tag and re-select the text if they want to create a new tag.
     */
    // const selectionStr = savedRange.toString()
    const selectionStr = selection.toString()

    // Prevent selection of line breaks without any actual text
    // (happens if the user clicked on a tag, the tag's span click listener
    // will handle it)
    if (selectionStr.length === 0) {
      return
    }

    // Compute the offset of the selected text within the input diff,
    // that is with respect to the beginning of the user input or attachment.
    const offset = computeSelectedTextOffset(selection)
    // Offset will be undefined if the selection is not within the input diff.
    // In that case, we should not show the popover.
    if (offset === null) {
      // Cancel the selection
      selection.removeAllRanges()
      return
    }

    setSelectedText([selectionStr, offset])

    // Show a popover slightly below the mouse cursor
    setAnchorPosition({ left: coords.x, top: coords.y + 20 })
    setOpenTextSelectPopover(true)

    // Ugly hack to re-select the text after the popover is shown.
    // Otherwise, the selection is removed when the popover is shown
    // and this would be confusing for the user.
    setTimeout(() => {
      selection.removeAllRanges()
      selection.addRange(savedRange)
    }, 200)
  }

  const handleAddExactMatch = (
    entityType: string,
    asAliasOf: string | undefined
  ): void => {
    if (onAddExactMatch === undefined) return

    const [cleartext, offset] = selectedText

    console.log('cleartext: ', cleartext)
    console.log('offset: ', offset)

    const position: [number, number] = [
      offset,
      offset + cleartext.length
    ]

    onAddExactMatch(cleartext, entityType, position, asAliasOf)
  }

  const handleChangeTag = (
    tag: Tag,
    entityType: string,
    asAliasOf: string | undefined
  ): void => {
    if (onAddExactMatch === undefined) return

    onAddExactMatch(tag.cleartext, entityType, tag.position, asAliasOf)
  }

  const handleCloseTextSelectPopover = (): void => {
    setOpenTextSelectPopover(false)
  }

  const handleDeactivate = (tag: Tag): void => {
    if (onDeactivate === undefined) return
    onDeactivate(tag)
  }

  // const handleActivate = (tag: Tag): void => {
  //   if (onActivate === undefined) return
  //   onActivate(tag)
  // }

  // const handleDelete = (cleartext: string): void => {
  //   if (onDelete === undefined) return
  //   onDelete(cleartext)
  // }

  const placeholderText = intl.formatMessage({
    id: 'app.review-modal.input-diff-empty',
    defaultMessage: '(empty)'
  })

  return (
    <InputDiffContainer className="input-diff">
      {/* Label of the text */}
      <InputDiffLabel docIdx={docIdx} />

      {/* Text segments */}
      <Box
        // The id is used to check if the selection is within the input diff
        id={`input-diff-container-${docIdx}`}
        className="input-diff-container"
        onMouseUp={handleMouseUp}
      >
        {
          inputDiff.length === 0
            ? <Typography variant="body2" className="input-diff-empty" sx={{ color: 'text.secondary' }}>
                {placeholderText}
              </Typography>
            : inputDiff.map(([text, offset, tag], idx2) =>
                <InputDiffSpan
                  key={idx2}
                  offset={offset}
                  text={text}
                  tag={tag}
                  entityTypeToActiveEntitiesCleartext={entityTypeToActiveEntitiesCleartext}
                  snap={snap}
                  onDeactivate={onDeactivate !== undefined ? handleDeactivate : undefined}
                  onReplace={onAddExactMatch !== undefined ? handleChangeTag : undefined}
                  // mouseCoords={coords}
                />
            )
        }
      </Box>
      {/* When text is selected, this popover allows user to quickly create an anonymization rule based on it */}
      <TextSelectPopover
        open={openTextSelectPopover}
        anchorPosition={anchorPosition}
        entityTypeToActiveEntitiesCleartext={entityTypeToActiveEntitiesCleartext}
        onAddExactMatch={handleAddExactMatch}
        onClose={handleCloseTextSelectPopover}
      />
    </InputDiffContainer>
  )
}

export default InputDiff
