import { Typography } from '@mui/material'
import { CopilexIcon } from 'components/icons'
import { type FC } from 'react'
import { useIntl } from 'react-intl'
import { StyledContainer } from './TitleContainer.styles'

interface Props {
  username: string
}

export const TitleContainer: FC<Props> = ({ username }) => {
  const intl = useIntl()

  return (
    <StyledContainer>
      <CopilexIcon />
      <Typography variant="h6">
        {
          intl.formatMessage({
            id: 'app.chat.splash-screen.greetings',
            defaultMessage: 'Hi, {username}. How can I help?'
          }, { username })
        }
      </Typography>
    </StyledContainer>
  )
}

export default TitleContainer
