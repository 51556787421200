import { Box } from '@mui/material'
import MessageText from 'components/central-pane/chat/MessageText'
import { type OtherDocReviewResult } from 'features/analyze'
import { useIntl } from 'react-intl'

interface Props {
  result: OtherDocReviewResult
}

/**
 * Component for displaying unstructured results (e.g., specific analysis results)
 */
const OtherDocReviewResultDisplay: React.FC<Props> = ({ result }: Props) => {
  const intl = useIntl()

  // Currently, the only case is specific analysis results,
  // so we use its translation.
  // FIXME: Make it support other (future) cases explicitly.
  const optionTranslation = intl.formatMessage({
    id: `app.specific-analysis-options-select.options.${result.option}`,
    defaultMessage: result.option
  })

  return (
    <Box className='other-doc-review-result'>
      <h1>{optionTranslation}</h1>
      <MessageText content={result.result}/>
    </Box>
  )
}

export default OtherDocReviewResultDisplay
