import { type FC } from 'react'
import { useProgressBackdrop } from '../../context/ProgressContext'
import ProgressBackdrop from './ProgressBackdrop'

/**
 * Container component for the progress backdrop.
 * This component is used to display the progress backdrop
 * when the application is processing a request.
 */
export const ProgressBackdropContainer: FC = (): JSX.Element => {
  const { progressState, cancelProgress } = useProgressBackdrop()

  return (
    <ProgressBackdrop
      open={progressState.isOpen}
      msg={progressState.message}
      onCancel={progressState.cancelable ? cancelProgress : undefined}
    />
  )
}
