import { Button } from '@mui/material'
import { forwardRef } from 'react'
import { useIntl } from 'react-intl'

const CancelButton = forwardRef<HTMLButtonElement, any>(function CancelButton (props, ref) {
  const intl = useIntl()
  return (
    <Button variant={'outlined'} {...props} ref={ref}>
      {
        intl.formatMessage({
          id: 'app.cancel-button.label',
          defaultMessage: 'Cancel'
        })
      }
    </Button>
  )
})

export default CancelButton
