import { ArrowRight } from '@mui/icons-material'
import { Box } from '@mui/material'
import { ThumbsDownButton, ThumbsUpButton } from 'components/buttons/ThumbsButtons'
import { UserMenu } from 'features/users/components/UserMenu'
import { ReportIcon } from 'features/users/components/UserMenu/ReportMenuItem'
import { type FC } from 'react'
import { useIntl } from 'react-intl'
import DisabledOverlay from './DisabledOverlay'
import { ComponentWrapper, TipContainer, TipDescription } from './TipContainer.style'

export const TipFeedback: FC = (): JSX.Element => {
  const intl = useIntl()
  return (
    <TipContainer>
      <ComponentWrapper>
        <DisabledOverlay>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <ThumbsUpButton feedback={1} onClick={() => {}} />
            <ThumbsDownButton feedback={-1} onClick={() => {}} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <UserMenu />
            <ArrowRight />
            <ReportIcon />
          </Box>
        </DisabledOverlay>
      </ComponentWrapper>
      <TipDescription>
        {
          intl.formatMessage({
            id: 'app.chat.splash-screen.tips.tip-feedback'
          })
        }
      </TipDescription>
    </TipContainer>
  )
}

export default TipFeedback
