import { styled, TableCell, TableRow, type Theme } from '@mui/material'

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // Add a gap between the cells instead of using margin or padding in cells
  display: 'flex',
  gap: theme.spacing(1)
}))

const baseTableCellStyles = ({ theme }: { theme: Theme }): Record<string, any> => ({
  width: '50%',
  verticalAlign: 'top',
  margin: 0,
  // padding: theme.spacing(1),
  padding: 0,
  borderBottom: 'none'
})

export const StyledTableCell = styled(TableCell)(baseTableCellStyles)

// Same as StyledTableCell, but with bold text and centered
export const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  ...baseTableCellStyles({ theme }),
  fontWeight: 'bold',
  textAlign: 'center'
}))
