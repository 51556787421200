import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { NestedMenuItem } from './NestedMenuItem'
import { nestedMenuItemSx } from './Selectors.styles'

interface Props {
  open: boolean
  label: string
  openDirection: 'left' | 'right'
  children: React.ReactNode
  onOpen: () => void
}

const EntitySelectMenuItem = ({ open, label, openDirection, children, onOpen }: Props): JSX.Element => (
  <NestedMenuItem
    onOpen={onOpen}
    parentMenuOpen={open}
    label={label}
    MenuProps={{
      anchorOrigin: {
        vertical: 'top',
        horizontal: openDirection
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: openDirection === 'left' ? 'right' : 'left'
      },
      sx: nestedMenuItemSx
    }}
    leftIcon={openDirection === 'left' ? <KeyboardArrowLeftIcon /> : null}
    rightIcon={openDirection === 'right' ? <KeyboardArrowRightIcon /> : null}
  >
    {children}
  </NestedMenuItem>
)

export default EntitySelectMenuItem
