import { Sidepane } from 'components/sidepanes'
import useDocumentPanel from 'features/documents/hooks/useDocumentPanel'
import type React from 'react'
import DocumentList from './DocumentList'
import { StyledContainer } from './DocumentPanel.styles'
import DocumentPanelHeader from './DocumentPanelHeader'
import DocumentWorkspace from './DocumentWorkspace'
import { type UUID } from 'crypto'

export const DocumentPanel: React.FC = () => {
  const { state, openPrimaryDocument } = useDocumentPanel()

  const handleDocumentSelected = (id: UUID): void => {
    openPrimaryDocument(id)
  }

  return (
    <Sidepane anchor={'right'} resizable headerChildren={<DocumentPanelHeader />}>
      <StyledContainer className='document-panel-container'>
        {state.viewMode === 'LIST_VIEW' && <DocumentList onDocumentSelected={handleDocumentSelected} />}
        {state.viewMode !== 'LIST_VIEW' && <DocumentWorkspace />}
      </StyledContainer>
    </Sidepane>
  )
}

export default DocumentPanel
