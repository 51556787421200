import { Box, styled } from '@mui/material'

export const LeftPaneHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(3),
  // Add some padding, as the sidepane header container does not provide any
  padding: theme.spacing(2),
  // Need also some margin at the top, to make the user menu not appear stuck to the top of the pane
  marginTop: theme.spacing(2)
}))
