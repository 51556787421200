import ListIcon from '@mui/icons-material/List'
import {
  IconButton,
  Tooltip
} from '@mui/material'
import useDocumentPanel from 'features/documents/hooks/useDocumentPanel'
import { useIntl } from 'react-intl'

export const BackToListView = (): JSX.Element => {
  const intl = useIntl()
  const { openListView } = useDocumentPanel()

  const handleBackToListView = (): void => {
    openListView()
  }

  const tooltip = intl.formatMessage({ id: 'app.document-panel.back-to-list-view.tooltip', defaultMessage: 'Back to list view' })

  return (
    <Tooltip title={tooltip}>
      <IconButton onClick={handleBackToListView}>
        <ListIcon />
      </IconButton>
    </Tooltip>
  )
}

export default BackToListView
