import { usePdfSearch } from 'components/PdfViewer'
import { DEFAULT_SIDE_PANE_WIDTH_IN_PX, useSidepanes } from 'components/sidepanes'
import { type UUID } from 'crypto'
import { createContext, useEffect, useState } from 'react'
import { useAttachments } from '../api/getAttachments'

export interface DocumentData {
  id: UUID
  filename: string
  createdAt: string
  deleted: boolean
  // rawContent: string
}

export type DocumentPanelViewMode = 'LIST_VIEW' | 'SINGLE_VIEW' | 'COMPARE_VIEW'

export interface DocumentPanelState {
  /**
   * Documents listed in the document panel, i.e. attachments uploaded to the session
   */
  documents: DocumentData[]
  /**
   * The current view mode of the document panel (list view, single view, compare view).
   */
  viewMode: DocumentPanelViewMode
  /**
   * The primary document being displayed in the document workspace.
   */
  primaryDocument?: DocumentData
  /**
   * The secondary document being displayed in the document workspace.
   */
  secondaryDocument?: DocumentData
  /**
   * Search texts to apply to the primary document once it's loaded
   */
  primarySearchTexts?: string[]
  /**
   * Search texts to apply to the secondary document once it's loaded
   */
  secondarySearchTexts?: string[]
}

export const defaultDocumentPanelState: DocumentPanelState = {
  documents: [],
  viewMode: 'LIST_VIEW',
  primaryDocument: undefined,
  secondaryDocument: undefined,
  primarySearchTexts: undefined,
  secondarySearchTexts: undefined
}

export interface DocumentPanelContextValue {
  state: DocumentPanelState
  openListView: () => void
  setViewMode: (viewMode: DocumentPanelViewMode) => void
  openPrimaryDocument: (documentId: UUID, searchTexts?: string[]) => void
  openSecondaryDocument: (documentId: UUID, searchTexts?: string[]) => void
  compareDocuments: (primaryId: UUID, secondaryId: UUID, primarySearchTexts?: string[], secondarySearchTexts?: string[]) => void
  closePrimaryDocument: () => void
  closeSecondaryDocument: () => void
  deleteDocument: (documentId: UUID) => void
  replaceSecondaryDocument: (documentId: UUID) => void
  handlePrimaryDocumentLoad: () => void
  handleSecondaryDocumentLoad: () => void
}

export const DocumentPanelContext = createContext<DocumentPanelContextValue | undefined>(undefined)

interface DocumentPanelProviderProps {
  children?: React.ReactNode
}

export const DocumentPanelProvider = ({ children }: DocumentPanelProviderProps): JSX.Element => {
  const [state, setState] = useState<DocumentPanelState>(defaultDocumentPanelState)

  // Fetch the attachments for the current session, including deleted ones,
  // as they might be used in previous messages and we list want to show them
  const { data: attachments } = useAttachments({ withContent: false, autoRefetch: false, includeDeleted: true })
  const { openRightPane, setRightPaneWidths } = useSidepanes()
  const { setSearchTexts: setPrimarySearchTexts } = usePdfSearch('primary')
  const { setSearchTexts: setSecondarySearchTexts } = usePdfSearch('secondary')

  // Set the min and max width of the right pane based on the view mode
  useEffect(() => {
    if (state.viewMode === 'LIST_VIEW') {
      setRightPaneWidths(DEFAULT_SIDE_PANE_WIDTH_IN_PX, DEFAULT_SIDE_PANE_WIDTH_IN_PX * 2)
    } else if (state.viewMode === 'SINGLE_VIEW') {
      setRightPaneWidths('one-third', 'half')
    } else if (state.viewMode === 'COMPARE_VIEW') {
      setRightPaneWidths('half', 'two-thirds')
    }
  }, [state.viewMode])

  // Update the state based on what is uploaded for the current session
  useEffect(() => {
    if (attachments === undefined) {
      return
    }

    // We need to have deleted attachments as well, as they might be used in previous messages
    // and we want to show them in the document panel
    const newDocuments = attachments.uploaded.concat(attachments.deleted).map(attachment => ({
      id: attachment.id,
      filename: attachment.filename,
      createdAt: attachment.createdAt,
      deleted: attachment.deleted
    }))

    setState(state => ({
      ...state,
      documents: newDocuments,
      // Reset to list view
      viewMode: 'LIST_VIEW',
      primaryDocument: undefined,
      secondaryDocument: undefined
    }))
  }, [attachments])

  // Apply the search texts when they are set.
  // It might not do anything if the document is not loaded yet,
  // but that's fine because we have handlePrimaryDocumentLoad / handleSecondaryDocumentLoad
  // to apply it again when the document is loaded.
  useEffect(() => {
    if (state.primarySearchTexts !== undefined) {
      setPrimarySearchTexts(state.primarySearchTexts)
    }
  }, [state.primarySearchTexts])

  useEffect(() => {
    if (state.secondarySearchTexts !== undefined) {
      setSecondarySearchTexts(state.secondarySearchTexts)
    }
  }, [state.secondarySearchTexts])

  const openListView = (): void => {
    openRightPane()
    setState(state => ({
      ...state,
      viewMode: 'LIST_VIEW'
    }))
  }

  const setViewMode = (viewMode: DocumentPanelViewMode): void => {
    setState(state => ({
      ...state,
      viewMode
    }))
  }

  const openPrimaryDocument = (documentId: UUID, searchTexts?: string[]): void => {
    // Find the document entry corresponding to the document ID in our state
    const primaryDocument = state.documents.find(doc => doc.id === documentId)
    if (primaryDocument === undefined) {
      throw new Error(`Document not found: ${documentId}`)
    }

    // If the secondary document is the same as the primary document, set it to undefined
    const secondaryDocument = (
      state.secondaryDocument?.id !== primaryDocument.id ? state.secondaryDocument : undefined
    )

    openRightPane()
    setState(state => ({
      ...state,
      // Switch the view mode only if we are in list view
      // (to stay in compare view if we are in it)
      viewMode: state.viewMode === 'LIST_VIEW' ? 'SINGLE_VIEW' : state.viewMode,
      primaryDocument,
      secondaryDocument,
      primarySearchTexts: searchTexts
    }))
  }

  const openSecondaryDocument = (documentId: UUID, searchTexts?: string[]): void => {
    // Secondary document must be opened after primary document
    if (state.primaryDocument === undefined) {
      throw new Error('Primary document not found, cannot open secondary document')
    }

    // Find the document entry corresponding to the document ID in our state
    const secondaryDocument = state.documents.find(doc => doc.id === documentId)
    if (secondaryDocument === undefined) {
      throw new Error(`Document not found: ${documentId}`)
    }

    openRightPane()
    setState(state => ({
      ...state,
      viewMode: 'COMPARE_VIEW',
      secondaryDocument,
      secondarySearchTexts: searchTexts
    }))
  }

  const compareDocuments = (primaryId: UUID, secondaryId: UUID, primarySearchTexts?: string[], secondarySearchTexts?: string[]): void => {
    // Open the document panel, in case it is not already open
    openRightPane()

    // Find both documents in our state
    const primaryDocument = state.documents.find(doc => doc.id === primaryId)
    const secondaryDocument = state.documents.find(doc => doc.id === secondaryId)
    if (primaryDocument === undefined || secondaryDocument === undefined) {
      throw new Error('Either primary or secondary document was not found, cannot compare them')
    }

    // Set the state to display the compare view between the two documents
    setState(state => ({
      ...state,
      viewMode: 'COMPARE_VIEW',
      primaryDocument,
      secondaryDocument,
      primarySearchTexts,
      secondarySearchTexts
    }))
  }

  const handlePrimaryDocumentLoad = (): void => {
    if (state.primarySearchTexts !== undefined) {
      setPrimarySearchTexts(state.primarySearchTexts)
      setState(state => ({
        ...state,
        primarySearchTexts: undefined
      }))
    }
  }

  const handleSecondaryDocumentLoad = (): void => {
    if (state.secondarySearchTexts !== undefined) {
      setSecondarySearchTexts(state.secondarySearchTexts)
      setState(state => ({
        ...state,
        secondarySearchTexts: undefined
      }))
    }
  }

  const closePrimaryDocument = (): void => {
    // If the primary document is closed, set the secondary document as the primary document,
    // and otherwise go back to list view

    if (state.primaryDocument === undefined) {
      return
    }

    if (state.secondaryDocument !== undefined) {
      setState(state => ({
        ...state,
        primaryDocument: state.secondaryDocument,
        secondaryDocument: undefined
      }))
    } else {
      setState(state => ({
        ...state,
        primaryDocument: undefined,
        secondaryDocument: undefined,
        viewMode: 'LIST_VIEW'
      }))
    }
  }

  const closeSecondaryDocument = (): void => {
    const documentId = state.secondaryDocument?.id
    if (documentId === undefined) {
      throw new Error('Secondary document not found, cannot close')
    }

    let newSecondaryDocument = state.secondaryDocument?.id === documentId ? undefined : state.secondaryDocument
    let newPrimaryDocument = state.primaryDocument?.id === documentId ? undefined : state.primaryDocument

    // If the primary document is closed, set the secondary document as the primary document
    if (newPrimaryDocument === undefined && newSecondaryDocument !== undefined) {
      newPrimaryDocument = newSecondaryDocument
      newSecondaryDocument = undefined
    }

    setState(state => ({
      ...state,
      primaryDocument: newPrimaryDocument,
      secondaryDocument: newSecondaryDocument
    }))
  }

  const deleteDocument = (documentId: UUID): void => {
    setState(state => ({
      ...state,
      documents: state.documents.filter(doc => doc.id !== documentId)
    }))
  }

  const replaceSecondaryDocument = (documentId: UUID): void => {
    openRightPane()
    setState(state => ({
      ...state,
      secondaryDocument: state.documents.find(doc => doc.id === documentId)
    }))
  }

  return (
    <DocumentPanelContext.Provider value={{
      state,
      openListView,
      setViewMode,
      openPrimaryDocument,
      openSecondaryDocument,
      compareDocuments,
      closePrimaryDocument,
      closeSecondaryDocument,
      deleteDocument,
      replaceSecondaryDocument,
      handlePrimaryDocumentLoad,
      handleSecondaryDocumentLoad
    }}>
      {children}
    </DocumentPanelContext.Provider>
  )
}

export default DocumentPanelProvider
