import { IconButton, Tooltip } from '@mui/material'
import Icon from 'components/icons/feather'
import { useIntl } from 'react-intl'

interface Props {
  showRaw: boolean
  setShowRaw: (showRaw: boolean) => void
}

const ToggleRawButton: React.FC<Props> = ({ showRaw, setShowRaw }: Props) => {
  const intl = useIntl()

  const handleClick = (): void => {
    setShowRaw(!showRaw)
  }

  const title = (
    showRaw
      ? intl.formatMessage({
        id: 'app.toggle-raw-button.show-formatted-text',
        defaultMessage: 'Click to show formatted text'
      })
      : intl.formatMessage({
        id: 'app.toggle-raw-button.show-raw-text',
        defaultMessage: 'Click to show raw text'
      })
  )

  return (
    <Tooltip title={title}>
      <IconButton onClick={handleClick}>
        {showRaw ? <Icon.Type /> : <Icon.Image />}
      </IconButton>
    </Tooltip>
  )
}

export default ToggleRawButton
