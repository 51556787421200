import { type AttachmentListItem } from 'features/documents'
import { useViewInDocument } from 'features/analyze/hooks/useViewInDocument'
import { type DocReviewIssue, type Evidence } from 'features/analyze/types'
import InternalIssueDisplay from './InternalIssueDisplay'

interface Props {
  idx: number
  result: DocReviewIssue
  reviewedDoc: AttachmentListItem
}

/**
 * Container component for a single internal issue.
 */
export const InternalIssueDisplayContainer = ({
  idx,
  result,
  reviewedDoc
}: Props): JSX.Element => {
  const { openQuoteFromEvidence } = useViewInDocument(result, reviewedDoc, null)

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean): void => {
    if (isExpanded) {
      console.debug('InternalIssueDisplayContainer: handleChange', isExpanded)
    }
  }

  const handleSummaryClick = (): void => {
    openQuoteFromEvidence(result.evidences[0], reviewedDoc)
  }

  const handleEvidenceClick = (evidence: Evidence): void => {
    openQuoteFromEvidence(evidence, reviewedDoc)
  }

  return (
    <InternalIssueDisplay
      idx={idx}
      result={result}
      onSummaryClick={handleSummaryClick}
      onEvidenceClick={handleEvidenceClick}
      onChange={handleChange}
    />
  )
}

export default InternalIssueDisplayContainer
