import { type HighlighterContextValue, HighlighterProvider, useHighlighter } from 'components/Highlighter'
import { type Context, createContext, type FC, type ReactNode, useContext } from 'react'
import { type PdfViewerId } from './types'

// Pdf search context value is the same as the highlighter context value,
// as the provider is a wrapper around the highlighter provider.
// We still declare it as a separate type to make it clear that it might change in the future.
interface PdfSearchContextValue extends HighlighterContextValue {}

// Define a context for each PDF viewer instance (primary and secondary)
const PrimaryPdfSearchContext: Context<PdfSearchContextValue | null> = createContext<PdfSearchContextValue | null>(null)
const SecondaryPdfSearchContext: Context<PdfSearchContextValue | null> = createContext<PdfSearchContextValue | null>(null)

interface ProviderProps {
  /**
   * The context for the PDF viewer instance. This is a prop of the provider
   * because each provider handles a specific PDF viewer instance.
   */
  context: Context<PdfSearchContextValue | null>
  /**
   * The children to render.
   */
  children: ReactNode
}

/**
 * Internal component that provides the actual PDF search functionality.
 * This separation is necessary because we use the useHighlighter hook
 * to perform the highlighting, and its provider needs to be outside of
 * this component (see PdfSearchProvider).
 */
const PdfSearchContent: FC<{ children: ReactNode, context: Context<PdfSearchContextValue | null> }> = ({ children, context }): JSX.Element => {
  const highlighterContext = useHighlighter()

  return (
    <context.Provider value={highlighterContext}>
      {children}
    </context.Provider>
  )
}

/**
 * Provider for the PDF search context over a single PDF viewer instance,
 * identified by its associated context.
 * This is not exported as it is meant only be used internally by the PdfSearchProviders component.
 */
const PdfSearchProvider: FC<ProviderProps> = ({ context, children }: ProviderProps): JSX.Element => {
  return (
    <HighlighterProvider>
      <PdfSearchContent context={context}>
        {children}
      </PdfSearchContent>
    </HighlighterProvider>
  )
}

interface PdfSearchProvidersProps {
  children: ReactNode
}

/**
 * Providers for each PDF viewer instance (primary and secondary).
 */
export const PdfSearchProviders: FC<PdfSearchProvidersProps> = ({ children }: PdfSearchProvidersProps): JSX.Element => (
  <PdfSearchProvider context={PrimaryPdfSearchContext}>
    <PdfSearchProvider context={SecondaryPdfSearchContext}>
      {children}
    </PdfSearchProvider>
  </PdfSearchProvider>
)

/**
 * Hook to get the PDF search context value for a given PDF viewer instance.
 */
export const usePdfSearch = (viewerId: PdfViewerId): PdfSearchContextValue => {
  const context = useContext(viewerId === 'primary' ? PrimaryPdfSearchContext : SecondaryPdfSearchContext)

  if (context === null) {
    throw new Error('usePdfSearch must be used within a PdfSearchProvider')
  }

  return context
}
