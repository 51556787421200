import { type UUID } from 'crypto'
import { FileUploader } from 'features/documents'
import DocumentSelect from 'features/documents/components/DocumentSelect'
import { useState, type FC } from 'react'

/**
 * Component to select a document for sanity check analysis, among the attached documents
 */
export const SanityCheckDocumentSelect: FC = (): JSX.Element => {
  // FIXME: this is a placebo component for now, we need to implement
  // the logic to select a document for sanity check analysis in the backend!
  const [attachmentId, setAttachmentId] = useState<UUID | null>(null)
  const [disabled] = useState<boolean>(false)
  const [inputLabel] = useState<string>('')

  const handleDocChange = (newId: UUID | null): void => {
    setAttachmentId(newId)
  }

  return (
    <>
      <DocumentSelect
        value={attachmentId}
        onChange={(newId) => { handleDocChange(newId) }}
        disabled={disabled}
        acceptedDocTypes={['Contract', 'Term sheet']}
        label={inputLabel}
      />
      <FileUploader iconOnly />
    </>
  )
}

export default SanityCheckDocumentSelect
