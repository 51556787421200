import { styled } from '@mui/material'
import { ModalContainer } from 'theme'

export const AuthModalContainer = styled(ModalContainer)(({ theme }) => ({
  width: '400px',
  maxHeight: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
  // Above all other elements
  zIndex: 2000,
  overflow: 'hidden',
  // Make sure the container itself can shrink
  minHeight: 0
}))
