import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, type UseMutationResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { useGlobals } from 'context/GlobalsContext'
import { type AttachmentsAndUploads, type DocType } from 'features/documents'
import { useSession } from 'features/sessions'
import { axiosInstance, createAuthHeaderFromToken } from 'services/axios'
import { queryClient } from 'services/react-query'

interface UploadAttachmentVariables {
  file: File
  docType: DocType
}

/**
 * A mutation hook to upload an attachment to the current session.
 */
export function useUploadAttachment (): UseMutationResult<PostAttachmentsResponse, Error, UploadAttachmentVariables> {
  const { getAccessTokenSilently } = useAuth0()
  const { selectedSession } = useSession()
  const { docTypes } = useGlobals()
  const docTypesKeys = Array.from(docTypes.keys())

  if (selectedSession === null) {
    throw new Error('No session selected')
  }

  const mutationFn = async ({ file, docType }: UploadAttachmentVariables): Promise<PostAttachmentsResponse> => {
    // Check the docType is valid
    if (!docTypesKeys.includes(docType)) {
      throw new Error(`Invalid docType: ${docType}`)
    }

    return await getAccessTokenSilently().then(async (token) =>
      await uploadAttachment(token, file, docType, selectedSession.id)
    )
  }

  const queryKey = ['sessions', selectedSession.id, 'attachments']

  return useMutation({
    mutationKey: ['uploadAttachment', selectedSession.id],
    mutationFn,
    onMutate: async ({ file, docType }) => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
        exact: true
      })

      // Snapshot the previous value
      const attachments = queryClient.getQueryData(queryKey) as AttachmentsAndUploads

      // Optimistically update to the new value
      const updatedAttachments: AttachmentsAndUploads = {
        uploaded: attachments.uploaded,
        pending: [
          ...attachments.pending,
          {
            taskId: undefined,
            taskUri: undefined,
            filename: file.name,
            createdAt: new Date().toISOString(),
            status: 'PENDING'
          }
        ],
        deleted: attachments.deleted
      }
      queryClient.setQueryData(queryKey, updatedAttachments)

      // Return a context object with the snapshot value
      return { previousAttachments: attachments }
    },
    onError: (error, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      console.error('Upload failed: ', error)
      if (context === undefined) {
        return
      }
      queryClient.setQueryData(queryKey, context.previousAttachments)
    },
    onSettled: () => {
      // Always refetch after error or success
      void queryClient.invalidateQueries({
        queryKey,
        exact: true
      })
    }
  })
}

interface PostAttachmentsResponse {
  taskId: string
  taskUri: string
}

interface PostAttachmentsErrorResponse {
  detail: string
}

const uploadAttachment = async (
  token: string,
  file: File,
  docType: DocType,
  sessionId: string
): Promise<PostAttachmentsResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  const authHeader = createAuthHeaderFromToken(token)
  const response = await axiosInstance.post<PostAttachmentsResponse>(
    `/sessions/${sessionId}/attachments`,
    formData,
    {
      params: { docType },
      headers: { ...authHeader, 'Content-Type': 'multipart/form-data' },
      cache: false
    }
  ).catch((error: AxiosError) => {
    // Extract the message from the error response to make it more user-friendly
    const errorResponse = error.response?.data as PostAttachmentsErrorResponse | undefined
    throw new Error(`Failed to upload attachment: ${errorResponse?.detail ?? error.message}`)
  })
  return response.data
}
