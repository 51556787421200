import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Icon from 'components/icons/feather'
import type * as React from 'react'
import { useEffect, useState } from 'react'
import { getSidepaneWidthInPx } from './types'
import useSidepanes from './SidepanesContext'
import { ICON_BUTTON_WIDTH_IN_PX } from './SidepaneToggleButton'

interface Props {
  anchor: 'left' | 'right'
}

/**
 * Renders a button that maximizes the size of the side panel,
 * or minimizes it if it's already maximized.
 */
const MaximizePaneButton: React.FC<Props> = (
  { anchor }: Props
): JSX.Element => {
  const [fullWidth, setFullWidth] = useState(false)

  const { leftPane, rightPane, openLeftPane, openRightPane } = useSidepanes()
  const pane = anchor === 'left' ? leftPane : rightPane
  const openPane = anchor === 'left' ? openLeftPane : openRightPane

  useEffect(() => {
    const newMode = fullWidth ? 'maximized' : 'standard'
    openPane(newMode)
  }, [fullWidth])

  return <Box
      className='sidepane-maximize-button'
      visibility={ anchor === 'left' ? (leftPane.isOpen ? 'visible' : 'hidden') : (rightPane.isOpen ? 'visible' : 'hidden') }
    >
      <IconButton
        onClick={() => { setFullWidth(!fullWidth) }}
        sx={{
          // Instead of the brand color (default), use the text color for the icon button,
          // and adapt the hover color accordingly. This is a bit more subtle as we don't
          // want it to be too intrusive.
          color: 'text.primary',
          '&:hover': {
            backgroundColor: 'primary.main',
            color: 'primary.contrastText'
          }
        }}
      >
        {fullWidth
          ? <Icon.Minimize2 />
          : <Icon.Maximize2 style={{ transform: anchor === 'left' ? 'none' : 'rotate(90deg)' }} />}
      </IconButton>
    </Box>
}

export default MaximizePaneButton
