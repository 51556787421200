import { Typography } from '@mui/material'
import Loading from 'components/progress/Loading'
import { type UUID } from 'crypto'
import { type AttachmentListItem } from 'features/documents'
import { useAttachments } from 'features/documents/api/getAttachments'
import { useIntl } from 'react-intl'
import DocumentCardContainer from '../DocumentCard/DocumentCardContainer'
import { DocumentGroup, StyledContainer } from './DocumentList.styles'

interface Props {
  onDocumentSelected: (id: UUID) => void
}

export const DocumentList: React.FC<Props> = ({ onDocumentSelected }: Props): JSX.Element => {
  const intl = useIntl()
  const { data: attachments } = useAttachments({ withContent: false, autoRefetch: false, includeDeleted: true })

  if (attachments === undefined) {
    return <Loading />
  }

  const uploadedGroupLabel = intl.formatMessage({
    id: 'app.document-panel.document-list.uploaded-group.label',
    defaultMessage: 'Attached files'
  })

  const deletedGroupLabel = intl.formatMessage({
    id: 'app.document-panel.document-list.deleted-group.label',
    defaultMessage: 'Other session files'
  })

  const noDocumentsMessage = intl.formatMessage({
    id: 'app.document-panel.document-list.no-documents',
    defaultMessage: 'Visualize here documents from your current working session'
  })
  const noDocuments = (
    <Typography
      variant='body1'
      sx={{
        alignSelf: 'center',
        textAlign: 'center'
      }}
    >
      {noDocumentsMessage}
    </Typography>
  )

  const documents = (items: AttachmentListItem[], isDeleted: boolean): JSX.Element => (
    <DocumentGroup>
      {items.map((a) => (
        <DocumentCardContainer
          key={a.id}
          attachmentId={a.id}
          filename={a.filename}
          createdAt={a.createdAt}
          isDeleted={isDeleted}
          onSelect={() => {
            onDocumentSelected(a.id)
          }} />
      ))}
    </DocumentGroup>
  )

  const groupTitle = (label: string): JSX.Element => (
    <Typography variant="overline" color="primary.main">{label}</Typography>
  )

  return (
    <StyledContainer>
      {attachments.uploaded.length === 0 && attachments.deleted.length === 0 && noDocuments}
      {attachments.uploaded.length > 0 && <>
        {groupTitle(uploadedGroupLabel)}
        {documents(attachments.uploaded, false)}
      </>}
      {attachments.deleted.length > 0 && <>
        {groupTitle(deletedGroupLabel)}
        {documents(attachments.deleted, true)}
      </>}
    </StyledContainer>
  )
}

export default DocumentList
