import { Box, FormControl, MenuItem, Select, useTheme, type SelectChangeEvent } from '@mui/material'
import { useIntlContext } from 'context/IntlProviderWrapper'
import { useIntl } from 'react-intl'

export const LocaleSelect: React.FC = () => {
  const intl = useIntl()
  const { localeConfig, selectLanguage } = useIntlContext()

  const handleChange = (event: SelectChangeEvent<string>): void => {
    selectLanguage(event.target.value)
  }

  const langs = ['en', 'fr']

  return (
    <Box>
      <FormControl>
        <Select
          variant='standard'
          id='locale-select'
          value={localeConfig.locale}
          onChange={handleChange}
          sx={{ width: '150px' }}
          aria-label="Language"
        >
          {
            langs.map((lang) => (
              <MenuItem key={lang} value={lang}>
                {
                  intl.formatMessage({
                    id: `app.locale-select.locale-${lang}.label`,
                    defaultMessage: lang
                  })
                }
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  )
}

export default LocaleSelect
