import { Box, Typography } from '@mui/material'
import { type UUID } from 'crypto'
import { type DocReviewIssue, type IssueType } from 'features/analyze'
import DocReviewIssuesFilters from 'features/analyze/components/DocReviewIssuesFilters'
import { useDocReviewIssuesFilter } from 'features/analyze/hooks/useDocReviewIssuesFilter'
import { type AttachmentListItem } from 'features/documents'
import { AttachmentChipContainer } from 'features/documents/components/AttachmentChip'
import { useIntl } from 'react-intl'
import { FiltersContainer, StyledBox } from './ContextualIssuesDisplay.styles'
import DocReviewIssueDisplayContainer from './DocReviewIssueDisplayContainer'

interface Props {
  typeToResults: Map<IssueType, DocReviewIssue[]>
  reviewedDoc: AttachmentListItem
  referenceDocs: AttachmentListItem[]
}

/**
 * Display the contextual issues (e.g., comparative analysis) from a doc review.
 */
const ContextualIssuesDisplay: React.FC<Props> = ({
  typeToResults,
  reviewedDoc,
  referenceDocs
}: Props): JSX.Element => {
  const intl = useIntl()

  const referenceDocsMap = new Map<UUID, AttachmentListItem>()
  referenceDocs.forEach((doc) => {
    referenceDocsMap.set(doc.id, doc)
  })

  const getReferenceDocForIssue = (issue: DocReviewIssue): AttachmentListItem | null => {
    if (referenceDocs.length === 0) {
      throw new Error('No reference docs found')
    }

    // Get all the doc ids that appear in the evidences (we don't know which ones are reference docs)
    const docIds = issue.evidences.map((evidence) => evidence.docRef?.docId ?? null).filter((id) => id !== null) as UUID[]
    // Find whether one of the doc ids has a match in the reference docs map.
    // There should be only one reference doc per issue, although we don't check for that here
    // (we would just pick the first one we find)
    const referenceDocId = docIds.find((id) => referenceDocsMap.has(id))
    const referenceDoc = referenceDocId !== undefined ? referenceDocsMap.get(referenceDocId) ?? null : null
    // There could be no reference doc for this issue, for example if the issue is
    // an addition to the reviewed doc with no evidence from a reference doc.
    // In that case, return the first reference doc
    if (referenceDoc === null) {
      return referenceDocs[0]
    }
    return referenceDoc
  }

  return (
    <div className='contextual-issues'>
      {
        Array.from(typeToResults).map(([type, results]: [IssueType, DocReviewIssue[]]) => {
          const {
            filteredIssues,
            filters,
            setType,
            setSort,
            setCriticalities,
            setNatures
          } = useDocReviewIssuesFilter(results)

          // Create a paragraph that summarizes what Copilex has done and
          // the issues it has found, including mentioning the reviewed doc and reference docs
          // formatted as attachment chips, to make them clickable.
          const description = intl.formatMessage({
            id: `app.doc-review.issue-type.${type}.description`
          }, {
            reviewedDoc: <AttachmentChipContainer attachment={reviewedDoc} docTypeInLabel={false} deletable={false} openable={true} />,
            referenceDocs: (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {
                  Array.from(referenceDocs).map((referenceDoc, idx) => {
                    return (
                      <AttachmentChipContainer key={idx} attachment={referenceDoc} docTypeInLabel={false} deletable={false} openable={true} />
                    )
                  })
                }
              </Box>
            ),
            numResults: results.length
          })

          return (
            <StyledBox className='contextual-issues-item' key={type}>
              <Typography
                className='contextual-issues-description'
                // Increase line height because of the attachment chips
                sx={{ lineHeight: 2.5 }}
              >
                {description}
              </Typography>

              <FiltersContainer className='filters-container'>
                <DocReviewIssuesFilters
                  issueCategory='Contextual'
                  sortBy={filters.sortBy}
                  type={filters.type}
                  onTypeChange={setType}
                  onSortChange={setSort}
                  onCriticalitiesChange={setCriticalities}
                  onNaturesChange={setNatures}
                />
              </FiltersContainer>

              <StyledBox className='doc-review-issues'>
              {
                filteredIssues.map((result) => {
                  const idx = filteredIssues.indexOf(result)
                  // Find which ones of the reference document
                  // was used in this result
                  const referenceDoc = getReferenceDocForIssue(result)
                  return (
                    <div className='doc-review-issue-item' key={idx}>
                      <DocReviewIssueDisplayContainer
                        idx={idx}
                        result={result}
                        reviewedDoc={reviewedDoc}
                        referenceDoc={referenceDoc}
                      />
                    </div>
                  )
                })
              }
              </StyledBox>
            </StyledBox>
          )
        })
      }
    </div>
  )
}

export default ContextualIssuesDisplay
