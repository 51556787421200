import { useDisclosure } from 'hooks/useDisclosure'
import { createContext, useContext } from 'react'

interface ReportModalContextValue {
  isOpen: boolean
  open: () => void
  close: () => void
}

const ReportModalContext = createContext<ReportModalContextValue | undefined>(undefined)

interface Props {
  children: React.ReactNode
}

export const ReportModalProvider = ({ children }: Props): JSX.Element => {
  const { isOpen, open, close } = useDisclosure()

  return (
    <ReportModalContext.Provider
      value={{
        isOpen,
        open,
        close
      }}
    >
      {children}
    </ReportModalContext.Provider>
  )
}

export const useReportModal = (): ReportModalContextValue => {
  const context = useContext(ReportModalContext)
  if (context === undefined) {
    throw new Error('useReportModal must be used within a ReportModalProvider')
  }
  return context
}
