import { type SxProps, type Theme, styled, Box, ListItem, alpha } from '@mui/material'

interface StyledListItemProps {
  selected: boolean
}

export const StyledListItem = styled(ListItem)<StyledListItemProps>(({ theme, selected }) => ({
  // This ensures that the height will not change depending on whether
  // the item is selected or not
  minHeight: '100px',
  gap: theme.spacing(1),
  // For some reason, the background color is not applied when selected
  // so we need to use !important to override the default behavior
  backgroundColor: selected ? `${theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800]} !important` : 'none',
  color: 'inherit',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]
  },
  // Make all child MuiTypography inherit the color
  '& .MuiTypography-root': { color: 'inherit' }
}))

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[200]
  }
}))
