import { type UUID } from 'crypto'
import { type Evidence } from 'features/analyze/types'

/**
 * Separate the evidence for the reference document and the reviewed document,
 * and construct a list of tuples so that they can be displayed side by side.
 */
export const createEvidencePairs = (reviewedDocId: UUID | null, referenceDocId: UUID | null, evidences: Evidence[]): Array<[Evidence | null, Evidence | null]> => {
  // If reviewed and reference document ids are not defined,
  // all evidences are considered to be for the reviewed document
  if (reviewedDocId === null || referenceDocId === null) {
    return evidences.map((evidence) => [evidence, null])
  }

  const reviewedDocEvidences = reviewedDocId !== null ? evidences.filter((evidence) => evidence.docRef?.docId === reviewedDocId) : []
  const referenceDocEvidences = referenceDocId !== null ? evidences.filter((evidence) => evidence.docRef?.docId === referenceDocId) : []

  const pairs: Array<[Evidence | null, Evidence | null]> = []
  for (let i = 0; i < Math.max(reviewedDocEvidences.length, referenceDocEvidences.length); i++) {
    const reviewedDocEvidence = reviewedDocEvidences[i] ?? null
    const referenceDocEvidence = referenceDocEvidences[i] ?? null
    pairs.push([reviewedDocEvidence, referenceDocEvidence])
  }

  return pairs
}

export default createEvidencePairs
