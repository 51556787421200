import { ExpandMore } from '@mui/icons-material'
import { createTheme, responsiveFontSizes, type Theme, type ThemeOptions } from '@mui/material/styles'
import { type CSSProperties } from '@mui/material/styles/createTypography'
import { type ResponsiveFontSizesOptions } from '@mui/material/styles/responsiveFontSizes'
import colors, { hoverColor, markBgColor } from './colors'
import './types'
import typography, { textMdMedium, textMdRegular, textSmMedium, textSmRegular } from './typography'

// Default border radius for components, in pixels
const defaultBorderRadius = 8

export const getThemeOptions = (colorMode: 'light' | 'dark'): ThemeOptions => ({
  palette: {
    mode: colorMode,
    primary: {
      main: colors.brand[600],
      light: colors.brand[500],
      dark: colors.brand[700],
      contrastText: colors.base.white
    },
    secondary: {
      main: colors.warning[500],
      light: colors.warning[200],
      dark: colors.warning[700],
      contrastText: colors.base.white
    },
    error: {
      main: colors.error[500],
      light: colors.error[200],
      dark: colors.error[700],
      contrastText: colors.base.white
    },
    warning: {
      main: colors.warning[500],
      light: colors.warning[200],
      dark: colors.warning[700],
      contrastText: colors.base.white
    },
    success: {
      main: colors.success[500],
      light: colors.success[200],
      dark: colors.success[700],
      contrastText: colors.base.white
    },
    grey: colors.gray,
    background: {
      default: colorMode === 'light' ? colors.base.white : colors.gray[900],
      paper: colorMode === 'light' ? colors.gray[50] : colors.gray[950]
    },
    text: {
      primary: colorMode === 'light' ? colors.gray[900] : colors.gray[100],
      secondary: colorMode === 'light' ? colors.gray[600] : colors.gray[400]
    }
  },
  typography,
  shape: {
    // Buttons, text fields, etc. will be rounded with this border radius
    borderRadius: defaultBorderRadius
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // Added that for aesthetic reasons, to make text look more like in Figma,
        // but I'm not sure it actually changes anything...
        body: {
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          textRendering: 'optimizeLegibility'
        },
        a: {
          // Change the color of links in dark mode for better contrast
          ...(colorMode === 'dark' && {
            color: colors.brand[500],
            '&:hover': {
              color: colors.brand[600]
            },
            '&:active': {
              color: colors.brand[50]
            },
            '&:visited': {
              color: colors.brand[700]
            }
          })
        },
        mark: {
          backgroundColor: markBgColor,
          borderRadius: '4px',
          // Animation to help the user find it when the text is small
          animation: 'hint 2s ease-out forwards',
          '@keyframes hint': {
            '0%': {
              backgroundColor: 'yellow'
            },
            '100%': {
              backgroundColor: markBgColor
            }
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variant: 'body2'
      }
    },
    MuiTooltip: {
      defaultProps: {
        // Increase the delay after which the tooltip appears. The default is too short (100ms)
        enterDelay: 500, // in milliseconds
        arrow: true,
        placement: 'top'
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: colorMode === 'light' ? colors.gray[900] : colors.gray[100],
          color: colorMode === 'light' ? colors.base.white : colors.gray[900],
          // Above all other elements
          zIndex: 3000
        },
        arrow: {
          color: colorMode === 'light' ? colors.gray[900] : colors.gray[100],
          // Above all other elements
          zIndex: 3000
        }
      }
    },
    // MuiInputBase is a base for other components like TextField and Select
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...textMdRegular
        },
        formControl: {
          // Hack to make placeholder text visible even when not focused
          // https://github.com/mui/material-ui/issues/8436#issuecomment-1911221127
          'label[data-shrink=false].MuiFormLabel-root ~ & ::-webkit-input-placeholder': {
            opacity: '0.5!important',
            color: colorMode === 'light' ? colors.gray[500] : colors.gray[300]
          }
        }
      }
    },
    MuiButton: {
      // Override default styles for MuiButton component
      styleOverrides: {
        // Apply styles to the root element of the button
        root: {
          // Set padding to a bit tighter than the default
          padding: '8px 12px',
          // Styles specific to contained variant buttons
          '&.MuiButton-contained': {
            // Make button position relative for pseudo-element positioning
            position: 'relative',
            // Set main button color
            backgroundColor: colors.brand[600],
            // Remove default border
            border: 'none',
            // Set text color to white. Can be hardcoded to white
            // because it will contrast with the fixed background color
            color: colors.base.white,
            // Set that same color for icons
            '& .MuiButton-startIcon, & .MuiButton-endIcon': {
              color: colors.base.white
            },
            // Create gradient border effect using pseudo-element
            '&::before': {
              content: '""',
              position: 'absolute',
              inset: 0,
              borderRadius: `${defaultBorderRadius}px`,
              padding: '1px',
              // Gradient for border effect
              background: 'linear-gradient(180deg, rgba(62, 124, 172, 0.5) 0%, rgba(37, 103, 154, 0.5) 100%)',
              // Mask properties to create border effect
              WebkitMask:
                'linear-gradient(#fff 0 0) content-box, ' +
                'linear-gradient(#fff 0 0)',
              WebkitMaskComposite: 'xor',
              maskComposite: 'exclude',
              zIndex: 0
            },
            // Create an inset border
            '&::after': {
              content: '""',
              position: 'absolute',
              inset: '1px',
              borderRadius: `${defaultBorderRadius - 1}px`,
              backgroundColor: colors.brand[600],
              zIndex: -1
            },
            // Hover state styles
            '&:hover': {
              backgroundColor: hoverColor,
              '&::after': {
                backgroundColor: hoverColor
              }
            },
            // Disabled state styles
            '&.Mui-disabled': {
              backgroundColor: colors.gray[100],
              // Remove the direct border and keep using pseudo-elements
              border: 'none',
              '&::before': {
                // Keep the pseudo-element but change its color
                background: `${colors.gray[200]}`
              },
              '&::after': {
                // Keep the pseudo-element but change its color
                backgroundColor: colors.gray[100],
                display: 'block' // Ensure it's visible
              },
              color: colors.gray[400],
              '& .MuiButton-startIcon, & .MuiButton-endIcon': {
                color: colors.gray[400]
              }
            }
          },
          '&.MuiButton-outlined': {
            // Set that same color for icons
            '& .MuiButton-startIcon, & .MuiButton-endIcon': {
              color: colors.brand[500]
            },
            // Disabled state styles
            '&.Mui-disabled': {
              color: colors.gray[300],
              '& .MuiButton-startIcon, & .MuiButton-endIcon': {
                color: colors.gray[300]
              }
            }
          }
        }
      },
      // Remove default elevation/shadow
      defaultProps: {
        disableElevation: true
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: colors.gray[500],
          '&.Mui-selected': {
            color: colorMode === 'light' ? colors.gray[900] : colors.gray[100]
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        // Replace the default icon with the ExpandMore icon, which we like more
        IconComponent: ExpandMore
      },
      styleOverrides: {
        root: {
          // Typography is not applied to the root element of the Select component
          // so we need to apply it manually
          ...textMdMedium,
          // Override the default icon color, to make it more subtle
          '& .MuiSelect-icon': selectIconStyle(colorMode),
          // Remove underline. While this can be done more simply using the disableUnderline prop,
          // it creates a warning from react-dom in the console.
          '&:before': { borderBottom: 'none' },
          '&:after': { borderBottom: 'none' },
          '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' }
        },
        standard: {
          // When underline is removed, bottom padding must be removed to make it look centered
          paddingBottom: 0
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...textMdMedium,
          padding: '8px 8px'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined'
      },
      styleOverrides: {
        root: {
          ...textSmMedium,
          // Label above the text input
          '& .MuiInputLabel-root': {
            // TODO: font size!
            // Position the label above the input like in standard variant
            transform: 'none',
            position: 'relative',
            marginBottom: '8px',
            color: colorMode === 'light' ? colors.gray[700] : colors.gray[300],
            '&.Mui-focused': {
              color: colorMode === 'light' ? colors.gray[700] : colors.gray[300]
            }
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...textSmRegular
        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        // Remove the notch from the text field (e.g. label above the input
        // and over the outline border)
        // We don't need it because we're putting the label above the input instead.
        notched: false
      },
      styleOverrides: {
        root: {
          ...textMdRegular,
          '&.Mui-disabled': {
            color: colorMode === 'light' ? colors.gray[300] : colors.gray[500],
            backgroundColor: colorMode === 'light' ? colors.gray[50] : colors.gray[900]
          }
        }
      }
    },
    MuiSwitch: {
      // iOS-inspired switch style
      styleOverrides: {
        root: switchStyle(colorMode, colors.brand[600], hoverColor)
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // Square icon buttons
          borderRadius: 0,
          color: colors.brand[500],
          fill: colors.brand[500],
          '&.Mui-focused': {
            backgroundColor: colors.brand[700]
          },
          '&.Mui-disabled': {
            color: colors.gray[300],
            fill: colors.gray[300]
          }
        },
        sizeSmall: {
          '& .MuiSvgIcon-root': {
            fontSize: '16px'
          }
        },
        // sizeMedium is the default size
        sizeLarge: {
          '& .MuiSvgIcon-root': {
            fontSize: '32px'
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          // Reduce horizontal padding
          padding: '0px 8px',
          // Reduce content gutters margin, and will be the same whether expanded or not
          '& .MuiAccordionSummary-content': {
            margin: '8px 0px'
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '8px 8px'
        }
      }
    }
  }
})

export const selectIconStyle = (colorMode: 'light' | 'dark'): CSSProperties => ({
  color: colorMode === 'light' ? colors.gray[300] : colors.gray[600]
})

export const switchStyle = (colorMode: 'light' | 'dark', bgColor: string, bgHoverColor: string): CSSProperties => ({
  width: '36px',
  height: '20px',
  padding: 0,
  borderRadius: '12px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: colors.base.white,
      '& + .MuiSwitch-track': {
        backgroundColor: bgColor,
        opacity: 1,
        border: 0
      },
      '&:hover + .MuiSwitch-track': {
        backgroundColor: bgHoverColor
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      // Show a big brand color dot at the center of the thumb when focused
      color: bgHoverColor,
      border: `4px solid ${colors.base.white}`
    },
    // Disabled state styles
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: colorMode === 'light' ? colors.gray[100] : colors.gray[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: colorMode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    borderRadius: '9999px',
    boxShadow: '0 2px 4px rgba(#0A0D12,0.1)'
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    backgroundColor: colorMode === 'light' ? colors.gray[200] : colors.gray[700],
    opacity: 1,
    transition: 'background-color 500ms'
  }
})

const responsiveFontSizesOptions: ResponsiveFontSizesOptions = {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
  factor: 2.0,
  disableAlign: true,
  variants: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'body2', 'button', 'caption', 'overline']
}

export const getTheme = (colorMode: 'light' | 'dark'): Theme => (
  responsiveFontSizes(createTheme(getThemeOptions(colorMode)), responsiveFontSizesOptions)
)

export default getTheme
