import { useGlobals } from 'context/GlobalsContext'
import { useSession } from 'features/sessions'
import { type ConfidentialityLevel, type ConfidentialityLevelNumber } from 'features/globals/types'
import { useUpdateSession } from 'features/sessions/api/updateSession'
import { useEffect, useState } from 'react'

const useConfidentialitySettings = (): {
  selectedLevel: ConfidentialityLevel | null
  levels: ConfidentialityLevel[]
  setLevelNum: (newLevelNum: number) => void
} => {
  const { selectedSession: session } = useSession()
  const { mutate } = useUpdateSession()
  const globals = useGlobals()

  const [level, setLevel] = useState<ConfidentialityLevel | null>(null)

  useEffect(() => {
    if (session === null) return

    // Use session-specific confidentiality level if available,
    // otherwise use the user-specific level if available,
    // otherwise use the default level from globals.
    const levelNum: ConfidentialityLevelNumber = (
      session.settings?.confidentialityLevel ?? globals?.defaultLevel.level
    )

    // Find the level object from the level number
    let level = globals.levels.find((l) => l.level === levelNum)
    if (level === undefined) {
      console.error(`Confidentiality level ${levelNum} not found!`)
      // Fallback to default level
      level = globals.defaultLevel
    }

    setLevel(level)
  }, [globals, session])

  if (session === null) {
    throw new Error('Session not set!')
  }

  const setLevelNum = (newLevelNum: number): void => {
    const newLevel = globals.levels.find((l) => l.level === newLevelNum)
    if (newLevel === undefined) throw new Error(`Level ${newLevelNum} not found!`)

    const sessionUpdate = { settings: { ...session.settings, confidentialityLevel: newLevel.level } }
    mutate({ sessionId: session.id, update: sessionUpdate })
  }

  return { selectedLevel: level, levels: globals.levels, setLevelNum }
}

export default useConfidentialitySettings
