import { Paper, Typography } from '@mui/material'
import { FloatingViewIcon } from 'components/icons/FloatingViewIcon'
import { type Evidence } from 'features/analyze'
import { EvidenceLocation } from 'features/analyze/components/evidence/EvidenceLocation'
import { useHovered } from 'hooks/useHovered'
import { CellContainer } from './EvidenceDisplay.styles'

interface Props {
  evidence: Evidence
  displayLocation?: boolean
  onClick?: () => void
}

const EvidenceDisplay = ({ evidence, displayLocation = true, onClick }: Props): JSX.Element => {
  const { isHovered: isEvidenceHovered, containerProps: hoveredContainerProps } = useHovered()

  const viewIcon = isEvidenceHovered && <FloatingViewIcon placement='top-right' />

  return (
    <CellContainer>
      {displayLocation && <EvidenceLocation evidence={evidence} />}
      <Paper
        sx={{
          padding: 1,
          margin: 0,
          border: 1,
          cursor: 'pointer',
          ...(isEvidenceHovered && {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s ease'
          })
        }}
        onClick={onClick}
        {...hoveredContainerProps}
      >
        {viewIcon}
        {/* Display the quote, which can have multiple lines,
            and may have a part that should be highlighted */}
        <Typography variant='body1' whiteSpace={'pre-wrap'}>
          {
            evidence.markedSpan !== null
              ? (
                <>
                  {evidence.quote.substring(0, evidence.markedSpan[0])}
                  <span style={{ backgroundColor: 'yellow' }}>
                    {evidence.quote.substring(evidence.markedSpan[0], evidence.markedSpan[1])}
                  </span>
                  {evidence.quote.substring(evidence.markedSpan[1])}
                </>
                )
              : (
                  evidence.quote
                )
          }
        </Typography>
      </Paper>
    </CellContainer>
  )
}

export default EvidenceDisplay
