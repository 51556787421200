import { useAuth0 } from '@auth0/auth0-react'
import { useAuth } from 'context/AuthContext'
import { useGlobals } from 'context/GlobalsContext'
import { localeToLanguageName } from 'context/IntlProviderWrapper'
import { useProgressBackdrop } from 'context/ProgressContext'
import { useSession } from 'features/sessions'
import { useUserSettings } from 'context/UserSettingsContext'
import { anonymize, type AnonymizeResponse } from 'features/anonymization/api/anonymize'
import { mustReview } from 'features/anonymization/api/checkSensitive'
import { type Entity } from 'features/anonymization/types'
import { getAssistantId } from 'features/assistants'
import { useAttachments } from 'features/documents'
import { type ConfidentialityLevel } from 'features/globals/types'
import { sendAndFetchResponse } from 'features/messages/api/sendMessage'
import { type Message } from 'features/messages/types'
import { type SessionSettings } from 'features/sessions'
import { useUserInputForm } from 'features/user-input-form'
import { type UserInputForm } from 'features/user-input-form/types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMessages } from './useMessages'
import useErrorModal from 'context/ErrorModalContext'

interface UseReviewModalReturn {
  reviewModalOpen: boolean
  reviewModalInitResponse: AnonymizeResponse | undefined
  setReviewModalOpen: (open: boolean) => void
  setReviewModalInitResponse: (response: AnonymizeResponse | undefined) => void
}

export const useReviewModal = (): UseReviewModalReturn => {
  const [reviewModalOpen, setReviewModalOpen] = useState<boolean>(false)
  const [reviewModalInitResponse, setReviewModalInitResponse] = useState<AnonymizeResponse | undefined>(undefined)

  return {
    reviewModalOpen,
    reviewModalInitResponse,
    setReviewModalOpen,
    setReviewModalInitResponse
  }
}

interface UseChatMessagesProps {
  sessionId: string | undefined
}

interface UseChatMessagesReturn {
  messages: Message[]
  input: string
  attachmentIds: string[]
  existingEntities: Entity[]
  processingInput: boolean
  reviewModalOpen: boolean
  reviewModalInitResponse: AnonymizeResponse | undefined
  onSubmitInput: (
    input: string,
    attachmentIds: string[],
    confidentialityLevel: ConfidentialityLevel,
    userInputFormKwargs: Partial<UserInputForm>
  ) => void
  onReviewSubmitted: (anonTexts: string[], attachmentIds: string[], existingEntities: Entity[]) => void
  onReviewSkipped: (texts: string[]) => void
  onReviewCancelled: (prevInput: string) => void
  onCorrectionSubmitted: (sessionId: string, idx: number, correction: string) => void
  onFeedbackSubmitted: (sessionId: string, idx: number, feedback: number) => void
}

const initAgentStatus = 'Waiting for input'

/**
 * Hook to handle the interactions with a given chat session.
 */
export const useChatMessages = ({ sessionId }: UseChatMessagesProps): UseChatMessagesReturn => {
  const intl = useIntl()
  const currentUser = useAuth()
  const { getAccessTokenSilently } = useAuth0()
  const globals = useGlobals()
  const { progressState, showProgress, closeProgress } = useProgressBackdrop()
  const { showError } = useErrorModal()

  const { selectedSession: session } = useSession()
  const userSettings = useUserSettings()
  const { userInputForm, setUserInputForm } = useUserInputForm()
  const { refetch: refetchAttachments } = useAttachments({
    withContent: true,
    autoRefetch: false
  })

  const { messages, setMessages, onCorrectionSubmitted, onFeedbackSubmitted } = useMessages({ sessionId })
  const { reviewModalOpen, reviewModalInitResponse, setReviewModalOpen, setReviewModalInitResponse } = useReviewModal()

  const [input, setInput] = useState<string>('')
  const [attachmentIds, setAttachmentIds] = useState<string[]>([])
  const [agentStatus, setAgentStatus] = useState<string>(initAgentStatus)
  const [processingInput, setProcessingInput] = useState<boolean>(false)
  const [abortController, setAbortController] = useState<AbortController | null>(null)

  // When messages change (i.e., a new message received),
  // reset the user input form to its default values.
  useEffect(() => {
    console.debug('> useChatMessages [messages]', messages)

    // Reset the user input form to its default values.
    setUserInputForm((prevForm): Partial<UserInputForm> => ({
      ...prevForm,
      mainTask: 'Chat',
      taskParams: undefined
    }))
  }, [messages])

  useEffect(() => {
    const uiUserLanguage = localeToLanguageName[intl.locale]

    // Reset the user input form to its default values.
    setUserInputForm((prevForm): Partial<UserInputForm> => ({
      ...prevForm,
      userLanguage: uiUserLanguage
    }))
  }, [intl.locale])

  /**
   * Show the progress backdrop when the review modal is processing.
   * The abortController may be set after the progress modal is opened,
   * so it is included in the dependency array to call again the showProgress function
   * with the correct cancelable state.
   */
  useEffect(() => {
    if (processingInput && !reviewModalOpen) {
      // FIXME: Ugly guessing whether we are analyzing the input
      // for anonymization (no specific message to show),
      // or running the assistant (show agentStatus as the message).
      const msg = agentStatus !== initAgentStatus && agentStatus !== 'Done' ? agentStatus : undefined
      const cancelable = abortController !== null
      showProgress(msg, cancelable)
    } else if (!processingInput) {
      closeProgress()
    }
  }, [processingInput, abortController])

  /**
   * If the progress backdrop was closed by the user, abort the request.
   */
  useEffect(() => {
    if (!progressState.isOpen && progressState.canceled && abortController !== null) {
      abortController.abort()
    }
  }, [progressState])

  const existingEntities = (
    messages.length > 0
      ? messages[messages.length - 1].existingEntities ?? []
      : []
  )

  const onReviewSubmitted = (anonTexts: string[], attachmentIds: string[], existingEntities: Entity[]): void => {
    setReviewModalOpen(false)
    setProcessingInput(true)
    void (async function () {
      await sendMessage(input, anonTexts, attachmentIds, existingEntities, userInputForm)
      setInput('') // Clear the input
    })()
  }

  const submitWithoutAnonymizationConfirmation = intl.formatMessage({
    id: 'app.home-page.submit-without-anon-confirmation',
    defaultMessage: 'Submit input WITHOUT anonymization?'
  })

  const onReviewSkipped = (texts: string[]): void => {
    if (window.confirm(submitWithoutAnonymizationConfirmation)) {
      setReviewModalOpen(false)
      setProcessingInput(true)
      void (async function () {
        await sendMessage(input, texts, attachmentIds, existingEntities, userInputForm)
        setInput('') // Clear the input
      })()
    }
  }

  const onReviewCancelled = (prevInput: string): void => {
    setReviewModalOpen(false)
    setProcessingInput(false)
    setInput(prevInput) // Restore the input (was cleared upon submitting for review)
  }

  /**
   * Called when the user submits a message.
   */
  const onSubmitInput = (
    input: string,
    attachmentIds: string[],
    confidentialityLevel: ConfidentialityLevel,
    userInputFormKwargs: Partial<UserInputForm>
  ): void => {
    if (userSettings === null) throw new Error('User settings not set!')
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')

    // FIXME: do we need those, since we are managing those states here?
    setInput(input)
    setAttachmentIds(attachmentIds)
    setUserInputForm(userInputFormKwargs)

    setProcessingInput(true)

    void (async function () {
      const token = await getAccessTokenSilently()
      // Check if the input must be reviewed, or can be sent directly
      mustReview(token, input, attachmentIds, confidentialityLevel, userSettings.anonymizationSettings, currentUser).then(async (mustReview) => {
        if (mustReview) {
          // Run anonymization and open the review modal

          // Set up an abort controller, in case the user whats to
          // cancels the processing before it's done.
          const abortController = new AbortController()
          setAbortController(abortController)

          anonymize(
            token,
            input,
            attachmentIds,
            userSettings.anonymizationSettings,
            [], // No message-specific rules at first
            [],
            existingEntities,
            currentUser,
            abortController
          ).then((response) => {
            setReviewModalInitResponse(response)
            setReviewModalOpen(true)
          }).catch((error) => {
            console.error(error)
          }).finally(() => {
            setProcessingInput(false)
            setAbortController(null)
          })
        } else {
          // Send the cleartext input directly.

          // Must get the attachments text, if any.
          // FIXME: should rather have the backend
          // load the attachments text itself, rather than
          // doing this back and forth.
          const attachmentsTexts = (
            attachmentIds.length > 0
              ? await getAttachmentsTexts(attachmentIds)
              : []
          )
          // Sanity check
          if (attachmentsTexts.length !== attachmentIds.length) {
            throw new Error(
              `Number of attachments text (${attachmentsTexts.length}) does not match ` +
              `the number of attachment IDs (${attachmentIds.length})!`
            )
          }

          await sendMessage(
            input,
            [input, ...attachmentsTexts],
            attachmentIds,
            existingEntities,
            userInputFormKwargs
          )
          setInput('') // Clear the input
        }
      }).catch((error) => {
        console.error(error)
        // TODO pop up an error message
        setProcessingInput(false)
      })
    })()
  }

  /**
   * Get the text of the attachments with the given IDs,
   * which should be uploaded attachments.
   */
  const getAttachmentsTexts = async (attachmentIds: string[]): Promise<string[]> => {
    if (attachmentIds.length === 0) {
      return []
    }
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')

    // Refetch attachments to ensure we have the latest data,
    // since auto-refetch is disabled.
    const { data: upToDateAttachments, isSuccess } = await refetchAttachments()
    if (!isSuccess) {
      throw new Error('Failed to refetch attachments!')
    }
    if (upToDateAttachments === undefined) {
      throw new Error('Attachments not loaded!')
    }

    // Remove any duplicate IDs
    const uniqueAttachmentIds = Array.from(new Set(attachmentIds))

    const filteredUploads = upToDateAttachments.uploaded.filter((a) => uniqueAttachmentIds.includes(a.id))
    if (filteredUploads.length !== uniqueAttachmentIds.length) {
      throw new Error(
        `Number of filtered uploaded attachments (${filteredUploads.length}) does not match ` +
        `the number of attachment IDs (${uniqueAttachmentIds.length})!`
      )
    }

    const texts = filteredUploads.map((a) => a.text)
    // Make sure that none of them are null
    if (texts.some((t) => t === null)) {
      throw new Error('Some returned attachments do not have text!')
    }

    return texts as string[]
  }

  const sendMessage = async (
    input: string,
    anonTexts: string[],
    attachmentIds: string[],
    existingEntities: Entity[],
    userInputFormKwargs?: Partial<UserInputForm>
  ): Promise<void> => {
    if (userSettings === null) throw new Error('User settings not set!')
    if (sessionId === undefined || sessionId === null) throw new Error('Session ID not set!')
    if (session === null) throw new Error('Session not set!')

    let assistantId = getAssistantId(session, globals)

    // Double-check that the copilex assistant IS selected
    // whenever there is a main task set in the user input form,
    // except for the "Chat" task that authorizes any assistant.
    // Normally, the assistant should be updated whenever the main task
    // changes through the UI interface, but this is a safety check.
    if (
      userInputForm?.mainTask !== null &&
      userInputForm?.mainTask !== undefined &&
      userInputForm?.mainTask !== 'Chat' &&
      !assistantId.startsWith('copilex')
    ) {
      console.error(
        `The copilex assistant MUST be selected for a main task
        other than "Chat" in the user input form, but got assistant ID: ${assistantId}
        and main task: ${userInputForm?.mainTask ?? '(undefined).'}`
      )
      assistantId = 'copilex'
    }

    // First element of anonTexts is the anonymized user input
    const anonMsg = anonTexts[0]
    // The rest are the anonymized attachments' content
    const anonAttachmentContents = anonTexts.slice(1)

    const confidentialityLevel = globals.defaultLevel.level
    const sessionSettings: SessionSettings = {
      assistantId,
      confidentialityLevel
    }
    console.debug('sendMessage: sessionSettings=', sessionSettings)
    const params = {
      cleartextMsg: input,
      anonMsg,
      anonAttachmentContents,
      attachmentIds,
      existingEntities,
      sessionSettings,
      userInputFormKwargs
    }
    setAgentStatus('Processing')
    void getAccessTokenSilently().then(async (token) => {
      await sendAndFetchResponse(
        token,
        currentUser,
        sessionId,
        params,
        onStep,
        onDone,
        onError
      )
      setProcessingInput(false)
    })
  }

  const onStep = (step: string): void => {
    console.debug('step :', step)
    setAgentStatus(step)
  }

  const onDone = (userMsg: Message, assistantMsg: Message): void => {
    console.debug('done: userMsg=', userMsg)
    console.debug('done: assistantMsg=', assistantMsg)
    setMessages([...messages, userMsg, assistantMsg])
    setAgentStatus('Done')
  }

  const onError = (): void => {
    showError()
  }

  return {
    messages,
    input,
    attachmentIds,
    existingEntities,
    processingInput,
    reviewModalOpen,
    reviewModalInitResponse,
    onSubmitInput,
    onReviewSubmitted,
    onReviewSkipped,
    onReviewCancelled,
    onCorrectionSubmitted,
    onFeedbackSubmitted
  }
}
