import { type UUID } from 'crypto'

interface DraftChange {
  before: string
  after: string
}

interface DraftChanges {
  changes: DraftChange[]
}

enum DraftTaskAction {
  TEMPLATE_FILLING = 'Template filling'
}

interface DraftTaskParams {
  action: DraftTaskAction
  templateId: UUID | null
  sourceIds: UUID[] | null
}

export type { DraftChange, DraftChanges, DraftTaskParams }
export { DraftTaskAction }
