import { Typography } from '@mui/material'
import { ViewIconButton } from 'components/icons/FloatingViewIcon'
import ComparativeAnalysisNatureDisplay from 'features/analyze/components/ComparativeAnalysisNatureDisplay'
import CorrectionsDisplay from 'features/analyze/components/CorrectionsDisplay'
import CriticalityDisplay from 'features/analyze/components/CriticalityDisplay'
import { IssueAccordion, IssueAccordionDetails, IssueAccordionIconsContainer, IssueAccordionSummary, IssueAccordionSummaryContainer } from 'features/analyze/components/IssueAccordion'
import { type ComparativeAnalysisNature, type Correction, type DocReviewIssue, type Evidence } from 'features/analyze/types'
import { type AttachmentListItem } from 'features/documents'
import ComparisonOverview from './ComparisonOverview'
import { TitleAndExplanationContainer } from './DocReviewIssueDisplay.styles'

interface Props {
  idx: number
  result: DocReviewIssue
  reviewedDoc: AttachmentListItem | null
  referenceDoc: AttachmentListItem | null
  onSummaryClick?: () => void
  onEvidenceClick?: (evidence: Evidence, doc: AttachmentListItem) => void
  onChange?: (event: React.SyntheticEvent, isExpanded: boolean) => void
}

/**
 * Presentational component for a single DocReviewIssue.
 */
const DocReviewIssueDisplay: React.FC<Props> = ({
  idx,
  result,
  reviewedDoc,
  referenceDoc,
  onSummaryClick,
  onEvidenceClick,
  onChange
}: Props) => {
  const titleAndExplanation = (
    <Typography>
      {`${idx + 1}. `}
      <b>{result.title ?? 'Deviation'}{': '}</b>
      {result.explanation}
    </Typography>
  )

  const reasoning = (
    result.reasoning !== null && <Typography variant="body1" sx={{ fontStyle: 'italic' }}>{result.reasoning}</Typography>
  )

  // Handler to prevent expansion when clicking on the view icon butt
  const handleViewIconButtonClick = (event: React.MouseEvent): void => {
    event.stopPropagation()
    onSummaryClick?.()
  }

  const viewIconButton = (
    <ViewIconButton
      onClick={handleViewIconButtonClick}
    />
  )

  return (
    <IssueAccordion
      className={`doc-review-issue-${idx + 1}`}
      onChange={onChange}
    >
      <IssueAccordionSummary
        aria-controls={`doc-review-issue-${idx + 1}-details`}
        id={`doc-review-issue-${idx + 1}-summary`}
      >
        <IssueAccordionSummaryContainer>
          <IssueAccordionIconsContainer>
            <CriticalityDisplay criticality={result.criticality} />
            <ComparativeAnalysisNatureDisplay nature={result.nature as ComparativeAnalysisNature | null} />
          </IssueAccordionIconsContainer>
          <TitleAndExplanationContainer>
            {titleAndExplanation}
          </TitleAndExplanationContainer>
          {viewIconButton}
        </IssueAccordionSummaryContainer>
      </IssueAccordionSummary>
      <IssueAccordionDetails className={`doc-review-issue-${idx + 1}-details`}>
        {reasoning}
        <ComparisonOverview
          evidences={result.evidences}
          reviewedDoc={reviewedDoc}
          referenceDoc={referenceDoc}
          onEvidenceClick={onEvidenceClick}
        />
        {
          ((result.corrections?.length ?? 0) > 0) && <CorrectionsDisplay corrections={result.corrections as Correction[]} />
        }
      </IssueAccordionDetails>
    </IssueAccordion>
  )
}

export default DocReviewIssueDisplay
