import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Typography } from '@mui/material'
import Icon from 'components/icons/feather'
import Loading from 'components/progress/Loading'
import { checkExportModifiedAttachment, exportModifiedAttachment } from 'features/export-document/api/exportModifiedAttachment'
import saveAs from 'file-saver'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

interface ExportModifiedDocButtonProps {
  sessionId: string
  messageIdx: number
}

/**
 * Button to export a modified document based on the results of an analysis or drafting.
 */
export const ExportModifiedDocButton: React.FC<ExportModifiedDocButtonProps> = ({
  sessionId,
  messageIdx
}: ExportModifiedDocButtonProps) => {
  const intl = useIntl()
  const { getAccessTokenSilently } = useAuth0()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [canExport, setCanExport] = useState<boolean>(false)
  const [isSupportedFileType, setIsSupportedFileType] = useState<boolean>(false)

  /**
   * Check if the export of a modified attachment is available.
   */
  useEffect(() => {
    const checkExport = async (): Promise<void> => {
      try {
        const token = await getAccessTokenSilently()
        const response = await checkExportModifiedAttachment(token, sessionId, messageIdx)
        setCanExport(response.canExport)
        setIsSupportedFileType(response.isSupportedFileType ?? false)
      } catch (error) {
        console.error('Error checking export availability:', error)
        setError(intl.formatMessage({
          id: 'app.export-modified-doc-button.unavailable',
          defaultMessage: 'Export not available'
        }))
      }
    }
    void checkExport()
  }, [sessionId, messageIdx, getAccessTokenSilently])

  const handleClick = (): void => {
    // If there is an error, do not attempt to export.
    // We do this instead of disabling the button to have
    // the button colored in red.
    if (error !== null) {
      return
    }

    void handleExport()
  }

  const handleExport = async (): Promise<void> => {
    try {
      setIsLoading(true)
      setError(null)
      const token = await getAccessTokenSilently()
      const { blob, filename } = await exportModifiedAttachment(token, sessionId, messageIdx)
      saveAs(blob, `Copilex changes - ${filename}`)
    } catch (error) {
      console.error('Error exporting modified document:', error)
      setError(intl.formatMessage({
        id: 'app.export-modified-doc-button.error',
        defaultMessage: 'Failed to export document'
      }))
    } finally {
      setIsLoading(false)
    }
  }

  const exportingLabel = intl.formatMessage({
    id: 'app.export-modified-doc-button.loading',
    defaultMessage: 'Exporting...'
  })

  const ExportInProgress = (
    <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 1 }}>
      {/* Make it small to keep the button height consistent */}
      <Loading size={16} />
      <Typography variant="body2">
        {exportingLabel}
      </Typography>
    </Box>
  )

  const unsupportedFileTypeLabel = intl.formatMessage({
    id: 'app.export-modified-doc-button.unsupported-file-type',
    defaultMessage: 'Export not available for this document format'
  })
  const label = intl.formatMessage({
    id: 'app.export-modified-doc-button.label',
    defaultMessage: 'Export updated reviewed document'
  })
  return (
    <Button
      onClick={handleClick}
      startIcon={!isLoading && <Icon.Download />}
      disabled={isLoading || !canExport}
      variant="contained"
      color={error !== null ? 'error' : 'primary'}
      sx={{
        minHeight: 'auto',
        width: 'fit-content',
        display: 'inline-flex',
        alignItems: 'center'
      }}
    >
      {isLoading
        ? ExportInProgress
        : error !== null
          ? (
              error
            )
          : !isSupportedFileType
              ? unsupportedFileTypeLabel
              : label
      }
    </Button>
  )
}

export default ExportModifiedDocButton
