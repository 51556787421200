import { Box, type BoxProps, Tooltip } from '@mui/material'
import { StyledTypography } from './FilenameWithIcon.styles'
import FiletypeIcon from './FiletypeIcon'

interface Props extends BoxProps {
  filename: string
}

/**
 * Display a filename with an icon representing the file type.
 */
const FilenameWithIcon: React.FC<Props> = ({ filename, ...boxProps }: Props): JSX.Element => {
  const extension = filename.split('.').pop()

  return (
    <Box className='filename-with-icon' display="flex" alignItems="center" gap={1} {...boxProps}>
      {extension !== undefined && <FiletypeIcon extension={extension} />}
      <Tooltip title={filename}>
        <StyledTypography>
          {filename}
        </StyledTypography>
      </Tooltip>
    </Box>
  )
}

export default FilenameWithIcon
