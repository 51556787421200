import { type UserInputForm } from 'features/user-input-form/types'
import Icon from 'components/icons/feather'
import { Grading, Difference, Summarize, Translate, TextSnippet, Search, ContentCopy } from '@mui/icons-material'

export const draftIcon: JSX.Element = <Icon.PenTool />

export type TaskEntryLabel = 'Ask' | 'Analyze' | 'Compare' | 'Draft' | 'Summarize' | 'Search' | 'Translate' | 'Redact'

export interface TaskEntry {
  label: TaskEntryLabel
  icon: JSX.Element
  userInputForm: Partial<UserInputForm>
  disabled?: boolean
}

// For each task, describe them with a label, icon and partial user input form.
// The partial user input form is used to match the task to the provided user input form,
// and to update the user input form when the task is selected.
export const TASKS: TaskEntry[] = [
  {
    label: 'Ask',
    icon: <Icon.MessageSquare />,
    userInputForm: {
      mainTask: 'Chat',
      taskParams: undefined
    }
  },
  {
    label: 'Analyze',
    icon: <Grading />,
    userInputForm: {
      mainTask: 'Analyze',
      taskParams: undefined
    }
  },
  {
    label: 'Compare',
    icon: <Difference />,
    userInputForm: {
      // Not yet implemented
      mainTask: undefined,
      taskParams: undefined
    },
    disabled: true
  },
  {
    label: 'Draft',
    icon: draftIcon,
    userInputForm: {
      mainTask: 'Draft',
      taskParams: undefined
    }
  },
  {
    label: 'Summarize',
    icon: <Summarize />,
    userInputForm: {
      mainTask: 'Summarize',
      taskParams: undefined
    }
  },
  {
    label: 'Translate',
    icon: <Translate />,
    userInputForm: {
      mainTask: 'Translate',
      taskParams: {
        sourceLang: 'all',
        targetLang: 'en'
      }
    }
  },
  {
    label: 'Redact',
    icon: <TextSnippet />,
    userInputForm: {
      // Not yet implemented
      mainTask: undefined,
      taskParams: undefined
    },
    disabled: true
  },
  {
    label: 'Search',
    icon: <Search />,
    userInputForm: {
      mainTask: 'Search',
      taskParams: undefined
    },
    disabled: true
  }
]

// The same icon is currently used for all sub-tasks of the Analyze task
export const analyzeSubTasksIcon: JSX.Element = <Grading />

export const comparativeAnalysisIcon: JSX.Element = <ContentCopy />

export default TASKS
