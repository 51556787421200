import { styled } from '@mui/material/styles'
import { Box, Stack } from '@mui/material'

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 0,
  overflowX: 'hidden',
  overflowY: 'auto',
  height: '100%'
}))

export const DocumentGroup = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(1)
}))
