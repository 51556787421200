import OptionsSelect, { type Option } from 'components/OptionsSelect'
import { type UserInputForm, AnalysisTaskAction, SpecificAnalysisOption } from 'features/user-input-form/types'
import { useIntl } from 'react-intl'

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

const SpecificAnalysisOptionsSelect: React.FC<Props> = (
  { userInputForm, onChange }: Props
) => {
  const intl = useIntl()

  // Create a map from the option id to the option object.
  // Starts at 1, as 0 is reserved for 'Select all' option
  const optionsMap = Object.values(SpecificAnalysisOption).reduce<Record<number, SpecificAnalysisOption>>((acc, option, idx) => {
    acc[idx + 1] = option
    return acc
  }, {})

  const label = intl.formatMessage({
    id: 'app.specific-analysis-options-select.label',
    defaultMessage: 'Select options'
  })

  return (
    <OptionsSelect
      items={
        Object.entries(optionsMap).map(([id, option]) => {
          return {
            id: parseInt(id),
            // Translate the option name, as it will be displayed in the select component.
            // We can use to id to match back to the option object.
            name: intl.formatMessage({ id: `app.specific-analysis-options-select.options.${option}`, defaultMessage: option })
          }
        })
      }
      label={label}
      onChange={(selectedOps) => {
        const specificAnalysisOptions: SpecificAnalysisOption[] = selectedOps.map((option: Option) =>
          optionsMap[option.id]
        ).filter((option) => option !== undefined)
        onChange({
          ...userInputForm,
          taskParams: {
            action: AnalysisTaskAction.SpecificAnalysis,
            specificAnalysisOptions
          }
        })
      }}
    />
  )
}

export default SpecificAnalysisOptionsSelect
