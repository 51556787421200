import { Box, styled } from '@mui/material'
import { AVATAR_SIZE } from '../UserAvatar.styles'

export const UserMenuContainer = styled(Box)({
  display: 'flex'
})

export const UserMenuButton = styled(Box)(({ theme }) => ({
  // Scale down the avatar to make it smaller, this is simpler than readjusting the size
  // for all the children components that make it.
  scale: 0.7,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '24px',
  border: `2px solid ${theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[600]}`,
  // Set a fixed width to avoid it being stretched
  width: `${AVATAR_SIZE + 30}px`,
  backgroundColor: theme.palette.action.hover,
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected
  },
  // Transition the border color when hovered
  transition: 'border-color 0.3s ease'
}))
