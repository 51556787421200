import { Stack, Tooltip } from '@mui/material'
import { type FC } from 'react'
import { useIntl } from 'react-intl'
import { StyledSwitch } from './ConfidentialityToggleSwitch.styles'

interface Props {
  active: boolean
  onChange?: (active: boolean) => void
}

/**
 * Presentational component for the confidentiality toggle switch
 */
export const ConfidentialityToggleSwitch: FC<Props> = ({ active, onChange }): JSX.Element => {
  const intl = useIntl()

  const tooltipVariant = active ? 'active' : 'inactive'
  const tooltip = intl.formatMessage({ id: `app.confidentiality-toggle-switch.tooltip.${tooltipVariant}` })

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip title={tooltip}>
        <StyledSwitch
          checked={active}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(event.target.checked)
          }}
        />
      </Tooltip>
    </Stack>
  )
}

export default ConfidentialityToggleSwitch
