import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import {
  Box,
  Button,
  IconButton,
  Modal,
  Tooltip
} from '@mui/material'
import CloseModalButton from 'components/buttons/CloseModalButton'
import { type FormEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import { ModalContainer, ModalHeader, ModalTitle } from 'theme'
import CancelButton from 'components/buttons/CancelButton'
import { FormButtonsContainer, StyledFormControl, StyledTextField } from './RenameModal.styles'

interface RenameModalProps {
  initTitle: string
  onRenamed: (newTitle: string) => void
}

/**
 * RenameModal is a modal that allows the user to rename the title of a session.
 */
function RenameModal ({ initTitle, onRenamed }: RenameModalProps): JSX.Element {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const [newTitle, setNewTitle] = useState<string>(initTitle)
  const [edited, setEdited] = useState(false)

  const onSubmit = (event: FormEvent): void => {
    event.preventDefault()
    setOpen(false)
    onRenamed(newTitle)
  }

  const submitDisabled = !edited || newTitle === ''

  const handleCancel = (): void => {
    setOpen(false)
  }

  const editForm = (
    <Box sx={{ mt: 2 }}>
      <form onSubmit={onSubmit}>
        <StyledFormControl>
          <StyledTextField
            error={newTitle === ''}
            id="title-input"
            name="title-input"
            variant="outlined"
            size={'small'}
            value={newTitle}
            onChange={(event) => {
              setNewTitle(event.target.value)
              setEdited(true)
            }}
            inputProps={{ maxLength: 150 }}
          />

          <FormButtonsContainer>
            <CancelButton onClick={handleCancel} />
            <Button
              type="submit"
              variant="contained"
              disabled={submitDisabled}
              startIcon={<SaveIcon />}
            >
              {
                intl.formatMessage({
                  id: 'app.rename-title-modal.submit-button.label',
                  defaultMessage: 'Save'
                })
              }
            </Button>
          </FormButtonsContainer>
        </StyledFormControl>
      </form>
    </Box>
  )

  const editButtonTooltip = intl.formatMessage({
    id: 'app.rename-title-modal.edit-button.tooltip',
    defaultMessage: 'Edit title'
  })

  return (
    <Box>
      <Tooltip title={editButtonTooltip} placement="right">
        <IconButton onClick={() => { setOpen(true) }}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => { setOpen(false) }}
        aria-labelledby="rename-modal-title"
        aria-describedby="rename-modal-description"
      >
        <ModalContainer>
          <ModalHeader>
            <ModalTitle className="rename-modal-title">
              {
                intl.formatMessage({
                  id: 'app.rename-title-modal.title',
                  defaultMessage: 'Edit title'
                })
              }
            </ModalTitle>
            <CloseModalButton onClick={handleCancel} />
          </ModalHeader>
          <Box>
            {editForm}
          </Box>
        </ModalContainer>
      </Modal>
    </Box>
  )
}

export default RenameModal
