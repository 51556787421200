import { IconButton } from '@mui/material'
import { forwardRef } from 'react'
import Icon from 'components/icons/feather'

export const BackIconButton = forwardRef<HTMLButtonElement, any>(function BackIconButton (props, ref) {
  return (
    <IconButton
      {...props}
      ref={ref}
    >
      <Icon.ArrowLeft />
    </IconButton>
  )
})

export default BackIconButton
