import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  gap: theme.spacing(1)
}))

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1)
}))

export const StyledContent = styled(Box)(({ theme }) => ({
  display: 'flex'
}))
