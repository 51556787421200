import { StyledBox, StyledStack } from './ChatLayout.styles'

interface Props {
  children: React.ReactNode
}

/**
 * Renders the chat layout (container with messages).
 * See {@link Chat} for the actual chat component.
 */
export const ChatLayout: React.FC<Props> = ({ children }: Props) => (
  <StyledBox className="chat-layout-container">
    <StyledStack className="chat-layout-stack" spacing={1}>
      {children}
    </StyledStack>
  </StyledBox>
)

export default ChatLayout
