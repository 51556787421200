import { Typography, useTheme } from '@mui/material'
import MuiMarkdown, { type Overrides } from 'mui-markdown'
import { useIntl } from 'react-intl'
import { StyledBox, StyledUserInputTypography } from './MessageText.styles'

interface Props {
  content: string
  showRaw?: boolean
}

const MessageText: React.FC<Props> = ({ content, showRaw = false }: Props) => {
  const intl = useIntl()
  const theme = useTheme()

  // Create an overrides object to pass to MuiMarkdown,
  // to change its default styles which are not to our liking.
  const overrides: Overrides = {
    // When formatting the "raw" message, we want to wrap the text.
    pre: {
      props: {
        style: {
          textWrap: 'wrap'
        }
      }
    }
  }

  return (
    <StyledBox className='message-text'>
      {
        content.length > 0
          ? (
              showRaw
                ? <StyledUserInputTypography className='raw-message-text' showRaw={showRaw}>{content}</StyledUserInputTypography>
                : <MuiMarkdown overrides={overrides}>{content}</MuiMarkdown>
            )
          : <MuiMarkdown overrides={overrides}>
              {
                intl.formatMessage({
                  id: 'app.message-text.no-additional-input',
                  defaultMessage: '*No additional input*'
                })
              }
            </MuiMarkdown>
      }
    </StyledBox>
  )
}

export default MessageText
